import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UtilsService } from '../../service/utils.service';

@Component({
  selector: 'app-http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.scss']
})
export class HttpLoaderComponent implements OnInit {
  isLoading: Subject<boolean> = this.utilsService.isLoading;
  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
  }

}
