import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  constructor( private _router: Router) { }

  ngOnInit(): void {
  }
  automobile(code): void {
    let codes = code;
    this._router.navigate(['/devis/5'], { queryParams: { donnee: codes } });
  }
  mrh(code): void {
    let codes = code;
    this._router.navigate(['/devis/6'], { queryParams: { donnee: codes } });
  }
  // sante(code): void {
  //   let codes = code;
  //   this._router.navigate(['/devis/7'], { queryParams: { donnee: codes } });
  // }

  sante(code: string): void {
    let codes = code;
    this._router.navigate(['/devis/7'], { queryParams: { donnee: codes } }).then(() => {
      if (!localStorage.getItem('santeReloaded')) {
        setTimeout(() => {
          localStorage.setItem('santeReloaded', 'true');
          window.location.reload();
        }, 1000);
      }
    });
  }
  
  remonter(code): void {
    let codes = code;
    this._router.navigate(['/devis/7'], { queryParams: { remonter: codes } });
  }

}
