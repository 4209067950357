<footer class="footer-area">
    <div class="container">
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="subscribe-content">
                        <h2>Rejoignez notre newsletter</h2>
                        <p>vous pouvez nous rejoindre dés maintenant.</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="subscribe-form">
                        <form class="newsletter-form">
                            <input [(ngModel)]="emailSouscripteur" type="email" class="input-newsletter" placeholder="Entrez votre adresse email" name="EMAIL" required autocomplete="off">
                            <button (click)="newsletter()" type="submit">Abonnez-vous maintenant
                                <i class="flaticon-right-chevron"></i>
                                <i *ngIf="loaderVisible" class="mx-2 fa fa-spinner fa-pulse text-white"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a>
                            <img class="tailleImage padimg" [src]="logoImage" alt="image">
                        </a>
                        <p>L’ABIDJANAISE D’ASSURANCES affiche un ancrage dans le paysage des assurances en Côte d’Ivoire grâce à sa qualité de service et sa politique de challenger confirmée, lui conférant une position d’acteur de référence du marché des
                            Assurances.
                        </p>
                    </div>
                    <ul class="social">
                        <li><a [href]="lienLinkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a [href]="lienFacebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Liens</h3>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Accueil</a></li>
                        <li><a routerLink="/devis/7">Assurance santé</a></li>
                        <li><a routerLink="/devis/5">Assurance automobile</a></li>
                        <li><a routerLink="/devis/8">Assurance Voyage</a></li>
                        <li><a routerLink="/devis/29">Assurance MRP</a></li>
                        <li><a routerLink="/devis/24">Assurance RCE</a></li>
                        <li><a routerLink="/devis/25">Tous Risques Chantiers</a></li>
                        <li><a routerLink="/contact">Nous contacter</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                <div class="single-footer-widget">
                    <h3>Informations de contact</h3>

                    <ul class="footer-contact-info">
                        <li><span>Adresse:</span> <a>{{adresse}}</a></li>
                        <li><span>Email:</span> <a>{{email}}</a></li>
                        <li><span>Phone:</span> <a>{{telephone}}</a></li>
                        <li><span>Fax:</span> <a>{{fax}}</a></li>
                        <li><a routerLink="/contact">Afficher l'emplacement sur GoogleMap</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p> © SUNTELECOMS </p>
                </div>

                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Politique de confidentialité</a></li>
                        <li><a routerLink="/">Termes et conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="fas fa-chevron-up"></i><i class="fas fa-chevron-up"></i></div>

<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>À propos de nous</h2>
                <p>Nous croyons que l'interaction avec la marque est la clé de la communication. De vraies innovations et une expérience client positive sont au cœur d'une communication réussie. Pas de faux produits et services. Le client est roi, sa vie
                    et ses besoins sont l'inspiration.</p>
            </div>
        </div>

        <!-- <div class="sidebar-instagram-feed">
            <h2>Instagram</h2>
            <ul>
                <li>
                    <a href="#"><img src="assets/img/blog-image/img1.jpg" alt="image"></a>
                </li>
                <li>
                    <a href="#"><img src="assets/img/blog-image/img2.jpg" alt="image"></a>
                </li>
                <li>
                    <a href="#"><img src="assets/img/blog-image/img3.jpg" alt="image"></a>
                </li>
                <li>
                    <a href="#"><img src="assets/img/blog-image/img4.jpg" alt="image"></a>
                </li>
                <li>
                    <a href="#"><img src="assets/img/blog-image/img5.jpg" alt="image"></a>
                </li>
                <li>
                    <a href="#"><img src="assets/img/blog-image/img6.jpg" alt="image"></a>
                </li>
                <li>
                    <a href="#"><img src="assets/img/blog-image/img7.jpg" alt="image"></a>
                </li>
                <li>
                    <a href="#"><img src="assets/img/blog-image/img8.jpg" alt="image"></a>
                </li>
            </ul>
        </div> -->

        <div class="sidebar-contact-area">
            <div class="sidebar-contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a>{{telephone}}</a>
                        <span>OU</span>
                        <a>{{email}}</a>
                    </h2>
                    <!-- <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul> -->
                </div>
            </div>
        </div>

        <span class="close-btn sidebar-modal-close-btn"><i class="flaticon-cross-out"></i></span>
    </div>
</div>