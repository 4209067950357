<!-- <app-header-four></app-header-four> -->
<app-header-one></app-header-one>
<div class="main-banner-section item-bg16-tous height_image">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1>Tous Risques Chantiers</h1>
                    <p>La couverture Tous Risques Chantiers permet de satisfaire aux obligations réglementaires qui imposent une assurance tous risques chantiers selon les dispositions du code construction.</p>

                    <div class="btn-box">
                        <a (click)="souscrire()" class="default-btn btnO">Obtenir un devis<span></span></a>
                        <a class="optional-btn rappel" (click)="rappeler()">Me faire rappeler<span></span></a>
                    </div>
                    <!-- <div class="btn-box" *ngIf="login && donnee != 'mrh'">
                        <a (click)="souscrire()" class="default-btn btnO">Obtenir un devis<span></span></a>
                        <a class="optional-btn rappel">Me faire rappeler<span></span></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/car.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/umbrella-white.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/home.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/heart.png" alt="image"></div>
</div>


<div class="row desc container-row justify-content-center" id="contact" #contact>
    <!-- *ngIf="login" -->
    <div class="col-12 droite" *ngIf="login">
        <!-- *ngIf="mri" -->
        <form [formGroup]="tousRCForm" *ngIf="mri">
            <aw-wizard #wizard class="stepper-container pb-5" [navBarLocation]="'top'" [navBarLayout]="'large-empty'" [disableNavigationBar]="false">
                <!-- Step 1 Maison  -->
                <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Intervenant">
                    <div class="form-container" formGroupName="step1">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" style="margin-top: -20px;margin-bottom: 40px;">
                                <div class="form" ngbPopover="Intervenants Principaux" triggers="mouseenter:mouseleave">
                                    <label for="textarea">Intervenants Principaux</label>
                                    <textarea placeholder="Intervenants Principaux" class="areaStyle" name="intervenant" id="intervenant" maxlength="65525" formControlName="intervenant"></textarea>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step1').get('intervenant').invalid" style="padding-top: 35px;">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" style="padding-top:15px">
                                <div class="form" placement="top" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="adresse" class="form__input" autocomplete="off" formControlName="adresse" placeholder=" " />
                                    <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step1').get('adresse').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Maître de l'ouvrage" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="maitreOuvrage" class="form__input" autocomplete="off" formControlName="maitreOuvrage" placeholder=" " />
                                    <label for="maitreOuvrage" class="form__label">Maître de l'ouvrage<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step1').get('maitreOuvrage').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Maître d'œuvre" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="maitreOeuvre" class="form__input" autocomplete="off" formControlName="maitreOeuvre" placeholder=" " />
                                    <label for="maitreOeuvre" class="form__label">Maître d'œuvre<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step1').get('maitreOeuvre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Architecte" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="architecte" class="form__input" autocomplete="off" formControlName="architecte" placeholder=" " />
                                    <label for="architecte" class="form__label">Architecte<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step1').get('architecte').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Bureau Ingenierie" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="bureauIngenierie" class="form__input" autocomplete="off" formControlName="bureauIngenierie" placeholder=" " />
                                    <label for="bureauIngenierie" class="form__label">Bureau ingénierie<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step1').get('bureauIngenierie').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Ingenieur-Conseil" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="ingenieurConseil" class="form__input" autocomplete="off" formControlName="ingenieurConseil" placeholder=" " />
                                    <label for="ingenieurConseil" class="form__label">Ingenieur-Conseil<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step1').get('ingenieurConseil').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Bureau d'Etude" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="bureauEtude" class="form__input" autocomplete="off" formControlName="bureauEtude" placeholder=" " />
                                    <label for="bureauEtude" class="form__label">Bureau Etude<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step1').get('bureauEtude').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" style="margin-top:-15px">
                                <div class="form" ngbPopover="Sous-traitants" triggers="mouseenter:mouseleave">
                                    <label for="textarea">Sous-traitants</label>
                                    <textarea placeholder="Sous-traitants" class="areaStyle" name="sousTraitants" id="sousTraitants" maxlength="65525" formControlName="sousTraitants"></textarea>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step1').get('sousTraitants').invalid" style="padding-top: 35px;">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="choixinit()"><i
                      class="fa fa-chevron-left"></i>Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i
                        class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>

                <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Caractéristiques">
                    <div class="form-container" formGroupName="step2">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Situation Géographique" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="situationGeographique" class="form__input" autocomplete="off" formControlName="situationGeographique" placeholder=" " />
                                    <label for="situationGeographique" class="form__label">Situation Géographique<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step2').get('situationGeographique').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Nature de l'ouvrage" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" name="natureOuvrage" formControlName="natureOuvrage">
                                         <option value="" selected disabled>Nature de l'ouvrage</option>
                                         <option *ngFor="let nature of natureOuvrageList" [value]="nature.value">{{nature?.name}}</option>
                                     </select>
                                    <label for="cars" class="form__label">Nature de l'ouvrage<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step2').get('natureOuvrage').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Caractéristiques principales  de l’ouvrage" triggers="mouseenter:mouseleave">
                                    <label for="textarea">Caractéristiques principales  de l’ouvrage</label>
                                    <textarea placeholder="Caractéristiques principales  de l’ouvrage" class="areaStyle" name="caracteristiquePrincipale" id="caracteristiquePrincipale" maxlength="65525" formControlName="caracteristiquePrincipale"></textarea>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step2').get('caracteristiquePrincipale').invalid" style="padding-top: 35px;">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" style="margin-top: 35px;">
                                <div class="form" placement="top" ngbPopover="Nombre d’étages" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" min="0" id="nbrEtage" class="form__input" autocomplete="off" formControlName="nbrEtage" placeholder=" " />
                                    <label for="nbrEtage" class="form__label">Nombre d’étages<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step2').get('nbrEtage').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Nombre de sous-sol" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" min="0" id="nbrSousSol" class="form__input" autocomplete="off" formControlName="nbrSousSol" placeholder=" " />
                                    <label for="nbrSousSol" class="form__label">Nombre de sous-sol<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step2').get('nbrSousSol').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Montant prévisionnel du marché" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-money-bill form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" mask="separator" id="mtPrevisionnel" class="form__input" autocomplete="off" placeholder=" " formControlName="mtPrevisionnel" />
                                    <label for="mtPrevisionnel" class="form__label">Montant prévisionnel du marché<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step2').get('mtPrevisionnel').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Conclusion de l’étude de sol" triggers="mouseenter:mouseleave">
                                    <label for="textarea">Conclusion de l’étude de sol</label>
                                    <textarea placeholder="" class="areaStyle" name="conclusion" id="conclusion" maxlength="65525" formControlName="conclusion"></textarea>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step2').get('conclusion').invalid" style="padding-top: 35px;">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" style="margin-top:35px">
                                <div class="form" ngbPopover="Planning avancement" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-calendar-days form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="planning" class="form__input" placeholder=" " (dateSelect)="onDateSelect($event,'step2','planning')" ngbDatepicker #planning="ngbDatepicker" (click)="planning.toggle()" />
                                    <label for="planning" class="form__label">Planning avancement<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step2').get('planning').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Rapport de contrôle technique sur existants" triggers="mouseenter:mouseleave">
                                    <label for="textarea">Rapport de contrôle technique sur existants</label>
                                    <textarea placeholder="" class="areaStyle" name="rapportTechnique" id="rapportTechnique" maxlength="65525" formControlName="rapportTechnique"></textarea>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('step2').get('rapportTechnique').invalid" style="padding-top: 35px;">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="choixinit()"><i
                      class="fa fa-chevron-left"></i>Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i
                        class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>

                <!-- Steppeur pour les Packs  -->
                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Garanties">
                    <div class="container">
                        <h2 class="text-center p-2 garantie"><a class="nav-link active" data-toggle="tab">Garanties optionnelles</a></h2>
                        <section class="services-area pb-70 c-marginB">
                            <div class="container">
                                <div class="row csMrh">
                                    <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let sp of packofpackList;let i=index" (click)="onChangeCategory($event, sp.libelle,sp)">
                                        <div class="single-services-box" (click)="handleBorder($event,sp?.nom,i)">
                                            <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="actif[sp?.nom]"></i>
                                            <div class="icon">
                                                <i class="flaticon-insurance"></i>
                                                <div class="icon-bg">
                                                    <img src="assets/img/icon-bg1.png" alt="image">
                                                    <img src="assets/img/icon-bg2.png" alt="image">
                                                </div>
                                            </div>
                                            <h3><a (click)="readmore(sp.id)" class="titre_Libelle">{{sp?.libelle}}</a></h3>
                                            <p>{{sp?.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                        class="fa fa-chevron-left"></i>Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>


                <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Pièce Jointe">
                    <div class="container">
                        <div class="row col-lg-6 col-md-12 col-xs-12 col-sm-12 c-imgRow" *ngIf="!downloadFile">
                            <div title="Cliquer pour charger la Pièce Jointe" class="image c-image bg-card" (click)="PIECE.click()">
                                <img src="{{noImage}}" alt="" class="myImg">
                                <div class="titleImage">Pièce Jointe</div>
                                <input [disabled]="loader" hidden #PIECE multiple class="use-avatar" (change)="selectOnFile($event,'PIECE','PIECE')" type="file" id="file" required accept=".pdf , .PDF , .doc , .DOC , .docx , .DOCX , .docm , .DOCM, .xls , .XLS , .xlsx , .XLSX" />
                            </div>
                            <div class="chargement" *ngIf="loader">
                                <i class="text-center fa fa-spinner fa-pulse"></i> Chargement du fichier.....
                            </div>
                        </div>
                        <div class="row col-lg-6 col-md-12 col-xs-12 col-sm-12 c-imgRow" *ngIf="downloadFile">
                            <div title="Cliquer pour Télecharger le questionnaire" class="image c-image bg-card">
                                <a (click)="downloadModel()" target="_blank" [href]="fileProduit?.questionnaireUrl ? fileProduit?.questionnaireUrl : '/assets/images/questionnaire_default.pdf'">
                                    <img src="{{downloadImage}}" alt="" class="myImg">
                                </a>
                                <div class="titleImage">Télecharger le questionnaire</div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                        class="fa fa-chevron-left"></i>Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>


                <!--   Steppeur pour Paiements -->
                <!--   Steppeur pour Paiements  suite-->
                <!--  -->
                <!--   Steppeur pour Récapitulatif -->
                <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Récapitulatif">
                    <div class="recap-form-container">
                        <div class="mesImage">
                            <div class="row imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                <!-- <img src="../../../../../assets/images/PROXIM-ASSUR-logo_blanc.png" alt=""> -->
                                <img [src]="logoImage" alt="logo">
                            </div>
                            <div class="imgLogoR">
                                <!-- <img src="../../../../../assets/images/logo.png" alt=""> -->
                            </div>
                        </div>
                        <div class="info-devis">
                            <div class="proposition">
                                <div class="pro-title">Récapitulatif</div>
                                <div class="sub-title">{{numeroSous}}
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        INTERVENANT
                                        <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="openModal(modalAssure)"></span> -->
                                        <!-- contentResultsign -->
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Intervenants Principaux</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">{{tousRCForm.get('step1').get('intervenant').value}}</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">{{tousRCForm.get('step1').get('adresse').value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Maître de l'ouvrage</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{tousRCForm.get('step1').get('maitreOuvrage').value}}
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Maître d'œuvre</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{tousRCForm.get('step1').get('maitreOeuvre').value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Architecte</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{tousRCForm.get('step1').get('architecte').value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Bureau ingénierie</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{tousRCForm.get('step1').get('bureauIngenierie').value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Ingenieur-Conseil</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                                {{tousRCForm.get('step1').get('ingenieurConseil').value}} </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Bureau Etude</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{tousRCForm.get('step1').get('bureauEtude').value}} </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Références Proposition</span>
                                        <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="goTo(3)"></span> -->
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Situation Géographique</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">{{tousRCForm.get('step2').get('situationGeographique').value}} </div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nature de l'ouvrage</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">{{tousRCForm.get('step2').get('natureOuvrage').value}}</div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nombre d’étages</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">{{tousRCForm.get('step2').get('nbrEtage').value}}</div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nombre de sous-sol</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">{{tousRCForm.get('step2').get('nbrSousSol').value}}</div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Montant prévisionnel du marché</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">{{tousRCForm.get('step2').get('mtPrevisionnel').value | number:'1.0-0':'fr'}}</div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Planning d’avancement des travaux</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">{{tousRCForm.get('step2').get('planning').value | date:'dd/MM/yyyy'}}</div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="disposition row col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <div class="title-dispo-footerL col-md-2 col-lg-2 col-sm-12 col-xs-12">
                                    <!-- Assureur : {{currentAssureur?.nom}} -->
                                </div>
                                <div class="title-dispo-footerR col-md-10 col-lg-10 col-sm-12 col-xs-12">
                                    Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss'}} E-MAIL : {{email}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                        class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [swal]="confirmSubscription2" [disabled]="sendingRequest"> <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
                        </div>
                    </div>
                </aw-wizard-step>
            </aw-wizard>
        </form>
    </div>
</div>


<section class="find-agent-area-trc ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="avantage">Avantages</h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="find-agent-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-home-insurance"></i>
                                </div>

                                <h3 class="color_title">les vols</h3>
                                <p>le vol sans installation d’une serrure sécurisée : les assureurs partent du principe qu’il ne faut pas créer les conditions de risque.</p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-home-insurance"></i>
                                </div>

                                <h3 class="color_title"> les catastrophes naturelles et technologiques</h3>
                                <p>De plus, cette assurance couvre automatiquement la responsabilité civile du souscripteur et de ses proches.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="find-agent-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-home-insurance"></i>
                                </div>

                                <h3 class="color_title">les dégâts des eaux</h3>
                                <p>votre assurance devrait couvrir les indemnités qui auraient été à votre charge si vous n’aviez pas été assuré.</p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-home-insurance"></i>
                                </div>

                                <h3 class="color_title">les incendies</h3>
                                <p>Avec une assurance multirisque habitation, le souscripteur du contrat n’est pas le seul protégé.Elle prend en compte sa famille.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- Modal pour la page login -->
<ng-template #contentResultlogin let-modal>
    <div class="login">
        <form [formGroup]="loginform">
            <div class="container px-4 py-5 mx-auto">
                <div class="card">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3">
                                        <h3 class="monCompte"> Mon compte!</h3>
                                        <p class="compteText"> Connectez vous à votre compte SVP.</p>
                                    </div>
                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="nomUser" class="form__input" autocomplete="off" placeholder=" " formControlName="username" />
                                        <label for="nomUser" class="form__label">Nom d'utilisateur<span class="text-red"></span></label>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                                        Le nom d'utilisateur est requis
                                    </div>

                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-lock form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password" />
                                        <label for="psw" class="form__label">Mot de passe<span class="text-red"></span></label>
                                        <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>
                                        <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                                        Le mot de passe est requis
                                    </div>
                                    <div class="forgetMdp">
                                        <div class="resterConnecter">
                                            <input type="checkbox">
                                            <span> Restez connecter</span>
                                        </div>
                                        <div class="updateMdp" (click)="lanceforgot()">Mot de passe oublié?</div>
                                    </div>
                                    <div class="text-danger text-center mt-1" *ngIf="userInconnu!=''"> {{userInconnu}}</div>
                                    <div class="text-center"> <button class="seConnecter" (click)="connecter()" [disabled]="loaderVisible">Connectez-vous</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Connexion en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-3 margeTop">
                                <p href="#" class="sm-text mx-auto mb-3">Vous n'avez pas de compte?<button class="btn btn-white ml-2" (click)="inscrire()" [disabled]="loaderVisible">Créez-en un!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>

</ng-template>

<ng-template #contentResultass let-modal>
    <div class="modal-body  bg-white  autodown" #mrhDownload id="mrhDownload">
        <div class="mesImage row col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                <!-- <img class="imgFirst" src="../../../../assets/img/logo123.png" alt=""> -->
                <img [src]="logoImage" alt="logo">
            </div>
            <div class="imgLogoR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <!-- <img src="../../../../assets/img/logo.png" alt=""> -->
            </div>
        </div>
        <div class="info-devis">
            <div class="proposition">
                <div class="pro-title"> Devis MRH</div>
                <div class="sub-title">{{numeroSous}}</div>
            </div>
            <div class="row ">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        Souscripteur
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nom</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{users?.infos?.firstName}} {{users?.infos?.lastName}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{users?.infos?.email}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Téléphone</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{users.infos.telephone}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{users.infos.adresse}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Profession</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{users.infos.profession}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Activité</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                {{users.infos.secteuractivite}} </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Assureur</div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">{{currentAssureur?.nom}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span> Références Proposition</span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">


                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{users?.infos?.firstName}} {{users?.infos?.lastName}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{users.infos.adresse}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{tousRCForm?.get('packForm1')?.get('effet')?.value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}}
                            </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> 1 an </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 name c-mt"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                        Caractéristiques de la maison
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Qualité souscripteur</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{tousRCForm?.get('packForm1')?.get('qualite_souscripteur')?.value=='proprietaire'?'Propriétaire':"Locataire"}}
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Nombre de pièces</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        F{{tousRCForm?.get('packForm1')?.get('pieces')?.value}} </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name" *ngIf="tousRCForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'">Valeur du batiment
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value" *ngIf="tousRCForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'">
                                        {{tousRCForm?.get('packForm1')?.get('valeur_batiment')?.value | number:'1.0-0':'fr'}} </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name" *ngIf="tousRCForm.get('packForm1').get('qualite_souscripteur').value!='proprietaire'">Loyer mensuel
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value" *ngIf="tousRCForm.get('packForm1').get('qualite_souscripteur').value!='proprietaire'">
                                        {{tousRCForm?.get('packForm1')?.get('loyer_mensuel')?.value | number:'1.0-0':'fr'}} </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Valeur du contenu</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{tousRCForm?.get('packForm1')?.get('valeur_contenu')?.value | number:'1.0-0':'fr'}} </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Surface à développée</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{tousRCForm?.get('packForm1')?.get('surface')?.value}} m²
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name">Adresse</div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value">
                                        {{tousRCForm?.get('packForm1')?.get('situation_risque')?.value}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="proposition2">
                <div class="pro-title"> Garanties Souscrites et Primes d'Assurance(en FCFA)</div>
            </div>
            <div class="row c-row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        Nature des garanties
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                Responsabilité civile
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                Incendie et événements assimilés
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                Vol par agression et/ou effraction
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                Dégâts des eaux
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                Bris de glaces
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                Défense et recours
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of  granddevis?.garanties">
                                {{garantie?.libelle}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        Primes d’assurance
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                Gratuit
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                Gratuit
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                Gratuit
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                Gratuit
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                Gratuit
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val">
                                Gratuit
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 val" *ngFor="let garantie of  granddevis?.garanties">
                                <span> {{garantie?.primeTtc | number:'1.0-0':'fr'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="description-ass">
                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
          {{prix | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
            </div>
            <!-- <div class="row "> -->
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">
                <div class="row contenu-div">
                    <!-- <div class="row"> -->
                    <div class="row col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                        <div>Prime nette (FCFA)</div>
                        <div>{{base | number:'1.0-0':'fr'}}</div>
                    </div>
                    <div class="row col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-val">
                        <div class="val">
                            <div class="valPrime">Prime totale TTC (FCFA)</div>
                            <div class="valPrime">{{ prix | number:'1.0-0':'fr'}}</div>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
            <!-- </div> -->

            <div class="disposition row col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <div class="title-dispo-footerL col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    Assureur : {{currentAssureur?.nom}}
                </div>
                <div class="title-dispo-footerR  col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss'}} E-MAIL : {{email}}
                </div>
            </div>
        </div>
    </div>

    <!-- </div> -->


    <div>
        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left c-roundbtn">
        <i class="fa fa-chevron-left"></i>Retour</button>
        <button #btnDownload id="btnDownload" type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()" placement="left" ngbPopover="Télécharger le devis" triggers="mouseenter:mouseleave">Télécharger 
        <i class="fa fa-download"></i></button>
        <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn" (click)="modal.close('subscribe')">
      Souscrire <i class="fa fa-angle-double-right"></i>
    </button>
    </div>



    <div>
        <div class="row">
        </div>
    </div>
</ng-template>

<ng-template #contentResultcontact let-modal>
    <div class="modal-header" id="modal-header">
        <h5 class="modal-title" id="modal-basic-title" id="modal-title">Se faire contacter</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body " id="modal-body">
        <form [formGroup]="personcontactForm">
            <div class="form-container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-append" placement="top" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user-circle-o text-white"> <span class="text-red">*</span></i>
                                </div>
                                <!-- <label for="prenom">Prénom <span class="text-red">*</span></label> -->
                                <input type="text" class="form-control" formControlName="first_name" placeholder="Prénom">
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm.get('first_name')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-user-circle-o text-white"> <span class="text-red">*</span></i>
                                </div>
                                <!-- <label for="nom">Nom <span class="text-red">*</span></label> -->
                                <input type="text" class="form-control" formControlName="last_name" placeholder="Nom">
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('last_name')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Téléphone" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                                </div>
                                <!-- <label for="telephone">Téléphone <span class="text-red">*</span></label> -->
                                <input type="text" class="form-control" prefix="+225 " [mask]="phoneMask" formControlName="telephone" placeholder="Téléphone">
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('telephone')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-home text-white"> </i>
                                </div>
                                <!-- <label for="adresse">Adresse</label> -->
                                <input type="text" class="form-control" formControlName="email" placeholder="Email">
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm.get('email').hasError('pattern')">
                            Ce champ est invalide
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Jour disponible" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                                </div>
                                <ng-select multiple="true" class="select-rappel" placeholder="Jour à être rappeler" formControlName="jour" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" [dropdownPosition]="'bottom'">
                                    <ng-option *ngFor="let jour of listJours" [value]="jour.name"> {{jour.name}} </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('jour')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Heure disponible" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                                </div>
                                <ng-select multiple="true" class="select-rappel" placeholder="Heure à être rappeler" formControlName="heure" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" [dropdownPosition]="'bottom'">
                                    <ng-option *ngFor="let heure of listHeures" [value]="heure.name"> {{heure.name}} </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('heure')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>

                </div>
            </div>
            <button type="button" class="btn btn-danger" #closeBTN (click)="modal.close('close')">Fermer</button>
            <button type="button" class="btn btn-success" [disabled]="loadContact" (click)="saveContact()"> <span
          *ngIf="loadContact" class="fa fa-spinner fa-pulse"></span> Envoyer</button>
        </form>
    </div>
    <!-- <div class="modal-footer">

  </div> -->

</ng-template>

<ng-template #contentResult let-modal>
    <div class="modal-header bg-white">
        <h4 class="modal-title" id="modal-basic-title">Devis</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body bg-white">
        <div class="cotainer devis-container">
            <div class="row row-devis">
                <div class="col-12">
                    <div class="row row-title-devis align-items-center">
                        <div class="col-6 img-logo">
                            <img src="../../../../assets/images/logo6.png" alt="">
                        </div>
                        <div class="col-6 ">
                            <div class="row Informations">
                                <div class="col-12">
                                    <p>Tel: {{telephone}}</p>
                                </div>
                                <div class="col-12">
                                    <p>Email: {{email}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-subtitle-devis ">
                        <div class="col-3 justify-content-center bg-primary">
                            <p class="devis-text">Devis</p>
                        </div>
                        <div class="col-9">
                            <div class="row assure">
                                <div class="col-12 assure-title">
                                    MRH
                                </div>
                                <div class="col-12 assure-text">
                                    <div class="row align-items-center ">
                                        <div class="col-8">
                                            <p>Prime Auto</p>
                                        </div>
                                        <div class="col-4">{{result?.prime_auto | number :'1.0-0' : 'fr' }} FCFA</div>
                                        <div class="col-8">
                                            <p>Prime Habitation</p>
                                        </div>
                                        <div class="col-4">{{result?.prime_habitation | number:'1.0-0' : 'fr' }} FCFA</div>
                                        <div class="col-8">
                                            <p>Taxe</p>
                                        </div>
                                        <div class="col-4">{{result?.taxe | number:'1.0-0' : 'fr' }} FCFA</div>
                                        <div class="col-8">
                                            <p>FGA</p>
                                        </div>
                                        <div class="col-4">{{result?.fga | number:'1.0-0' : 'fr' }} FCFA</div>
                                        <div class="col-8">
                                            <p>Cout police</p>
                                        </div>
                                        <div class="col-4">{{result?.cout_police | number:'1.0-0' : 'fr' }} FCFA</div>
                                        <div class="col-8">
                                            <p>Bonus</p>
                                        </div>
                                        <div class="col-4">{{result?.bonus | number:'1.0-0' : 'fr' }} FCFA</div>
                                        <div class="col-8">
                                            <p>Prime RC</p>
                                        </div>
                                        <div class="col-4">{{result?.rcrti | number:'1.0-0' : 'fr' }} FCFA</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row assure">
                                <div class="col-12 assure-title">
                                    Totale
                                </div>
                                <div class="col-12 assure-text">
                                    <div class="row align-items-center ">
                                        <div class="col-8">
                                            <p>Prime Totale</p>
                                        </div>
                                        <div class="col-4">{{result?.prime_total | number:'1.0-0' : 'fr' }} FCFA</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer bg-white">
        <button type="button" class="btn btn-outline-dark c-roundbtn" (click)="modal.close('close')">Fermer</button>
        <button type="button" class="btn btn-success c-roundbtn" (click)="modal.close('subscribe')">Souscrire</button>
    </div>

</ng-template>
<!-- Modal pour étre rappelé -->
<ng-template #devisAEnvoye let-modal>
    <form [formGroup]="etrerappeleForm">
        <!-- <div class="container px-2 py-1 mx-auto"> -->
        <div class="card card0 c-card">
            <!-- <div class="row beneficiary-section form-container "> -->
            <div class="row no-margin">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">{{assureurAEnvoye?.displayMe}}</h6>
                        </div>
                        <div class="col-lg-2 text-right1">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                    class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-3">
                    <div class="row">

                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Date de rappel" triggers="mouseenter:mouseleave">
                                <i class="fa fa-calendar form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="date" placeholder="" class="form__select" name="date" formControlName="date" name="date" #vehicule>
                          <option value="" disabled selected>Jour de rappel</option>
                          <option *ngFor="let rapel of dateRappel" [value]="rapel.value">{{rapel?.name}}</option>
                        </select>
                                <label for="cars" class="form__label">Jour de rappel<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="etrerappeleForm.get('date').invalid && updateRequest">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Heure de rappel" triggers="mouseenter:mouseleave">
                                <i class="fa-regular fa-clock form__icon c-icon"></i>
                                <div class="form__separator">
                                </div>
                                <ngx-bootstrap-multiselect formControlName="heure" name="heure" class="form__select" [texts]="myTexts" [settings]="mySettings" #heure [options]="heureRappel"></ngx-bootstrap-multiselect>
                                <label for="cars" class="form__label">Heure de rappel<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf=" etrerappeleForm.get('heure').invalid && updateRequest">
                                Ce champ est obligatoire
                            </div>
                        </div>
                    </div>
                    <div class="noDataGaranties" *ngIf="garantie?.length == 0 ">
                        Chargemet en cours ...
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-center">
                    <button class="btn btn-large c-btnAjout" (click)="etreRappele()" [disabled]="updateRequest">
                <i class="fa fa-pencil" *ngIf="!updateRequest"></i> 
                <i class="fa fa-spinner fa-pulse" *ngIf="updateRequest"></i>
                Valider
              </button>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <!-- </div> -->
    </form>
</ng-template>

<!-- Maison -->
<ng-template #updateMaison let-modal>
    <form [formGroup]="tousRCForm">
        <div class="container px-4 py-2 mx-auto">
            <div class="card c-card">
                <div class="row beneficiary-section form-container " formGroupName="packForm1">
                    <div class="row no-margin">
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                            <div class="row no-margin">
                                <div class="col-10 p-0">
                                    <h6 class="text-left text-white c-cardTitle">Modifier les informations de la maison</h6>
                                </div>
                                <div class="col-lg-2 text-right">
                                    <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                    class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Qualité du souscripteur" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="qualite_souscripteur" (change)="checkSouscripteur()">
                  <option value="" selected disabled>Qualité souscripteur </option>
                  <option value="proprietaire">Propriétaire</option>
                  <option value="proprietaireN">Propriétaire Non occupant</option>
                  <option value="locataire">Locataire</option>
                </select>
                                <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('packForm1').get('qualite_souscripteur').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" placement="top" placement="top" ngbPopover="Nombre de pieces" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" formControlName="pieces">
                  <!-- <option value="" disabled selected>Nombre de pièces</option> -->
                    <option value="2">F2</option>
                    <option value="3">F3</option>
                    <option value="4">F4</option>
                    <option value="5">F5</option>
                    <option value="6">F6</option>
                    <option value="7">F7</option>
                    <option value="8">F8</option>
                    <option value="9">F9</option>
                    <option value="10">F10</option>
                    <option value="11">F11</option>
                </select>
                                <label for="cars" class="form__label">Nombre de pièces<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('packForm1').get('pieces').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="tousRCForm.get('packForm1').get('qualite_souscripteur').value=='proprietaire'">
                            <div class="form" ngbPopover="Valeur du batiement" triggers="mouseenter:mouseleave">
                                <i class="fa fa-building-o form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" mask="separator" id="valeur_batiment" class="form__input" autocomplete="off" placeholder=" " formControlName="valeur_batiment" />
                                <label for="valeur_batiment" class="form__label">Valeur du batiment<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('packForm1').get('valeur_batiment').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="tousRCForm.get('packForm1').get('qualite_souscripteur').value=='locataire'">
                            <div class="form" ngbPopover="Loyer mensuel" triggers="mouseenter:mouseleave">
                                <i class="fa fa-building-o form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" mask="separator" id="loyer_mensuel" class="form__input" autocomplete="off" placeholder=" " formControlName="loyer_mensuel" />
                                <label for="loyer_mensuel" class="form__label">Loyer mensuel<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('packForm1').get('loyer_mensuel').invalid">
                                Ce champ est obligatoire
                            </div>


                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Valeur du contenu" triggers="mouseenter:mouseleave">
                                <i class="fa fa-money form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" mask="separator" id="valeur_contenu" class="form__input" autocomplete="off" placeholder=" " mask="separator" formControlName="valeur_contenu" />
                                <label for="valeur_contenu" class="form__label">Valeur du contenu<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('packForm1').get('valeur_contenu').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Surface développée" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" mask="separator" id="surface" class="form__input" autocomplete="off" placeholder=" " formControlName="surface" />
                                <label for="surface" class="form__label">Surface développée m2<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('packForm1').get('surface').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Début du contrat" triggers="mouseenter:mouseleave">
                                <i class="fa fa-clock-o form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <!-- | date: 'dd/MM/yyyy' -->
                                <input type="text" id="effet" class="form__input" autocomplete="off" placeholder=" " (dateSelect)="onDateSelect($event, 'packForm1','effet')" [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="today" ngbDatepicker #dateAss="ngbDatepicker" [value]="tousRCForm.get('packForm1').get('effet').value | date: 'dd/MM/yyyy'"
                                    (click)="dateAss.toggle()" />
                                <label for="effet" class="form__label">Date effet<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('packForm1').get('effet').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Surface développée" triggers="mouseenter:mouseleave">
                                <i class="fa fa-map-marker form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="situation_risque" class="form__input" autocomplete="off" placeholder=" " formControlName="situation_risque" />
                                <label for="situation_risque" class="form__label">Adresse<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('packForm1').get('situation_risque').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint('beneficiares')"><i class="fa fa-pencil"></i> Modifier</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<!-- Assuré -->
<ng-template #updateAssures let-modal>
    <form [formGroup]="tousRCForm">
        <!-- <div class="container px-4 py-2 mx-auto"> -->
        <div class="card c-card">
            <div class="row beneficiary-section form-container " formGroupName="assure">
                <!-- <div class="row no-margin"> -->
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">Ajouter assurés</h6>
                        </div>
                        <div class="col-lg-2 text-right">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                    class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="prénom" triggers="mouseenter:mouseleave">
                        <i class="fa fa-user form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                        <label for="prenom" class="form__label">Prénom<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('assure').get('prenom').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                        <i class="fa fa-user form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="nom" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                        <label for="nom" class="form__label">Nom<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('assure').get('nom').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                        <i class="fa fa-phone form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="numeroTelephone" prefix="+221 " [mask]="phoneMask" class="form__input" autocomplete="off" placeholder=" " formControlName="numeroTelephone" />
                        <label for="numeroTelephone" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('assure').get('numeroTelephone').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                        <i class="fa fa-envelope form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                        <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('assure').get('email').invalid">
                        Ce champ est invalide
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="profession" triggers="mouseenter:mouseleave">
                        <i class="fa fa-user form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="profession" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                        <label for="profession" class="form__label">Profession<span class="text-red"> *</span></label>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                        <i class="fa fa-user form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="secteuractivite" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                        <label for="secteuractivite" class="form__label">Activité<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('packForm1').get('secteuractivite').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                        <i class="fa-solid fa-venus form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <select id="cars" placeholder=" " class="form__select" name="genre" formControlName="genre">
                  <option value="M">Masculin</option>
                  <option value="F">Féminin</option>
                </select>
                        <label for="cars" class="form__label">Sexe <span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('assure').get('genre').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                        <i class="fa fa-home form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="adresse" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                        <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && tousRCForm.get('assure').get('adresse').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint('beneficiares')"><i class="fa fa-pencil"></i> Modifier</button>
                <!-- </div> -->
            </div>
        </div>
        <!-- </div> -->
    </form>
</ng-template>

<!-- <ng-template #contentResultforgot let-modal>
    <div class="modal-body login">
        <form [formGroup]="forgotForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3"> <img id="logo" [src]="logoImage"> </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Mot de passe</label> <input type="password" id="psw" class="form-control" formControlName="Password"> </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Mot de passe</label> <input type="password" id="psw" class="form-control" formControlName="password"> </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Mot de passe</label> <input type="password" id="psw" class="form-control" formControlName="password"> </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="forgot()" [disabled]="loaderVisible">Réinitialiser compte</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Réinitialisation en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template> -->

<ng-template #contentResultforgot let-modal>
    <div class="modal-body login">
        <form [formGroup]="forgotForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" [src]="logoImage"> </div> -->
                                    <h6 class="msg-info text-center">Mot de passe oublié ?</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input type="text" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="forgot()" [disabled]="loaderVisible">Réinitialiser compte</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Réinitialisation en cours
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<ng-template #contentResultResetPassword let-modal>
    <div class="modal-body login">
        <form [formGroup]="resetPasswordForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" src="../../../../../assets/img/logo.png"> </div> -->
                                    <h6 class="msg-info text-center">Veuillez remplir ces champs svp!</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Code OTP</label>
                                        <input type="text" id="token" placeholder="" class="form-control" formControlName="token">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('token')?.errors?.required && (resetPasswordForm?.get('token')?.touched || resetPasswordForm.get('token')?.dirty)">
                                            Le champs nom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Mot de passe
                                        </label> <input type="text" id="newPassword" placeholder="" class="form-control" formControlName="newPassword">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('newPassword')?.errors?.required && (resetPasswordForm.get('newPassword')?.touched || resetPasswordForm.get('newPassword')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>

                                    <div class="form-group"> <label class="form-control-label text-muted">Confirmation de mot de passe
                                    </label> <input type="text" id="confirmPassword" placeholder="" class="form-control" formControlName="confirmPassword">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.required && (resetPasswordForm.get('confirmPassword')?.touched || resetPasswordForm.get('confirmPassword')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>

                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="resetPassword()" [disabled]="loaderVisible">Changez votre mot de passe</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Changez de mot de passe en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!--start inscription -->

<!-- <ng-template #contentResultsign let-modal>
    <div class="modal-body login">
        <form [formGroup]="inscriptionForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <div class="row justify-content-center px-3 mb-3"> <img id="logo" src="../../../../../assets/images/logo.png"> </div>
                                    <h6 class="msg-info text-center">Veuillez remplire ces champs svp!</h6>

                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input type="email" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.required && (inscriptionForm.get('email')?.touched || inscriptionForm.get('email')?.dirty)">
                                            Le champs email est requis</div>
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.pattern">Le champs email est incorrect
                                        </div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Numéro téléphone <span
                        class="text-red">*</span> </label> <input type="text" id="tel" placeholder="" class="form-control" formControlName="tel" prefix="+225 " [mask]="phoneMask">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('tel')?.errors?.required && (inscriptionForm.get('tel')?.touched || inscriptionForm.get('tel')?.dirty)">
                                            Le champs téléphone est requis</div>
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="inscrireutil()" [disabled]="loaderVisible">S'inscrire</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Inscription en cours
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div class="bottom text-center mb-1">
                                <p href="#" class="sm-text mx-auto mb-3">Vous avez déjà un compte?<button class="btn btn-white ml-2" (click)="souscrire()" [disabled]="loaderVisible">Connectez-vous!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template> -->

<ng-template #contentResultsign let-modal>
    <div class="modal-body login">
        <form [formGroup]="inscriptionForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" src="../../../../../assets/img/logo.png"> </div> -->
                                    <h6 class="msg-info text-center">Veuillez remplir ces champs svp!</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Nom</label>
                                        <input type="text" id="nom" placeholder="" class="form-control" formControlName="nom">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('nom')?.errors?.required && (inscriptionForm?.get('nom')?.touched || inscriptionForm.get('nom')?.dirty)">
                                            Le champs nom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Prénom
                                        </label> <input type="text" id="prenom" placeholder="" class="form-control" formControlName="prenom">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('prenom')?.errors?.required && (inscriptionForm.get('prenom')?.touched || inscriptionForm.get('prenom')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse
                                            mail</label> <input type="email" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.required && (inscriptionForm.get('email')?.touched || inscriptionForm.get('email')?.dirty)">
                                            Le champs email est requis</div>
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.pattern">Le champs email est incorrect
                                        </div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Numéro
                                            téléphone</label> <input type="text" id="telephone" placeholder="" class="form-control" formControlName="telephone" prefix="+225 " [mask]="phoneMask">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('telephone')?.errors?.required && (inscriptionForm.get('telephone')?.touched || inscriptionForm.get('telephone')?.dirty)">
                                            Le champs téléphone est requis</div>
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="inscrireutil()" [disabled]="loaderVisible">S'inscrire</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Inscription en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-1">
                                <p href="#" class="sm-text mx-auto mb-3">Vous avez déjà un compte?<button class="btn btn-white ml-2" (click)="souscrire()" [disabled]="loaderVisible">Connectez-vous!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<!--end inscription -->

<ng-template #etreRappeller let-modal>
    <form [formGroup]="rappellerForm">
        <div class="card card2 c-card">
            <div class="row no-margin">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">Me faire rappeler</h6>
                        </div>
                        <div class="col-lg-2 text-right1">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                      class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-3">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="emai" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                <label for="emai" class="form__label">Nom<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('nom').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                <label for="prenom" class="form__label">Prénom<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('prenom').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                <i class="fa fa-envelope form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="emai" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                <label for="emai" class="form__label">Email<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('email').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Téléphone" triggers="mouseenter:mouseleave">
                                <i class="fa fa-phone form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="telephone" class="form__input" autocomplete="off" placeholder="Téléphone" formControlName="telephone" />
                                <label for="telephone" class="form__label">Téléphone<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('telephone').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Disponibilité" triggers="mouseenter:mouseleave">
                                <i class="fa fa-car form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" name="disponibilite" formControlName="disponibilite" name="product" #disponibilite (change)="checkDisponiblite()">
                                    <option value="" disabled selected>Disponibilité</option>
                                    <option *ngFor="let dispo of disponibiteList" [value]="dispo.value">{{dispo?.name}}
                                    </option>
                                </select>
                                <label for="cars" class="form__label">Disponibilité<span class="text-red">
                                        *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && rappellerForm.get('disponibilite').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="rappellerForm.get('disponibilite').value =='Soir' || rappellerForm.get('disponibilite').value =='Matin'">
                            <div class="form" placement="top" ngbPopover="Quelle heure" triggers="mouseenter:mouseleave">
                                <i class="fa fa-car form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" name="heureDisponibilite" formControlName="heureDisponibilite" #heureDisponibilite>
                                    <option value="" disabled selected>Quelle heure</option>
                                    <option *ngFor="let heure of heureDisponibiliteListTemoin" [value]="heure.value">{{heure?.name}}
                                    </option>
                                </select>
                                <label for="cars" class="form__label">Quelle heure<span class="text-red">
                                        *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && rappellerForm.get('heureDisponibilite').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-center">
                    <button class="btn btn-large c-btnAjout" (click)="meRappeller()" [disabled]="sendingRequestRappel">
                  <i class="fa fa-pencil" *ngIf="!sendingRequestRappel"></i> 
                  <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequestRappel"></i>
                  Valider
                </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>


<swal #confirmSubscription2 [reverseButtons]="true" title="Confirmation" text="Voulez-vous vraiment procéder à la souscription ?" icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SÛR'" [cancelButtonText]="'NON, ANNULER'"
    (confirm)="saveDatamrh()">
</swal>
<swal #confirmSubscription [reverseButtons]="true" title="Confirmation" text="Voulez-vous vraiment procéder à la souscription ?" icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SÛR'" [cancelButtonText]="'NON, ANNULER'"
    (confirm)="saveData()">
</swal>
<swal #confirmSubscription [reverseButtons]="true" title="Confirmation" text="Voulez-vous vraiment procéder à la souscription ?" icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SÛR'" [cancelButtonText]="'NON, ANNULER'"
    (confirm)="saveData()">
</swal>
<swal #confirmSubscription2 [reverseButtons]="true" title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?" icon="question" [reverseButtons]="true" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'"
    [cancelButtonText]="'NON, ANNULER'" (confirm)="saveDatamrh()">
</swal>
<ng-template #mypop>
    1.Pour moi-même : mon nom est sur la carte à grise
    <br>2.Pour un tiers :pour une tierce personne
    <br>3.Pour le compte de :c'est ma voiture mais mon nom n'est pas sur la carte à grise
</ng-template>