import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { UtilsService } from 'src/app/shared/service/utils.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  localisation = localStorage.getItem('localisation');
  telephone = localStorage.getItem('telephone');
  email = localStorage.getItem('email');
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  personcontactForm = new FormGroup({
    intitule: new FormControl("", [Validators.required]),
    telephone: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.pattern(this.emailPattern)]),
    commentaire: new FormControl("", []),
})
loadContact: boolean = false;
  constructor(private utilsService : UtilsService,private subscriptionService: SubscriptionService,) { }

  ngOnInit(): void {
  }


  saveContact() { 
    if (this.personcontactForm.invalid) {
        this.utilsService.showToastError("Veuillez remplir tous les champs obligatoire", "Attention !!!");
    } else {
        this.loadContact = true;
        let value = this.personcontactForm.value;
        this.subscriptionService.contactezNous(value).subscribe(resp => {
            if (resp) {
                this.loadContact = false;
                this.utilsService.showToastSuccess("Votre demande de contact a été enregistrée avec succès", "Demande réussie");
                this.personcontactForm.reset();
            } else {
                this.loadContact = false;
                this.utilsService.showToastError(resp['message'], "Erreur")
            }
        }, error => {
            this.loadContact = false;
            this.utilsService.showToastError(error.error['errors'][0]['message'], "Erreur")
        });
    }
}

}
