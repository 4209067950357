<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2A activedH">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <!-- <h2>Votre paiement a été effectué avec succès.</h2> -->
                </div>
            </div>
        </div>
    </div>
</div>


<section class="faq-contact-area ptb-100 pt-0" *ngIf="showMessage">
    <div class="container">
        <div class="section-title">
            <h2 class="activate"> {{message}} <i *ngIf="loader" class="fa fa-spinner fa-pulse"></i></h2>
        </div>
    </div>
    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</section>

<section class="faq-contact-area ptb-100 pt-0" *ngIf="!showMessage">
    <div class="container">
        <div class="section-title">
            <h2 class="activate">Votre attestation a été générée avec succès.</h2>
            <div class="col-lg-12 col-md-12 marG">
                <button type="submit" class="default-btn c-uppercase" (click)="voirAttestation()">Voir Attestation<span></span></button>
                <button type="submit" class="default-btn c-uppercase c_fac" (click)="showFacture()">Voir Facture<span></span></button>
            </div>
        </div>
    </div>

    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</section>



<!--start facture -->
<ng-template #facture let-modal>
    <div class="modal-body bg-white autodown" #facture id="facture">

        <div class="facture">
            <div class="recu">
                <div class="my-logo">
                    <img class="logo_size" [src]="souscription?.assureur?.logo" [alt]="souscription?.assureur?.displayMe">
                    <img class="logo_size" [src]="abijanaiseLogo" alt="logo abijanaise">
                </div>
                <div class="title">
                    <p>Facture N° {{souscription?.numeroSouscription}}/401/3968 </p>
                    <p>DU {{souscription?.dateCreation | date: 'dd/MM/yyyy'}}</p>
                </div>
                <div class="contenu">
                    <div class="detailRecu">
                        <div class="formInfMiddle">
                            <div class="contenuClient">
                                <div class="info">
                                    <div class="pl-4 border-l text-md">
                                        <div class="data">
                                            <span class="name font-bold">Client/Souscripteur</span>
                                        </div>
                                        <div class="data">
                                            <span class="name font-bold">
                                                {{souscription?.souscripteur?.prenom | titlecase}} {{souscription?.souscripteur?.nom | titlecase}}
                                          </span>
                                        </div>
                                        <div class="data">
                                            <span class="name">BP ABIDJAN 01</span>
                                        </div>
                                        <div class="data">
                                            <span class="name">Abidjan</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contenu">
                    <div class="detailRecu">
                        <div class="formInfMiddleTwo">
                            <div class="contenuClient">
                                <div class="info">
                                    <div class="pl-4 border-l text-md">
                                        <div class="dataOne">
                                            <span class="name">Assurance</span>
                                            <span class="value font-bold">Automobile</span>
                                        </div>
                                        <div class="dataTwo">
                                            <span class="name1">N° Police</span>
                                            <span class="value1 font-bold">{{souscription?.numeroSouscription}}</span>
                                            <span class="name2">Avenant</span>
                                            <span class="value2 font-bold">Nouvelle Affaire</span>
                                        </div>
                                        <div class="dataThree">
                                            <span class="name1">Date d'effet</span>
                                            <span class="value1 font-bold"> {{souscription?.dateEffet | date: 'dd/MM/yyyy'}}</span>
                                            <span class="name2">Date Expiration</span>
                                            <span class="value2 font-bold">{{souscription?.dateEcheance | date: 'dd/MM/yyyy'}}</span>
                                        </div>
                                        <div class="dataFour">
                                            <span class="name1">Référence</span>
                                            <span class="value1 font-bold">Contrat Sans Tacile Reconduction</span>
                                        </div>
                                        <div class="dataFive">
                                            <span class="name1">Bureau Souscription</span>
                                            <span class="value1 font-bold">3968 L'Abidjanaise d'assurance</span>
                                        </div>
                                        <hr class="new1">
                                        <div class="dataSix">
                                            <span class="name1 font-bold">Risque(s) Assuré(s):</span>
                                            <span class="value1 font-bold">N° Immatriculation : {{souscription?.immatriculation}}</span>
                                        </div>
                                        <div class="dataSeven">
                                            <span class="name1 font-bold">Pour plus de détail:</span>
                                            <span class="value1 font-bold">se référer au Conditions Particulières</span>
                                        </div>
                                        <hr class="new1">
                                        <div class="dataHeigh" *ngFor="let item of souscription?.detailsVehicule">
                                            <span class="name1" *ngIf="item.key!='Prime Totale TTC' && item.key!='Prime RC' && item.key!='Tierce Collision' && item.key!='Incendie' && item.key!='Vol' && item.key!='Bris de glace' && item.key!='Avance et recours' && item.key!='Défense et recours' && item.key!='Option4 (Personne Transportée)' && item.key!='Option1 (Personne Transportée)' && item.key!='Option2 (Personne Transportée)' && item.key!='Option3 (Personne Transportée)' && item.key!='Tierce plafonnée' && item.key!='Tierce Complète'">{{item.key}}</span>
                                            <span class="value1 font-bold" *ngIf="item.key!='Prime Totale TTC' && item.key!='Prime RC' && item.key!='Tierce Collision' && item.key!='Incendie' && item.key!='Vol' && item.key!='Bris de glace' && item.key!='Avance et recours' && item.key!='Défense et recours' && item.key!='Option4 (Personne Transportée)' && item.key!='Option1 (Personne Transportée)' && item.key!='Option2 (Personne Transportée)' && item.key!='Option3 (Personne Transportée)' && item.key!='Tierce plafonnée' && item.key!='Tierce Complète'">{{item.value | number:'1.0-0':'fr'}}</span>
                                        </div>
                                        <hr class="new2">
                                        <div class="dataNight">
                                            <span class="name1 font-bold">Prime Totale à Payer = </span>
                                            <span class="value1 font-bold">{{souscription.prix | number:'1.0-0':'fr'}}</span>
                                        </div>
                                        <div class="dataTen">
                                            <span class="name1">Arrêter la présente facture à la somme de :</span>
                                            <span class="value1 font-bold">{{lettreToNumber}} FCFA</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contenu">
                    <div class="detailRecu">
                        <div class="formInfMiddleTwo">
                            <div class="contenuEnd">
                                <div class="info">
                                    <div class="pl-4 text-md">
                                        <div class="footer">
                                            <span class="name">Fait A Abidjan, le {{today | date: 'dd/MM/yyyy'}}</span>
                                            <span class="value font-bold">Pour la Société</span>
                                        </div>
                                        <div class="end">
                                            <span>Payable à notre guichet en espèce</span><br>
                                            <span>ou par chèque ou Virement Bancaire à nos comptes:</span>
                                            <ul>
                                                <li>- BICICI</li>
                                                <li>- CI 00 6 01392 010412000064 76</li>
                                                <li>- IBAN CI 93 CI 00 6013 9201 0412 00006476</li>
                                                <!-- <li>- ORABANK CI <span class="hrH">|</span> - BANQUE POPULAIRE DE CI</li>
                                                <li>- ECOBANK GREEN PAY / CI0590100112000109200222 <span class="hrH">|</span> - CARTE DG CMB</li>
                                                <li>- BACIDAT <span class="hrH">|</span> - STANBIC SPOT</li>
                                                <li>- CARTE HEREMA <span class="hrH">|</span> - BOA RECOMMANDATION </li>
                                                <li>- SGBCI BUSINESS C / 01171675331 <span class="hrH">|</span> - SGCI VISA CARTE / 1117435162271</li>
                                                <li>- SCB <span class="hrH">|</span> - BOA ASSUR/RC SCOLAIRE/SAHAM/01029410768</li>
                                                <li>- BOA MOY PAIEMENT PACK BUSINESS / 01029410663 <span class="hrH">|</span> - BOA ASSURANCE CREDIT BAIL / 01029410731</li>
                                                <li>- CORIS BANK CAE</li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div>
        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left c-roundbtn" (click)="modal.close('close')"><i class="fa fa-chevron-left"></i>Fermer</button>
        <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()">Télécharger
                <i class="fa fa-download"></i>
        </button>
    </div>
</ng-template>



<!-- end facture -->