<app-header-two></app-header-two>

<!-- <div class="home-area home-slides-two owl-carousel owl-theme"> -->
<!-- <div class="banner-section item-bg3"> -->
<!-- <div class="d-table"> -->
<!-- <div class="d-table-cell"> -->
<!-- <div class="container"> -->
<!-- <div class="main-banner-content">
                        <span class="sub-title">Enjoy Your Happiness</span>
                        <h1>Family! The key to Your Balancing Life</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <div class="btn-box">
                            <a routerLink="/contact" class="optional-btn">Contact Us <span></span></a>
                        </div>
                    </div> -->




















<div class="container-gauche bg-dark" *ngIf="login">
    <div class="mask">
    </div>
    <h2 class="text-center main-title"><i> Simulation assurance automobile</i></h2>

    <div class="decoration-droite bg-dark"></div>


</div>
<div class="row desc container-row justify-content-center" *ngIf="login">

    <div class="col-12 droite">
        <form [formGroup]="simulationVehiculeForm" class="auto">
            <aw-wizard class="stepper-container bg-whtie pb-5" #wizard [navBarLocation]="'top'" [navBarLayout]="'large-empty'" [disableNavigationBar]="false">


                <!-- Step 1  Vehicule-->

                <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Vehicule">
                    <div class="form-container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Catégorie du véhicule à assurer" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="typeVehicule" (change)="selectVehiculeCategories(vehicule.value)" name="product" #vehicule>
                                        <option value="" disabled selected>Catégorie de voiture</option>
                                        <option *ngFor="let v of vehiculeCategories" [value]="v.id">{{v?.libelle}}</option>
                                      </select>
                                    <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeVehicule').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && camion">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Poids du vehicule"
                                        triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-balance-scale text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <input type="number" formControlName="poids" class="form-control" placeholder="Poids du véhicule">
                                      </div>
                                    </div> -->
                                <div class="form" ngbPopover="Poids du vehicule" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-balance-scale form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" formControlName="poids" id="poidsVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="poidsVehicule" class="form__label">Poids du véhicule<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('poids').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Type de Carburant"
                                        triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-bus text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <select class="form-control" name="product" formControlName="typeMoteur">
                                          <option value="" disabled selected="selected">Type de carburant</option>
                                          <option value="essence">Essence</option>
                                          <option value="gasoil">Gasoil</option>
                                          <option value="electrique">Electrique</option>
                                          <option value="Hybride">Hybride</option>
                                        </select>
                                      </div>
                                    </div> -->
                                <div class="form" placement="top" ngbPopover="Type de Carburant" triggers="mouseenter:mouseleave">
                                    <i class="fas fa-gas-pump form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" name="product" formControlName="typeMoteur">
                                        <option value="" disabled selected="selected">Type de carburant</option>
                                          <option value="essence">Essence</option>
                                          <option value="gasoil">Gasoil</option>
                                          <option value="electrique">Electrique</option>
                                          <option value="Hybride">Hybride</option>
                                      </select>
                                    <label for="cars" class="form__label">Type de carburant<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeMoteur').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && !moto">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Nombre de places"
                                        triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-users text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <input type="number" class="form-control" formControlName="nombrePlace"
                                          placeholder="Nombre de place " *ngIf="categorieVehicule && !vp" value="">
                                        <input type="number" class="form-control" formControlName="nombrePlace"
                                          placeholder="Nombre de place " *ngIf="categorieVehicule && vp" value="5">
                    
                                      </div>
                                    </div> -->
                                <div class="form" ngbPopover="Nombre de places" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-balance-scale form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" formControlName="nombrePlace" id="place" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && !vp" value="" />
                                    <input type="number" formControlName="nombrePlace" id="place" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && vp" value="5" />
                                    <label for="place" class="form__label">Nombre de places<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('nombrePlace').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="categorieVehicule ">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Puissance fiscale"
                                        triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-bolt text-white"><span class="text-red">*</span></i>
                                        </div>
                                        <input type="number" min="5" formControlName="puissance" class="form-control "
                                          placeholder="Puissance">
                                      </div>
                                    </div> -->
                                <div class="form" ngbPopover="Puissance fiscale" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-bolt form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" min="5" formControlName="puissance" id="puissanceV" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="puissanceV" class="form__label">Puissance<span class="text-red"> *</span></label>
                                </div>
                                <!-- <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('puissance').value && ((simulationVehiculeForm.get('puissance').value | number) < 1 || (simulationVehiculeForm.get('puissance').value | number) > 1000) ">
                                                        La valeur doit être comprise entre 1 et 1000 ch
                                                    </div> -->
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('puissance').invalid">
                                    Ce champ est obligatoire
                                </div>
                                <div class="dateImage">
                                    <span class="dateImageText">Voir la puissance de la voiture:</span>
                                    <span class="puissanceImageNew"> Nouvelle</span>
                                    <span class="barre">|</span>
                                    <span class="puissanceImageOld"> Ancienne</span>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule ">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Immatriculation"
                                        triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-users text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <input type="text" class="form-control" formControlName="immatriculation"
                                          placeholder="Immatriculation du véhicule">
                                      </div>
                                    </div> -->
                                <div class="form" ngbPopover="Immatriculation" triggers="mouseenter:mouseleave">
                                    <i class="fas fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="immatriculation" id="immatriculationV" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="immatriculationV" class="form__label">Immatriculation du véhicule<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('immatriculation').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Marque du vehicule"
                                        triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-car text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <select class="form-control" id="mySelect" formControlName="marque" (change)="marque()">
                                          <option value="" disabled selected="selected"> Marque du vehicule</option>
                                          <option *ngFor="let item of listMarques" value="{{item?.make}}">{{item?.make}}</option>
                                        </select>
                                      </div>
                                    </div> -->
                                <div class="form" placement="top" ngbPopover="Marque du vehicule" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select placeholder=" " class="form__select" id="mySelect" formControlName="marque" (change)="marque()">
                                        <option value="" disabled selected="selected"> Marque du vehicule</option>
                                        <option *ngFor="let item of listMarques" value="{{item?.make}}">{{item?.make}}</option>
                                      </select>
                                    <label for="mySelect" class="form__label">Marque du vehicule<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('marque').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule)&& simulationVehiculeForm.get('marque').valid ">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top"
                                        ngbPopover="Modèle de {{ simulationVehiculeForm.get('marque').value}}!"
                                        triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-car text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <select class="form-control" formControlName="modele">
                                          <option value="" disabled selected="selected"> Modèle du vehicule</option>
                                          <option *ngFor="let item of listModeles" [value]="item?.model">{{item?.model}}</option>
                                        </select>
                                      </div>
                                    </div> -->
                                <div class="form" placement="top" ngbPopover="Modèle de {{ simulationVehiculeForm.get('marque').value}}!" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select placeholder=" " class="form__select" id="cars" formControlName="modele">
                                        <option value="" disabled selected="selected"> Modèle du vehicule</option>
                                        <option *ngFor="let item of listModeles" [value]="item?.model">{{item?.model}}</option>
                                      </select>
                                    <label for="cars" class="form__label">Modèle du vehicule<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('modele').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Date de mise en circulation"
                                        triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <input type="text" class="form-control" [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today"
                                          (dateSelect)="onDateSelect($event,'date_mis_en_circulation')" ngbDatepicker #debut="ngbDatepicker"
                                          (click)="debut.toggle()" placeholder="Date de mise en circulation">
                                      </div>
                                    </div> -->
                                <div class="form" placement="top" ngbPopover="Date de mise en circulation" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="dateCirculation" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today" (dateSelect)="onDateSelect($event,'date_mis_en_circulation')" ngbDatepicker #debut="ngbDatepicker"
                                        (click)="debut.toggle()" />
                                    <label for="dateCirculation" class="form__label">Date de mise en circulation<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_mis_en_circulation').invalid">
                                    Ce champ est obligatoire
                                </div>
                                <div class="dateImage">
                                    <span class="dateImageText">Voir la date 1ère mise en circulation:</span>
                                    <span class="dateImageNew"> Nouvelle</span>
                                    <span class="barre">|</span>
                                    <span class="dateImageOld"> Ancienne</span>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Date d'effet " triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <input type="text" class="form-control" [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today"
                                          (dateSelect)="onDateSelect2($event,'date_effet')" ngbDatepicker #dateEffet="ngbDatepicker"
                                          [value]="date" (click)="dateEffet.toggle()" placeholder="Date d'effet du contrat">
                                      </div>
                                    </div> -->
                                <div class="form" placement="top" ngbPopover="Date d'effet" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="effet" class="form__input" autocomplete="off" placeholder=" " [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today" (dateSelect)="onDateSelect2($event,'date_effet')" ngbDatepicker #dateEffet="ngbDatepicker" [value]="this.simulationVehiculeForm.get('date_effet').value | date: 'dd/MM/yyyy'"
                                        (click)="dateEffet.toggle()" />
                                    <label for="effet" class="form__label">Date d'effet du contrat<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_effet').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                <div>
                                    <!-- <div class="form-group">
                                        <div class="input-group" placement="top" ngbPopover="Durée du contrat"
                                          triggers="mouseenter:mouseleave">
                                          <div class="input-group-append">
                                            <i class="fa fa-hourglass-2  text-white"> <span class="text-red">*</span></i>
                                          </div>
                                          <select class="form-control" (change)="checkDuree()" name="contract-duration"
                                            formControlName="duree">
                                            <option value="" disabled selected>Durée du contrat</option>
                                            <option *ngFor="let d of listDureesFiltrer" [value]="d.duree">{{d.displayMe}}</option>
                                          </select>
                                        </div>
                                      </div> -->
                                    <div class="form" placement="top" ngbPopover="Durée du contrat" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-hourglass-2 form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select placeholder=" " class="form__select" id="cars" (change)="checkDuree()" name="contract-duration" formControlName="duree">
                                        <option value="" disabled selected>Durée du contrat</option>
                                        <option *ngFor="let d of listDureesFiltrer" [value]="d.duree">{{d.displayMe}}</option>
                                      </select>
                                        <label for="cars" class="form__label">Durée du contrat<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('duree').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()"><i
                                      class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="valider()">Suivant <i
                                      class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>

                <!-- Step 2  Garanties-->

                <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Garanties">
                    <div class="form-container c-form-container">
                        <div class="row">
                            <div class="col-12 text-center p-2 mrhgarantie">
                                <i class="fa fa-check-circle mr-2 text-success"></i>
                                <b>Responsabilité civile des conducteurs (Garantie par défaut)</b>
                            </div>
                        </div>
                        <div *ngIf="garanties?.length > 0 " class="row">
                            <div class="c-col-lg-6 col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngFor="let g of garanties">
                                <div class="value" *ngIf="g.sousGarantie.length==0">
                                    <!-- <input class="inputCheck" [checked]="!g.selected" *ngIf="!g.selected" (click)="addGarantie(g, sg)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}"> -->
                                    <!-- <span placement="top" [ngbPopover]="popValeur"
                                      triggers="mouseenter:mouseleave" > -->
                                    <input class="inputCheck" [disabled]="g.disabled" [checked]="g.selected" (change)="addGarantie(g,g.sousGarantie)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}">
                                    <label for="{{g.code}}"> {{g?.nom}} </label>
                                    <span class="garantDescription" *ngIf="g.code === 'gtcom'" placement="right" [ngbPopover]="g.description"> ?</span>
                                    <span class="garantDescription" *ngIf="g.code === 'bris' && (complete || collision || plafone)" placement="right"> (Garantie gratuite)</span>
                                    <!-- </span> -->
                                </div>
                                <div class="value" *ngIf="g.sousGarantie.length>0">
                                    <label for="" class="label-SG"> {{g?.nom}}</label>
                                    <div *ngFor="let sg of g.sousGarantie" class="sgGarantie">
                                        <!-- <input class="inputCheck" [checked]="!g.selected" *ngIf="!g.selected" (click)="addGarantie(g, sg)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}"> -->
                                        <input class="inputCheck" [checked]="sg.selected" (change)="addGarantie(sg,sg,g.sousGarantie)" type="radio" [value]="sg" name="{{sg.code}}" id="{{sg.code}}">
                                        <label for="{{g.code}}"> {{sg?.nom}}</label>
                                        <span class="garantDescription" *ngIf="g.code === 'gpt'" placement="right" [ngbPopover]="sg.description"> <i class="fa fa-hand-o-right form__icon_option"></i>   <label for="{{g.description}}"> {{sg?.description}}</label></span>
                                        <!-- <div class="descriptionOption"></div> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="noDataGaranties" *ngIf="garanties?.length == 0 ">
                            Chargemet en cours ...
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="complete || collision || plafone">
                                <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-money form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (blur)="setValeurVenale()" (keyup)="onBlurEventneuf($event)" />
                                    <label for="neuf" class="form__label">Valeur à neuf<span class="text-red"> *</span></label>
                                </div>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                Ce champ est obligatoire
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="incendie || collision || vol || plafone">
                                <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-credit-card-alt form__icon c-icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                    <label for="venal" class="form__label">Valeur vénale<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                    Ce champ est obligatoire
                                </div>
                                <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                    La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="popValue">
                        <ng-template #popValeur>
                            <div class="card" *ngIf="incendie || collision || vol || plafone || complete">
                                <div class="card-header">
                                    Veuillez renseigner le(s) champs suivant(s)
                                </div>
                                <div class="card-body">
                                    <div *ngIf="complete">
                                        <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-money form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (keyup)="onBlurEventneuf($event)" />
                                            <label for="neuf" class="form__label">Valeur à neuf<span class="text-red"> *</span></label>
                                        </div>
                                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                    <div *ngIf="incendie || collision || vol || plafone">
                                        <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-money form__icon c-icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                            <label for="venal" class="form__label">Valeur vénale<span class="text-red"> *</span></label>
                                        </div>
                                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                        <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                            La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                                      class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="processSimulation()"><i
                                      class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i> Suivant <i
                                      class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>


                <!-- Step 3  Assureur-->

                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assureur">
                    <div class="">
                        <div class="container justify-content-center">
                            <div class="noAssureur" *ngIf="sendingRequestAssureur">
                                <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
                                <p class="text-center text-ass">Chargement en cours...</p>
                            </div>
                            <div *ngIf="false">Veuillez selectionner les assureurs pour un comparateur de prix.</div>
                            <ng-select *ngIf="false" class="select-ass" multiple="true" [(ngModel)]="selectedAss" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" (change)="selectAssureur()" [dropdownPosition]="'bottom'" [ngModelOptions]="{standalone: true}">
                                <ng-option *ngFor="let ass of listAssureurs" [value]="ass.id"> <img [src]="ass.img" class="img-assureur" [alt]="ass.nom"> <span class="text-assureur">{{ass?.nom}}</span> </ng-option>
                            </ng-select>
                            <div class="justify-content-center">

                                <!--  liste des assureurs -->
                                <div class="row">

                                    <div class="row col-md-12 col-lg-12 col-sm-6 col-xs-12 card-assureur" *ngFor="let res of listAssureurs">

                                        <!-- Partie Assureur -->

                                        <div class="col-3" id="ads" ngbPopover="Cliquer pour choisir {{res.nom}}" triggers="mouseenter:mouseleave">
                                            <div class="card rounded" (click)="processSimulation2(res)">
                                                <div class="card-image">
                                                    <img class="img-fluid" [src]="res.img" alt="{{res.nom}}" height="177px" />
                                                </div>
                                            </div>
                                        </div>
                                        <!-- les boutons -->
                                        <div class="col-2 mb-3">

                                            <div class="col-6">
                                                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                    <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="openModalEnvoyeDevis(res)">
                                                    <i class="fa fa-phone mt-2 mr-2" aria-hidden="true"></i>
                                                    <span>Etre rappelé</span>
                                                  </button>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                    <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="processSimulation2(res)">
                                                  <i class="fa fa-cloud-download mt-2 mr-2" aria-hidden="true"></i>
                                                  <span>Télécharger</span>
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 mb-3">
                                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="devisParMail(res)">
                                                  <i class="fa fa-envelope mt-2 mr-2" aria-hidden="true"></i>
                                                  <span>Devis par E-mail</span>
                                              </button>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="devisParWatshap(res)">
                                                  <i class="fa fa-whatsapp mt-2 mr-2" aria-hidden="true"></i>
                                                  <span>Devis par watsapp</span>
                                              </button>
                                            </div>
                                        </div>
                                        <div class="col-3 posiPrix">
                                            <div class="c-card-ass my-price text-center">
                                                <h6 class="nom">Prix:&nbsp; <span *ngIf="res.prix"> {{res.prix |number:'1.0-0':'fr'}} &nbsp;
                                                  FCFA</span> <span *ngIf="!res.prix" class="fa fa-spinner fa-pulse"> </span></h6>
                                            </div>
                                        </div>
                                        <div class="col-2 posiPrix">
                                            <button type="button" class="mb-3 btn btn-assureurSouscripteur c-btn-prev c-roundAssureurSouscrip" (click)="goToNext(res)">
                                                <span>Souscrire en ligne</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <div class="noAssureur" *ngIf="sendingRequest">
                                    <i class="fa fa-spinner fa-pulse"></i><br>
                                    <p class="text-center">Chargement devis en cours...</p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                <button type="button" class="btn ml-0 btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i
                                        class="fa fa-chevron-left"></i></button>
                            </div>
                        </div>
                        <!-- <button type="button" class="btn btn-primary btn-phone pull-right c-roundbtn" (click)="mail()">Me faire rappeler <i
                                        class="fa fa-phone c-phone"></i></button> -->
                    </div>
                    <div class="recap-form-container" *ngIf="false" #autoDownload id="autoDownload">
                        <div class="mesImage">
                            <div class="imgLogo">
                                <img [src]="currentAssureur.img" alt="">
                            </div>
                        </div>
                        <div class="info-devis">
                            <div class="proposition">
                                <div class="pro-title"> Proposition automobile</div>
                                <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Références du souscripteur1</span>
                                        <!-- (click)="modal.close('close')" -->
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.email}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.telephone}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.adresse}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.profession}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.secteuractivte}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Intermédiare</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span> Références Proposition</span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.adresse}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm.get('date_effet').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à Minuit
                                            </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}} </div>
                                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Mouvement</div>
                                          <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Affaire nouvelle  </div>
                                          <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Barème</div>
                                          <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Temporaire  </div>
                                          <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Type</div>
                                          <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Mono  </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                    <div class="title-div">
                                        Identification du véhicule
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('immatriculation').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('marque').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('puissance').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur à neuf</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('valeurNeuf').value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        <span class="fa fa-check"></span>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                        {{simulationVehiculeForm.get('typeMoteur').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('nombrePlace').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('poids').value}} </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('modele').value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur vénale</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">

                                                        {{simulationVehiculeForm.get('valeurVenal').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SENEGAL </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="proposition2">
                                <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
                            </div>
                            <div class="row c-row">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        Nature des garanties
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                                                {{garantie.key}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Capitaux garanties
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                                <span> {{garantie.value |number:'1.0-0':'fr'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Franchises par sinistre
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                                <span class="c-col-val-neant">Néant</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="description-ass">
                                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
                                      {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                            </div>
                            <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                <div *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item.key}}</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.key}}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-val">
                                                <div class="val" *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item.value | number:'1.0-0':'fr'}} FCFA</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value | number:'1.0-0':'fr'}}
                                                FCFA</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="disposition">
                                <div class="title-disp">
                                    Dispositions complémentaires
                                </div>
                                <div class="desc-disp">
                                    La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de l'article 13
                                    <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales jointes, les conditions particulières ainsi que les closes en annexe.</div>
                                    <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute disposition des conditions générales (CG) qui leurs sont contraires.</div>
                                    <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de l'assureur et du souscripteur. </div>
                                </div>
                            </div>
                        </div>
                        <div class="footer-devis row">
                            <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                                <div class="fist-line">
                                    <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec conseil d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                                        number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                                    <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le code des assurances CIMA. Son siège est situé à : <b>Mermoz Pyrotechnie Lot 3</b>.
                                </div>
                                <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email: &nbsp;
                                    <b>{{currentAssureur.email}}</b> </div>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                                <img class="footer-img" [src]="currentAssureur.img" alt="">
                            </div>
                        </div>
                        <!-- <div class="row justify-content-center">
                                  <div class="col-5">
                                    <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i
                                        class="fa fa-chevron-left"></i> Retour</button>
                                  </div>
                                  <div class="col-5">
                                    <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn" [swal]="confirmSubscription2"
                                      [disabled]="sendingRequest"> <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
                                  </div>
                                </div> -->
                        <div>
                            <div class="row">
                                <div class="col-4">
                                    <!-- (click)="modal.close('close')" -->
                                    <button type="button" class="btn btn-warning btn-prev c-btn-prev  pull-left c-roundbtn"><i
                                          class="fa fa-chevron-left"></i></button>
                                </div>
                                <div class="col-4 text-center">
                                    <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()">Télécharger <i
                                          class="fa fa-download"></i></button>
                                </div>
                                <div class="col-4">
                                    <!-- (click)="modal.close('subscribe')" -->
                                    <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn">
                                        Souscrire <i class="fa fa-angle-double-right"></i>
                                      </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </aw-wizard-step>

                <!-- Step 4  Assuré-->

                <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Assuré">
                    <div class="form-container" formGroupName="assure">
                        <div class="row assurance-for justify-content-right">
                            <!-- <h6 class="col-3">Je  souscris ?</h6> -->
                            <!-- <div class="radio-toolbar col-12">
                                    <input type="radio" id="moi" name="radioFruit" value="1" checked>
                                    <label for="moi" (click)="setAssure(1)">Pour moi-même</label>
                                    <input type="radio" id="tiers" name="radioFruit" value="2">
                                    <label for="tiers" (click)="setAssure(2)">Pour un tiers</label>
                                  </div> -->

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Assuré" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-intersex form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" formControlName="choix" placeholder=" " class="form__select" name="radioFruit" (change)="setAssure(simulationVehiculeForm.get('assure').get('choix').value)">
                                        <!-- <option value="" selected disabled>Assuré</option> -->
                                        <option selected value="1">Pour moi-même</option>
                                        <option value="2">Pour un tiers</option>
                                        <option value="3">Pour le compte de</option>
                                      </select>
                                    <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                        </div>
                        <div class="row no-margin">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <input type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                                      </div>
                                    </div> -->
                                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="prenom" id="prenom" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="prenom" class="form__label">Prénom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('prenom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <input type="text" class="form-control" formControlName="nom" placeholder="Nom">
                                      </div>
                                    </div> -->
                                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="nom" id="nom" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="nom" class="form__label">Nom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('nom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Numéro de téléphone"
                                        triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <input type="text" class="form-control" prefix="+225 " [mask]="phoneMask"
                                          formControlName="numeroTelephone" placeholder="Numéro téléphone">
                                      </div>
                                    </div> -->
                                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-phone form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" id="telephone" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="telephone" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('numeroTelephone').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-envelope text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <input type="text" class="form-control" formControlName="email" placeholder="Email ">
                                      </div>
                                    </div> -->
                                <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-envelope form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="email" id="email" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('email').invalid">
                                    Ce champ est obligatoire ou invalide
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                      <div class="form-group">
                                        <div class="input-group" placement="top"
                                          ngbPopover="Veuillez renseigner le numéro de carte d'identité" triggers="mouseenter:mouseleave"
                                          popoverTitle="CNI">
                                          <div class="input-group-append">
                                            <i class="fa  fa-vcard text-white"> <span class="text-red">*</span></i>
                                          </div>
                                          <input type="text" class="form-control" [mask]="cinMask" formControlName="numeroCni"
                                            placeholder="Numéro CNI">
                                        </div>
                                      </div>
                                      <div class="error min-max text-red"
                                        *ngIf="invalid && simulationVehiculeForm.get('assure').get('numeroCni').invalid">
                                        Ce champ est obligatoire ou invalide
                                      </div>
                                    </div> -->

                            <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                      <div class="form-group">
                                        <div class="input-group" placement="top" ngbPopover="Veuillez renseigner date de naissance"
                                          triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                          <div class="input-group-append">
                                            <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                                          </div>
                                          <input type="text" class="form-control" [maxDate]="today"
                                            [minDate]="{year: 1900, month: 1, day: 1}"
                                            (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker
                                            #dateAsss="ngbDatepicker" (click)="dateAsss.toggle()" placeholder="Date de naissance" value=""
                                            name="dateNaissance">
                    
                                        </div>
                                      </div>
                                      <div class="error min-max text-red"
                                        *ngIf="invalid && simulationVehiculeForm.get('assure').get('dateNaissance').invalid">
                                        Ce champ est obligatoire
                                      </div>
                                    </div> -->

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-intersex form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="genre">
                                        <option value="" selected disabled>Sexe</option>
                                        <option value="M">Masculin</option>
                                        <option value="F">Féminin</option>
                                      </select>
                                    <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <input type="text" class="form-control" formControlName="profession" placeholder="Profession">
                                      </div>
                                    </div> -->
                                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="profession" id="profession" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="profession" class="form__label">Profession<span class="text-red"> *</span></label>
                                </div>
                                <!-- <div class="error min-max text-red"
                                        *ngIf="invalid && simulationVehiculeForm.get('assure').get('nom').invalid">
                                        Ce champ est obligatoire
                                      </div> -->
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="top" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                        <div class="input-group-append">
                                          <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                                        </div>
                                        <input type="text" class="form-control" formControlName="adresse" placeholder="Adresse">
                                      </div>
                                    </div> -->
                                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="adresse" id="adresse" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('adresse').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <!-- <div class="form-group">
                                      <div class="input-group" placement="left" ngbPopover="Veuillez renseigner votre secteur d'activité"
                                        triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                        <div class="input-group-append">
                                          <i class="fa fa-briefcase text-white">
                                          </i>
                                        </div>
                                        <input type="text" class="form-control" formControlName="secteuractivite"
                                          placeholder="Secteur activité">
                                      </div>
                                    </div> -->
                                <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                    <i class="fa fa-briefcase form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="secteuractivite" id="secteur" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="secteur" class="form__label">Secteur activité<span class="text-red"> *</span></label>
                                </div>
                                <!-- <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('souscripteur').get('prenom').invalid">
                                      Ce champ est obligatoire
                                    </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                                      class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [disabled]="sendingRequest" (click)="assuree()">Suivant</button>
                        </div>
                    </div>

                </aw-wizard-step>

                <!-- Step 5  Paiement-->
                <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement" *ngIf="false">
                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center">Choisir le type de livraison</h6>
                        <div class="mt-4 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                            <div class="badge">
                                Domicile
                            </div>
                            <img width="135px" src="assets/images/domicile.jpg" alt="">
                        </div>
                        <div class="mt-4 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                            <img width="135px" src="assets/images/relais.jfif" alt="">
                            <div class="badge">
                                Point relais
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                <label for="adresseLivraison" class="form__label">Adresse<span class="text-red"> *</span></label>
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                        <div class="form-group">
                            <div class="input-group relais" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                                </div>
                                <!-- <label for="email">Marque <span class="text-red">*</span></label> -->
                                <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                                      <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                                      <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                                    </select>
                                <!-- <input type="text" class="form-control" formControlName="marque" placeholder="Marque voiture "> -->
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>

                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center">Choisir un moyen de paiement</h6>
                        <div class="mt-4 badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="simulationVehiculeForm.get('moyenDePaiement').value == pay.code">
                            <div class="badge">
                                {{pay?.nom}}
                            </div>
                            <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'}" alt="">
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                                      class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                                      class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>

                </aw-wizard-step>

                <!-- Step 5  Paiement -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement">
                    <!-- <div class="row mb-4 justify-content-center">
                                <h6 class="col-12 text-center">Choisir le type de livraison</h6>
                                <div class="mt-4 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                                  <div class="badge" >
                                    Domicile
                                  </div>
                                  <img width="135px" src="assets/images/domicile.jpg" alt="">
                                </div>
                                <div class="mt-4 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                                  <div class="badge" >
                                    Point relais
                                  </div>
                                  <img width="135px" src="assets/images/relais.jpg" alt="">
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
                                   *ngIf="pointRelais?.length > 0 ||  pointRelais">
                                <div class="form-group">
                                  <div class="input-group relais" placement="top" ngbPopover="Point de relais"
                                       triggers="mouseenter:mouseleave">
                                    <div class="input-group-append">
                                      <i class="fa fa-home text-white"> <span class="text-red"> *</span></i>
                                    </div>
                                    <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                                      <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                                      <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('pointRelaisControl').invalid">
                                  Ce champ est obligatoire
                                </div>
                              </div> -->
                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center">Choisir le type de livraison</h6>
                        <div class="mt-4 p-0 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                            <img class="c-moyenPaiementimg" src="assets/images/domicile.jfif" alt="">
                            <div class="badge">
                                Domicile
                            </div>
                            <!-- <img width="135px" src="assets/images/domicile.jpg" alt=""> -->
                        </div>
                        <div class="mt-4 p-0 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                            <!-- <i class="fa fa-check-circle mr-2 text-success"></i> -->
                            <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
                            <div class="badge">
                                Point relais
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                        <div class="form mt-5" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                            <i class="fas fa-map-marked-alt form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <select id="mySelect" placeholder=" " class="form__select optionRelais" formControlName="pointRelaisControl">
                                    <option value="" selected disabled>Sélectionner un point de relais</option>
                                    <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                                  </select>
                            <label for="mySelect" class="form__label">Point de relais<span class="text-red"> *</span></label>
                        </div>
                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
                        <div class="form" ngbPopover="AdresseLivraison" triggers="mouseenter:mouseleave">
                            <i class="fa fa-home form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                            <label for="adresseLivraison" class="form__label">Adresse de livraison<span class="text-red"> *</span></label>
                        </div>
                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>

                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center c-titlePaiement">Choisir un moyen de paiement</h6>
                        <div class="mt-5 p-0  badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="simulationVehiculeForm.get('moyenDePaiement').value == pay.code">
                            <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'} + ' c-moyenPaiementimg'" alt="">
                            <div class="badge">
                                {{pay?.nom}}
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                                      class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                                      class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                    <!-- <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i></button> -->
                    <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription" awNextStep><i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button> -->

                </aw-wizard-step>

                <!-- Step 6  Récapitulatif -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '6' }" stepTitle="Récapitulatif">
                    <div class="recap-form-container">
                        <div class="mesImage row">
                            <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                <img src="../../../../../assets/images/PROXIM-ASSUR-logo_blanc.png" alt="">
                            </div>
                            <div class="imgLogoR">
                                <img [src]="logoImage" alt="">
                            </div>
                        </div>
                        <div class="info-devis">
                            <div class="proposition">
                                <div class="pro-title">Devis automobile</div>
                                <!-- <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) -->
                                <div class="sub-title">{{numeroSous}}</div>
                                <!-- </div> -->
                            </div>
                            <div class="row ">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Souscripteur</span>
                                        <!-- (click)="modal.close('close')" -->
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm?.get('assure')?.get('nom')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('email')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('numeroTelephone')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('profession')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('secteuractivite')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assureur</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.nom}} </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Références Proposition</span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="openModalIV()"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm.get('assure').get('nom').value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('date_effet')?.value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à Minuit
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                    <div class="title-div">
                                        <span>Caractéristiques du véhicule</span>
                                        <!-- (click)="modal?.close('close')" -->
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos du véhicule" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('immatriculation').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('marque').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('puissance').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur à neuf</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('valeurNeuf').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        <span class="fa fa-check"></span>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                        {{simulationVehiculeForm.get('typeMoteur').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('nombrePlace').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('poids').value}} </div>
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}} </div>
                                            </div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('modele').value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur vénale</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">

                                                        {{simulationVehiculeForm.get('valeurVenal').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SENEGAL </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="proposition2">
                                <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
                            </div>
                            <div class="row c-row">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span> Nature des garanties</span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les garanties" triggers="mouseenter:mouseleave" (click)="openModalGaran()"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                                                <!-- {{garantie.key}} -->
                                                {{garantie?.key == 'Prime RC' ? 'Responsabilité Civile' : garantie?.key }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Capitaux garanties
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                                <span> {{garantie.value |number:'1.0-0':'fr'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Franchises par sinistre
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                                <span class="c-col-val-neant">Néant</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="description-ass">
                                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b> {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                <div *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item?.key == 'Prime RC' ? 'Responsabilité Civile' : item?.key}} (FCFA)</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">Prime TTC (FCFA)</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                <div class="val" *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item.value | number:'1.0-0':'fr'}}</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value | number:'1.0-0':'fr'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="disposition">
                                    <div class="title-disp">
                                      Dispositions complémentaires
                                    </div>
                                    <div class="desc-disp">
                                      La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
                                      disposition de l'article 13
                                      <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
                                        générales jointes, les conditions particulières ainsi que les closes en annexe.</div>
                                      <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
                                        disposition des conditions générales (CG) qui leurs sont contraires.</div>
                                      <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
                                        visas de l'assureur et du souscripteur. </div>
                                    </div> -->
                            <div class="disposition row">
                                <div class="title-dispo-footerL">
                                    Assureur : {{currentAssureur?.nom}}
                                </div>
                                <div class="title-dispo-footerR">
                                    Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss' }} E-MAIL : info@optima.com
                                </div>
                            </div>
                        </div>
                        <!-- <div class="footer-devis row">
                                  <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                                    <div class="fist-line">
                                      <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec
                                      conseil d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                                        number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                                      <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le
                                      code des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
                                    </div>
                                    <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
                                      &nbsp;<b>{{currentAssureur.email}}</b> </div>
                                  </div>
                                  <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                                    <img class="footer-img" [src]="currentAssureur.img" alt="">
                                  </div>
                                </div> -->

                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                                      class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [swal]="confirmSubscription2" [disabled]="sendingRequest"> <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
                        </div>
                    </div>
                </aw-wizard-step>

            </aw-wizard>
        </form>
        <!-- </div> -->
    </div>
</div>

<div *ngIf="!login" class="desc">
    <!-- <img src="../../../../../assets/images/assurance-auto.jpg" alt="" class="img-1" @slideInOut> -->
    <!-- <img src="../../../../../assets/images/insurance.jpg" alt=""
                        [ngClass]="{'img-2': garanties.length!=0,'img-4':garanties.length==0}" class="img-2" @slideInOut> -->

    <section class="section1 ">
        <div class="row justify-content-center pres">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 photo-auto container">
                <h3 class=" text-left title-ass" @fade> Assurance automobile</h3>
                <p class="mt-5  description" @fade>
                    La RC Auto couvre la responsabilité civile des conducteurs d'un véhicule. Concrètement, elle prend en charge les dommages corporels et matériels subis par les victimes de l'accident que vous avez causé avec votre véhicule.
                </p>
                <div class="Souscrire" (click)="souscrire()">
                    <div class="simulation">
                        <div class="container" @slideInOut>
                            <div class="left-side">
                                <div class="card">
                                    <div class="card-line"></div>
                                    <div class="buttons"></div>
                                </div>
                                <div class="post">
                                    <div class="post-line"></div>
                                    <div class="screen">
                                        <div class="dollar">$</div>
                                    </div>
                                    <div class="numbers"></div>
                                    <div class="numbers-line2"></div>
                                </div>
                            </div>
                            <div class="right-side">
                                <div class="new">Faire une simulation</div>

                                <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 451.846 451.847">
                                      <path
                                        d="M345.441 248.292L151.154 442.573c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744L278.318 225.92 106.409 54.017c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.287 194.284c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373z"
                                        data-original="#000000" class="active-path" data-old_color="#000000" fill="#cfcfcf" />
                                    </svg>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <img src="../../../../../assets/images/auto2.jpg" alt="" class="img-4" @slideInOut>
            </div>
        </div>
    </section>

    <!-- HTML Setup -->
    <section class="section2" *ngIf="garanties.length!=0">
        <div class="container">
            <div class="row">
                <div class="mx-auto">
                    <div class="col-lg-12">
                        <div class="text-center my-3">
                            <div class="section-title">
                                <h2 class="text-center my-5">Garanties</h2>
                            </div>
                            <div class="row card-deck no-gutters">
                                <div class="col-12 col-sm-6 col-md-6 col-lg-4 mt-5" *ngFor="let garantie of garanties">
                                    <div class="card card1 h-100 mb-4">
                                        <div class="box-part text-center" placement="top" triggers="mouseenter:mouseleave">
                                            <img class="heart" src="assets/images/mrh.png" alt="">
                                            <div class="title">
                                                <h4>{{garantie?.nom}}</h4>
                                            </div>

                                            <div class="text">
                                                <h6>Avantages:</h6>
                                                <p class="pr-1 pl-1 textPad">{{garantie?.description}}</p>

                                            </div>
                                            <a class="mb-5"><b>Prix</b></a><br>
                                            <p>{{garantie?.montantFranchise | number : '1.0-0':'fr' }} FCFA</p>

                                            <div class="promotion-price">
                                                <div class="promotion-price-desc"> À partir de </div>
                                                <div class="promotion-price-text">{{garantie?.montantFranchise | number : '1.0-0':'fr' }} FCFA
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
<ng-template #contentResult let-modal>
    <div class="modal-body border border-none">
        <div class="cotainer devis-container bg-white" id="htmlData">
            <div class="row row-devis">
                <div class="col-12">
                    <div class="row row-title-devis align-items-center">
                        <div class="col-6 img-logo">
                            <img src="../../../../assets/images/logo6.png" alt="">
                        </div>
                        <div class="col-6 ">
                            <div class="row Informations">
                                <div class="col-12">
                                    <p>Tel: +225 27 22 23 68 83</p>
                                </div>
                                <div class="col-12">
                                    <p>Email: info@optima.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-subtitle-devis ">
                        <div class="col-3 justify-content-center bg-primary">
                            <p class="devis-text">Devis</p>
                        </div>
                        <div class="col-9">
                            <div>
                                <div class="row assure" *ngIf="rest?.key != 'Prime Totale TTC'">
                                    <div class="col-12 assure-title">
                                        Automobile
                                    </div>
                                    <div class="col-12 assure-text" *ngFor="let res of resultSimulation">
                                        <div class="row align-items-center ">
                                            <div class="col-8">
                                                <p>{{res?.key}}</p>
                                            </div>
                                            <div class="col-4">{{res?.value | number :'1.0-0' : 'fr' }} FCFA</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let res of resultSimulation">
                                <div class="row assure" *ngIf="res?.key == 'Prime Totale TTC'">
                                    <div class="col-12 assure-title">
                                        Totale
                                    </div>
                                    <div class="col-12 assure-text">
                                        <div class="row align-items-center ">
                                            <div class="col-8">
                                                <p>{{res?.key}}</p>
                                            </div>
                                            <div class="col-4">{{res?.value | number :'1.0-0' : 'fr' }} FCFA</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <!-- (click)="modal.close('close')" -->
        <button type="button" class="btn btn-danger">Fermer</button>
        <!-- (click)="modal.close('subscribe')" -->
        <button type="button" class="btn btn-success c-roundbtn">Souscrire</button>
    </div>

</ng-template>

<ng-template #contentResultgrise let-modal>

    <div class="modal-body">
        <div class="cotainer p-2" id="htmlData">
        </div>
    </div>
    <div class="modal-footer">
        <!-- (click)="modal.close('close')" -->
        <button type="button" class="btn btn-danger">Fermer</button>
        <!-- (click)="modal.close('subscribe')" -->
        <button type="button" class="btn btn-success">Souscrire</button>
    </div>

</ng-template>

<ng-template #contentResult2 let-modal>

    <div class="modal-body">
        <div class="card mb-3 c-card-body">
            <div class="row g-0">
                <div class="col-md-12 p-4">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row  align-items-center">
                                    <h5 class="text-center col-12 mb-5">Carte grise</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- (click)="modal.close('close')" -->
    <button class="btn btn-danger  m-auto c-btn-close">Revenir</button>
    <!-- (click)="modal.close('subscribe')" -->
    <button class="btn btn-success m-auto c-btn-close">Continuer</button>

</ng-template>

<ng-template #contentResultcontact let-modal>
    <div class="c-modal-header" id="modal-header DKG">
        <h5 class="modal-title text-white" id="modal-basic-title" id="modal-title">Se faire contacter</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
    </div>
    <div class="modal-body bg-white" id="modal-body">
        <form [formGroup]="personcontactForm">
            <div class="form-container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-append" placement="top" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user-circle-o text-white"> <span class="text-red">*</span></i>
                                </div>
                                <!-- <label for="prenom">Prénom <span class="text-red">*</span></label> -->
                                <input type="text" class="form-control" formControlName="first_name" placeholder="Prénom">
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('first_name')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-user-circle-o text-white"> <span class="text-red">*</span></i>
                                </div>
                                <!-- <label for="nom">Nom <span class="text-red">*</span></label> -->
                                <input type="text" class="form-control" formControlName="last_name" placeholder="Nom">
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('last_name')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Téléphone" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                                </div>
                                <!-- <label for="telephone">Téléphone <span class="text-red">*</span></label> -->
                                <input type="text" class="form-control" prefix="+225 " [mask]="phoneMask" formControlName="telephone" placeholder="Téléphone">
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('telephone')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-home text-white"> </i>
                                </div>
                                <!-- <label for="adresse">Adresse</label> -->
                                <input type="text" class="form-control" formControlName="email" placeholder="Email">
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm.get('email').hasError('pattern')">
                            Ce champ est invalide
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Jour disponible" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                                </div>
                                <!-- <select class="form-control" formControlName="jour">
                                      <option value="" disabled selected="selected"> Jours</option>
                                      <option *ngFor="let item of listJours" [value]="item?.name">{{item?.name}}</option>
                                    </select> -->
                                <ng-select multiple="true" class="select-rappel" placeholder="Jour à être rappeler" formControlName="jour" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" [dropdownPosition]="'bottom'">
                                    <ng-option *ngFor="let jour of listJours" [value]="jour.name"> {{jour.name}} </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('jour')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Heure disponible" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                                </div>
                                <!-- <select class="form-control" formControlName="heure">
                                      <option value="" disabled selected="selected"> Heures</option>
                                      <option *ngFor="let item of listHeures" [value]="item?.name">{{item?.name}}</option>
                                    </select> -->
                                <ng-select multiple="true" class="select-rappel" placeholder="Heure à être rappeler" formControlName="heure" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" [dropdownPosition]="'bottom'">
                                    <ng-option *ngFor="let heure of listHeures" [value]="heure.name"> {{heure.name}} </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="personcontactForm?.get('heure')?.hasError?.required">
                            Ce champ est obligatoire
                        </div>
                    </div>
                </div>
            </div>
            <!-- <button type="button" class="btn btn-warning btn-prev pull-left" awPreviousStep (click)="retour()"><i
                                class="fa fa-chevron-left"></i></button>
                            <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription">
                              <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i> Souscrire <i
                                class="fa fa-angle-double-right"></i></button> -->

            <!-- (click)="modal.close('close')" -->
            <button type="button" class="btn btn-danger" #closeBTN>Fermer</button>
            <button type="button" class="btn btn-success" [disabled]="loadContact" (click)="saveContact()"> <span
                              *ngIf="loadContact" class="fa fa-spinner fa-pulse"></span> Envoyer</button>
        </form>
    </div>

</ng-template>

<ng-template #contentResultass let-modal>
    <div class="modal-body  bg-white  autodown" #autoDownload1 id="autoDownload">
        <div class="mesImage row">
            <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                <img src="../../../../../assets/images/PROXIM-ASSUR-logo_blanc.png" alt="">
            </div>
            <div class="imgLogoR">
                <img [src]="logoImage" alt="">
            </div>
        </div>
        <div class="info-devis">
            <div class="proposition">
                <div class="pro-title">Devis automobile</div>
                <!-- <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) </div> -->
                <div class="sub-title">{{numeroSous}}</div>
            </div>
            <div class="row ">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span>Souscripteur</span>
                        <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages"
                                          triggers="mouseenter:mouseleave" (click)="openModal()" (click)="modal.close('close')"></span> -->
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.firstName}} {{user?.infos?.lastName}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.email}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.telephone}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.adresse}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.profession}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.secteuractivte}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assureur</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.nom}} </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span> Références Proposition</span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>


                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                <!-- {{ simulationVehiculeForm?.get('assure')?.get('prenom')?.value}}  {{ simulationVehiculeForm?.get('assure')?.get('nom')?.value}} -->
                                {{user?.infos?.firstName}} {{user?.infos?.lastName}}
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.adresse}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.email}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.telephone}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{simulationVehiculeForm?.get('date_effet')?.value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à Minuit
                            </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm?.get('duree')?.value }} mois </div>
                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Mouvement</div>
                                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Affaire nouvelle  </div>
                                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Barème</div>
                                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Temporaire  </div>
                                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Type</div>
                                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Mono  </div> -->
                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                        Caractéristiques du véhicule
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('immatriculation')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('marque')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('puissance')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur à neuf</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('valeurNeuf')?.value | number:'1.0-0':'fr'}} </div>
                                    <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel</div>
                                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        <span class="fa fa-check"></span>
                                      </div> -->
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                        {{simulationVehiculeForm?.get('typeMoteur')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('nombrePlace')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('poids')?.value}} </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value |date:'dd/MM/yyyy'}} </div>

                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('modele')?.value}}
                                    </div>
                                    <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                                      <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                      </div> -->
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur vénale</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">

                                        {{simulationVehiculeForm.get('valeurVenal')?.value | number:'1.0-0':'fr'}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value | date:'dd/MM/yyyy'}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SENEGAL </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="proposition2">
                <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
            </div>
            <div class="row c-row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        Nature des garanties
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                                {{garantie?.key == 'Prime RC' ? 'Responsabilité Civile' : garantie?.key }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                    <div class="title-div">
                        Capitaux garanties
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                <span> {{garantie?.value |number:'1.0-0':'fr'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                    <div class="title-div">
                        Franchises par sinistre
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                <span class="c-col-val-neant">Néant</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="description-ass">
                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
                              {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                <div *ngFor="let item of primeAss">
                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item?.key  == 'Prime RC' ? 'Responsabilité Civile' : item?.key }} FCFA</span>
                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">Prime TTC FCFA</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div class="val" *ngFor="let item of primeAss">
                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item?.value | number:'1.0-0':'fr'}}</span>
                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item?.value | number:'1.0-0':'fr'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="disposition row">
                <div class="title-dispo-footerL">
                    Assureur : {{currentAssureur?.nom}}
                </div>
                <div class="title-dispo-footerR">
                    Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss' }}
                    <span class="emailmarg"> E-MAIL : info@optima.com</span>
                </div>
                <!-- <div class="desc-disp">
                              La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de
                              l'article 13
                              <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales
                                jointes, les conditions particulières ainsi que les closes en annexe.</div>
                              <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
                                disposition des conditions générales (CG) qui leurs sont contraires.</div>
                              <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de
                                l'assureur et du souscripteur. </div>
                            </div> -->
            </div>
        </div>
        <!-- <div class="footer-devis row">
                          <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                            <div class="fist-line">
                              <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec conseil
                              d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                                number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                              <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le code
                              des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
                            </div>
                            <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
                              &nbsp;<b>{{currentAssureur.email}}</b> </div>
                          </div>
                          <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                            <img class="footer-img" [src]="currentAssureur.img" alt="">
                          </div>
                        </div> -->
    </div>

    <!-- </div> -->
    <div *ngIf="isSouscription">
        <div class="row">
            <div class="col-4">
                <!-- (click)="modal.close('close')" -->
                <button type="button" class="btn btn-warning btn-prev c-btn-prev  pull-left c-roundbtn"><i
                                class="fa fa-chevron-left"></i></button>
            </div>
            <div class="col-4 text-center">
                <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()">Télécharger <i
                                class="fa fa-download"></i></button>
            </div>
            <div class="col-4">
                <!-- (click)="modal.close('subscribe')" -->
                <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn">
                              Souscrire <i class="fa fa-angle-double-right"></i>
                            </button>
            </div>
        </div>
    </div>
    <div *ngIf="!isSouscription" class="btnSous">

        <button type="button" class="btn btn-primary btn-tel pull-left" (click)="openPDF()"><i
                            class="fa fa-download"></i></button>

    </div>
</ng-template>

<ng-template #contentResultlogin let-modal>
    <!-- <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Déclarer sinistre</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div> -->

    <div class="modal-body login">
        <form [formGroup]="loginform">
            <div class="container px-4 py-5 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3">
                                        <!-- <img id="logo" src="../../../../../assets/images/logo.png"> </div> -->
                                        <!-- <h6 class="msg-info">Veuillez renseigner votre email ou votre nom d'utilisateur et votre mot de passe!
                                      </h6> -->
                                        <h3 class="monCompte"> Mon compte!</h3>
                                        <p class="compteText"> Connectez vous à votre compte SVP.</p>
                                    </div>
                                    <!-- <div class="form-group"> <label class="form-control-label text-muted">Nom d'utilisateur</label> <input
                                          type="text" id="email" name="email" class="form-control" formControlName="username">
                                        <div class="text-red ml-3"
                                          *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                                          Le nom d'utilisateur est requis</div>
                                      </div> -->
                                    <!-- <div class="form-group "> <label class="form-control-label text-muted">Mot de passe</label> <input
                                          type="password" id="psw" class="form-control" formControlName="password">
                                        <div class="text-red ml-3"
                                          *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                                          Le mot de passe est requis</div>
                                      </div> -->
                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="nomUser" class="form__input" autocomplete="off" placeholder=" " formControlName="username" />
                                        <label for="nomUser" class="form__label">Nom d'utilisateur<span class="text-red"></span></label>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                                        Le nom d'utilisateur est requis
                                    </div>

                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-lock form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password" />
                                        <label for="psw" class="form__label">Mot de passe<span class="text-red"></span></label>
                                        <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>
                                        <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                                        Le mot de passe est requis
                                    </div>
                                    <div class="forgetMdp">
                                        <div class="resterConnecter">
                                            <input type="checkbox">
                                            <span> Restez connecter</span>
                                        </div>
                                        <div class="updateMdp">Mot de passe oublié?</div>
                                    </div>
                                    <div class="text-danger text-center mt-1" *ngIf="userInconnu!=''"> {{userInconnu}}</div>
                                    <div> <button class="seConnecter" (click)="connecter()" [disabled]="loaderVisible">Connectez-vous</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Connexion en cours
                                        </p>
                                    </div>
                                    <!-- <div class="row justify-content-center my-2"> <a href="#" sm-text><small class="text-danger"
                                            [disabled]="loaderVisible">Mot de passe oublié?</small></a> </div> -->
                                </div>
                            </div>
                            <div class="bottom text-center mb-3">
                                <p href="#" class="sm-text mx-auto mb-3">Vous n'avez pas de compte?<button class="btn btn-white ml-2" (click)="inscrire()" [disabled]="loaderVisible">Créez-en un!</button></p>
                            </div>
                        </div>
                        <!-- <div class="card card2">
                                        <div class="my-auto mx-md-5 px-md-5 right">
                                            <h3 class="text-white">We are more than just a company</h3> <small class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                                        </div>
                                    </div> -->
                    </div>
                </div>
            </div>
        </form>

    </div>

</ng-template>

<ng-template #contentResultsign let-modal>
    <div class="modal-body login">
        <form [formGroup]="inscriptionForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <div class="row justify-content-center px-3 mb-3"> <img id="logo" [src]="logoImage"> </div>
                                    <!-- <h3 class="mb-5 text-center heading">We are Tidi</h3> -->
                                    <h6 class="msg-info text-center">Veuillez remplire ces champs svp!</h6>
                                    <!-- <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input
                                          type="email" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                        </div> -->
                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-envelope form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                        <label for="email" class="form__label">Adresse mail<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="text-red" *ngIf="inscriptionForm.get('email')?.errors?.required && (inscriptionForm.get('email')?.touched || inscriptionForm.get('email')?.dirty)">
                                        Le champs email est requis</div>
                                    <div class="text-red" *ngIf="inscriptionForm.get('email')?.errors?.pattern">Le champs email est incorrect
                                    </div>

                                    <!-- <div class="form-group"> <label class="form-control-label text-muted">Numéro téléphone <span
                                            class="text-red">*</span></label> <input type="text" id="tel" placeholder=""
                                          class="form-control" formControlName="tel" prefix="+225 " [mask]="phoneMask">
                                        </div> -->
                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-phone form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="tel" class="form__input" autocomplete="off" placeholder=" " formControlName="tel" prefix="+225 " [mask]="phoneMask" />
                                        <label for="tel" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="text-red" *ngIf="inscriptionForm.get('tel')?.errors?.required && (inscriptionForm.get('tel')?.touched || inscriptionForm.get('tel')?.dirty)">
                                        Le champs téléphone est requis</div>
                                    <!-- <div class="form-group"> <label class="form-control-label text-muted" >Mot de passe</label> <input type="password" id="psw"  placeholder="" class="form-control" formControlName="password" >
                                                  <div class="text-red ml-3" *ngIf="inscriptionForm.get('password')?.errors?.required && (inscriptionForm.get('password')?.touched || inscriptionForm.get('password')?.dirty)">Le champs mot de passe est requis</div>
                                                </div> -->

                                    <!-- <div class="form-group text-danger text-center" *ngIf="userInconnu">Nom d'utilisateur et/ ou mot de passe incorrect(s) !</div> -->
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="inscrireutil()" [disabled]="loaderVisible">S'inscrire</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Inscription en cours
                                        </p>
                                    </div>
                                    <!-- <div class="row justify-content-center my-2"> <a href="#"><small class="text-danger">Mot de passe oublié?</small></a> </div> -->
                                </div>
                            </div>
                            <div class="bottom text-center mb-1">
                                <p href="#" class="sm-text mx-auto mb-3">Vous avez déjà un compte?<button class="btn btn-white ml-2" (click)="souscrire()" [disabled]="loaderVisible">Connectez-vous!</button></p>
                            </div>
                        </div>
                        <!-- <div class="card card2">
                                        <div class="my-auto mx-md-5 px-md-5 right">
                                            <h3 class="text-white">We are more than just a company</h3> <small class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                                        </div>
                                    </div> -->
                    </div>
                </div>
            </div>
        </form>

    </div>
</ng-template>

<ng-template #contentResultforgot let-modal>
    <div class="modal-body login">
        <form [formGroup]="forgotForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3"> <img id="logo" [src]="logoImage"> </div>
                                    <!-- <h3 class="mb-5 text-center heading">We are Tidi</h3> -->
                                    <!-- <h6 class="msg-info text-center">Veuillez remplire ces champs svp!</h6> -->
                                    <!-- <div class="form-group"> <label class="form-control-label text-muted" >Prénom et nom</label> <input type="text" id="nom"  placeholder="" class="form-control" formControlName="nom"> </div> -->
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input type="text" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                    </div>
                                    <!-- <div class="form-group"> <label class="form-control-label text-muted">Numéro téléphone</label> <input type="text" id="psw"  placeholder="" class="form-control" formControlName="tel"  prefix="+225 " [mask]="phoneMask"> </div> -->

                                    <!-- <div class="form-group text-danger text-center" *ngIf="userInconnu">Nom d'utilisateur et/ ou mot de passe incorrect(s) !</div> -->
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="forgot()" [disabled]="loaderVisible">Réinitialiser compte</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Réinitialisation en cours
                                        </p>
                                    </div>
                                    <!-- <div class="row justify-content-center my-2"> <a href="#"><small class="text-danger">Mot de passe oublié?</small></a> </div> -->
                                </div>
                            </div>

                        </div>
                        <!-- <div class="card card2">
                                        <div class="my-auto mx-md-5 px-md-5 right">
                                            <h3 class="text-white">We are more than just a company</h3> <small class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                                        </div>
                                    </div> -->
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<swal #confirmSubscription2 title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?" icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'" [cancelButtonText]="'NON, ANNULER'" (confirm)="saveData()">
</swal>

<!--  -->
<ng-template #updateAssures let-modal>
    <form [formGroup]="simulationVehiculeForm">
        <div class="container px-4 py-2 mx-auto">
            <div class="card card0 c-card">
                <div class="row beneficiary-section form-container " formGroupName="assure">
                    <div class="row no-margin">
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                            <div class="row no-margin">
                                <div class="col-10 p-0">
                                    <h6 class="text-left text-white c-cardTitle">Ajouter assuré</h6>
                                </div>
                                <div class="col-lg-2 text-right">
                                    <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                                      class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Type" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)">
                                <option selected disabled>Type</option>
                                <option value="1">Pour moi-même</option>
                                <option value="2">Pour un tiers</option>
                              </select>
                                <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                <i class="fa fa-intersex form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" formControlName="genre">
                                <option value="" selected disabled>Sexe</option>
                                <option value="M">Masculin</option>
                                <option value="F">Féminin</option>
                              </select>
                                <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="prenomAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                <label for="prenomAssure" class="form__label">Prénom<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('prenom').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="nomAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                <label for="nomAssure" class="form__label">Nom<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('nom').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Numéro téléphone" triggers="mouseenter:mouseleave">
                                <i class="fa fa-phone form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="telephoneAssure" class="form__input" autocomplete="off" placeholder=" " prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" />
                                <label for="telephoneAssure" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('numeroTelephone').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                <i class="fa fa-envelope form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('email').invalid">
                                Ce champ est obligatoire ou invalide
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Veuillez renseigner date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                                <i class="fa fa-calendar form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="dateNaissance" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker #dateAssu="ngbDatepicker"
                                    (click)="dateAssu.toggle()" />
                                <label for="dateNaissance" class="form__label">Date de naissance<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('dateNaissance').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                <i class="fa fa-vcard form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="professionAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                                <label for="professionAssure" class="form__label">Profession<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('profession').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                                <i class="fa fa-briefcase form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="activiteAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                                <label for="activiteAssure" class="form__label">Secteur activité<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('secteuractivite').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="adresseAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                                <label for="adresseAssure" class="form__label">Adresse<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('adresse').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <button class="btn  btn-large c-btnAjout" (click)="updateDevise('assure')"><i class="fa fa-pencil"></i> Valider</button>
                        <!-- <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(conjoits)"
                                      *ngIf="updateConj"><i class="fa fa-pencil"></i> Modifier</button> -->
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #updateInfoVehicule let-modal>

    <form [formGroup]="simulationVehiculeForm">
        <div class="container px-4 py-2 mx-auto">
            <div class="card card0 c-card">
                <div class="row beneficiary-section form-container ">
                    <div class="row no-margin">
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                            <div class="row no-margin">
                                <div class="col-10 p-0">
                                    <h6 class="text-left text-white c-cardTitle">Information véhicule</h6>
                                </div>
                                <div class="col-lg-2 text-right">
                                    <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                                      class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Catégorie du véhicule à assurer" triggers="mouseenter:mouseleave">
                                <i class="fa fa-car form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="typeVehicule" (change)="selectVehiculeCategories(vehicule.value)" name="product" #vehicule>
                                    <option value="" disabled selected>Catégorie de voiture</option>
                                    <option *ngFor="let v of vehiculeCategories" [value]="v.id">{{v?.libelle}}</option>
                                  </select>
                                <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeVehicule').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && camion">
                            <div class="form" ngbPopover="Poids du vehicule" triggers="mouseenter:mouseleave">
                                <i class="fa fa-balance-scale form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="number" formControlName="poids" id="poids" class="form__input" autocomplete="off" placeholder=" " />
                                <label for="poids" class="form__label">Poids du véhicule<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('poids').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule">
                            <div class="form" placement="top" ngbPopover="Type de Carburant" triggers="mouseenter:mouseleave">
                                <i class="fas fa-gas-pump form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" name="product" formControlName="typeMoteur">
                                    <option value="" disabled selected="selected">Type de carburant</option>
                                      <option value="essence">Essence</option>
                                      <option value="gasoil">Gasoil</option>
                                      <option value="electrique">Electrique</option>
                                      <option value="Hybride">Hybride</option>
                                  </select>
                                <label for="cars" class="form__label">Type de carburant<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeMoteur').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && !moto">
                            <div class="form" ngbPopover="Nombre de places" triggers="mouseenter:mouseleave">
                                <i class="fa fa-balance-scale form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="number" formControlName="nombrePlace" id="nombrePlace" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && !vp" value="" />
                                <input type="number" formControlName="nombrePlace" id="nombrePlace" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && vp" value="5" />
                                <label for="nombrePlace" class="form__label">Nombre de places<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('nombrePlace').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="categorieVehicule ">
                            <div class="form" ngbPopover="Puissance fiscale" triggers="mouseenter:mouseleave">
                                <i class="fa fa-bolt form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="number" min="5" formControlName="puissance" id="puissance" class="form__input" autocomplete="off" placeholder=" " />
                                <label for="puissance" class="form__label">Puissance<span class="text-red"> *</span></label>
                            </div>
                            <!-- <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('puissance').value && ((simulationVehiculeForm.get('puissance').value | number) < 1 || (simulationVehiculeForm.get('puissance').value | number) > 1000) ">
                                                    La valeur doit être comprise entre 1 et 1000 ch
                                                </div> -->
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('puissance').invalid">
                                Ce champ est obligatoire
                            </div>
                            <div class="dateImage">
                                <span class="dateImageText">Voir la puissance de la voiture:</span>
                                <span class="puissanceImageNew"> Nouvelle</span>
                                <span class="barre">|</span>
                                <span class="puissanceImageOld"> Ancienne</span>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule ">
                            <div class="form" ngbPopover="Immatriculation" triggers="mouseenter:mouseleave">
                                <i class="fas fa-car form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" formControlName="immatriculation" id="immatriculation" class="form__input" autocomplete="off" placeholder=" " />
                                <label for="immatriculation" class="form__label">Immatriculation du véhicule<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('immatriculation').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                            <div class="form" placement="top" ngbPopover="Marque du vehicule" triggers="mouseenter:mouseleave">
                                <i class="fa fa-car form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select placeholder=" " class="form__select" id="mySelect" formControlName="marque" (change)="marque()">
                                    <option value="" disabled selected="selected"> Marque du vehicule</option>
                                    <option *ngFor="let item of listMarques" value="{{item?.make}}">{{item?.make}}</option>
                                  </select>
                                <label for="mySelect" class="form__label">Marque du vehicule<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('marque').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule)&& simulationVehiculeForm.get('marque').valid ">
                            <div class="form" placement="top" ngbPopover="Modèle de {{ simulationVehiculeForm.get('marque').value}}!" triggers="mouseenter:mouseleave">
                                <i class="fa fa-car form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select placeholder=" " class="form__select" id="cars" formControlName="modele">
                                    <option value="" disabled selected="selected"> Modèle du vehicule</option>
                                    <option *ngFor="let item of listModeles" [value]="item?.model">{{item?.model}}</option>
                                  </select>
                                <label for="cars" class="form__label">Modèle du vehicule<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('modele').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                            <div class="form" placement="top" ngbPopover="Date de mise en circulation" triggers="mouseenter:mouseleave">
                                <i class="fa fa-calendar form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="circulation" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today" (dateSelect)="onDateSelect($event,'date_mis_en_circulation')" ngbDatepicker #debut="ngbDatepicker" (click)="debut.toggle()"
                                />
                                <label for="circulation" class="form__label">Date de mise en circulation<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_mis_en_circulation').invalid">
                                Ce champ est obligatoire
                            </div>
                            <div class="dateImage">
                                <span class="dateImageText">Voir la date 1ère mise en circulation:</span>
                                <span class="dateImageNew"> Nouvelle</span>
                                <span class="barre">|</span>
                                <span class="dateImageOld"> Ancienne</span>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                            <div class="form" placement="top" ngbPopover="Date d'effet" triggers="mouseenter:mouseleave">
                                <i class="fa fa-calendar form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="dateEffet" class="form__input" autocomplete="off" placeholder=" " [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today" (dateSelect)="onDateSelect2($event,'date_effet')" ngbDatepicker #dateEffet="ngbDatepicker" [value]="this.simulationVehiculeForm.get('date_effet').value | date: 'dd/MM/yyyy'"
                                    (click)="dateEffet.toggle()" />
                                <label for="dateEffet" class="form__label">Date d'effet du contrat<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_effet').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                            <div>
                                <div class="form" placement="top" ngbPopover="Durée du contrat" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-hourglass-2 form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select placeholder=" " class="form__select" id="cars" (change)="checkDuree()" name="contract-duration" formControlName="duree">
                                    <option value="" disabled selected>Durée du contrat</option>
                                    <option *ngFor="let d of listDureesFiltrer" [value]="d.duree">{{d.displayMe}}</option>
                                  </select>
                                    <label for="cars" class="form__label">Durée du contrat<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('duree').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <button class="btn  btn-large c-btnAjout" (click)="updateDevise('assure')">
                                    <i class="fa fa-pencil"></i> Valider
                                  </button>
                                <!-- <button type="button" class="btn btn-large  c-btnAjout" (click)="updateConjoint(conjoits)"
                                          *ngIf="updateConj"><i class="fa fa-pencil"></i> Modifier</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<!-- garanted -->
<ng-template #updateGarantie let-modal>

    <form [formGroup]="simulationVehiculeForm">
        <div class="container px-2 py-1 mx-auto">
            <div class="card card0 c-card">
                <div class="row beneficiary-section form-container ">
                    <div class="row no-margin">
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                            <div class="row no-margin">
                                <div class="col-10 p-0">
                                    <h6 class="text-left text-white c-cardTitle">Information véhicule</h6>
                                </div>
                                <div class="col-lg-2 text-right">
                                    <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                                      class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-3">
                            <div *ngIf="garanties?.length > 0 " class="row">
                                <div class="c-col-lg-6 col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngFor="let g of garanties">
                                    <div class="value" *ngIf="g.sousGarantie.length==0">
                                        <input class="inputCheck" [disabled]="g.disabled" [checked]="g.selected" (change)="addGarantie(g,g.sousGarantie)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}">
                                        <label for="{{g.code}}"> {{g.nom}} </label>
                                        <span class="garantDescription" *ngIf="g.code === 'gtcom'" placement="right" [ngbPopover]="g.description"> ?</span>
                                    </div>
                                    <div class="value" *ngIf="g.sousGarantie.length>0">
                                        <label for="" class="label-SG"> {{g.nom}}</label>
                                        <div *ngFor="let sg of g.sousGarantie" class="sgGarantie">
                                            <!-- <input class="inputCheck" [checked]="!g.selected" *ngIf="!g.selected" (click)="addGarantie(g, sg)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}"> -->
                                            <input class="inputCheck" [checked]="sg.selected" (change)="addGarantie(sg,sg,g.sousGarantie)" type="radio" [value]="sg" name="{{sg.code}}" id="{{sg.code}}">
                                            <label for="{{g.code}}"> {{sg.nom}}</label>
                                            <span class="garantDescription" *ngIf="g.code === 'gpt'" placement="right" [ngbPopover]="sg.description"> ?</span>
                                            <!-- <div class="descriptionOption"></div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="complete">
                                            <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-money form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" mask="separator" id="valeurNeuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (keyup)="onBlurEventneuf($event)" />
                                                <label for="valeurNeuf" class="form__label">Valeur à neuf<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="incendie || collision || vol || plafone">
                                            <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-credit-card-alt form__icon c-icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" mask="separator" id="valeurVenal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                                <label for="valeurVenal" class="form__label">Valeur vénale<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                            <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                                La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="noDataGaranties" *ngIf="garanties?.length == 0 ">
                                Chargemet en cours ...
                            </div>
                        </div>
                        <!--  -->
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-center">
                            <button class="btn btn-large c-btnAjout" (click)="updateDevise('assure')" [disabled]="updateRequest">
                                  <i class="fa fa-pencil" *ngIf="!updateRequest"></i>
                                  <i class="fa fa-spinner fa-pulse" *ngIf="updateRequest"></i>
                                  Valider
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>


<!-- Modal pour étre rappelé -->
<ng-template #devisAEnvoye let-modal>

    <form [formGroup]="etrerappeleForm">
        <div class="container px-2 py-1 mx-auto">
            <div class="card card0 c-card">
                <div class="row beneficiary-section form-container ">
                    <div class="row no-margin">
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                            <div class="row no-margin">
                                <div class="col-10 p-0">
                                    <!-- <h6 class="text-left text-white c-cardTitle">{{assureurAEnvoye?.displayMe}}</h6> -->
                                    <h6 class="text-left text-white c-cardTitle">PROXIM'ASSUR</h6>
                                </div>
                                <div class="col-lg-2 text-right">
                                    <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                                      class="fa fa-times text-white times-ass c-times-ass"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-3">
                            <div class="row">

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" placement="top" ngbPopover="Date de rappel" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-calendar form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="date" placeholder="" class="form__select" name="date" formControlName="date" name="date" #vehicule>
                                            <option value="" disabled selected>Jour de rappel</option>
                                            <option *ngFor="let rapel of dateRappel" [value]="rapel.value">{{rapel?.name}}</option>
                                          </select>
                                        <label for="cars" class="form__label">Date de rappel<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="etrerappeleForm.get('date').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>


                                <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                        <div class="form" placement="top" ngbPopover="Date de rappel"
                                          triggers="mouseenter:mouseleave">
                                          <i class="fa fa-clock-o form__icon c-icon"></i>
                                          <div class="form__separator">
                                          </div>
                                          <ngx-bootstrap-multiselect formControlName="heure" name="heure" class="form__select"  [texts]="myTexts" [settings]="mySettings" #heure
                                            [options]="heureRappel"></ngx-bootstrap-multiselect>
                                          <label for="cars" class="form__label">Heure de rappel<span class="text-red"> *</span></label>
                                        </div>
                                        <div class="error min-max text-red"
                                          *ngIf=" etrerappeleForm.get('heure').invalid">
                                          Ce champ est obligatoire
                                        </div>
                                      </div> -->
                            </div>
                            <div class="noDataGaranties" *ngIf="garanties.length == 0 ">
                                Chargemet en cours ...
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-center">
                            <button class="btn btn-large c-btnAjout" (click)="etreRappele()" [disabled]="updateRequest">
                                  <i class="fa fa-pencil" *ngIf="!updateRequest"></i>
                                  <i class="fa fa-spinner fa-pulse" *ngIf="updateRequest"></i>
                                  Valider
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>






<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->


<!-- </div> -->