import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-health-insurance',
  templateUrl: './health-insurance.component.html',
  styleUrls: ['./health-insurance.component.scss']
})
export class HealthInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
