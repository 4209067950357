<app-header-one></app-header-one>

<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Pricing</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Pricing</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="pricing-area ptb-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header bg1">
                        <h3>Basic</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>179<sub>/mo</sub>
                    </div>

                    <div class="book-now-btn">
                        <a routerLink="/" class="default-btn">Book Now <span></span></a>
                    </div>

                    <ul class="pricing-features-list">
                        <li><i class="fas fa-check"></i> Home Insurance</li>
                        <li><i class="fas fa-check"></i> Business Insurance</li>
                        <li><i class="fas fa-check"></i> Health Insurance</li>
                        <li><i class="fas fa-check"></i> Travel Insurance</li>
                        <li><i class="flaticon-cross-out"></i> Car Insurance</li>
                        <li><i class="flaticon-cross-out"></i> Life Insurance</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header bg2">
                        <h3>Professional</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>295<sub>/mo</sub>
                    </div>

                    <div class="book-now-btn">
                        <a routerLink="/" class="default-btn">Book Now <span></span></a>
                    </div>

                    <ul class="pricing-features-list">
                        <li><i class="fas fa-check"></i> Home Insurance</li>
                        <li><i class="fas fa-check"></i> Business Insurance</li>
                        <li><i class="fas fa-check"></i> Health Insurance</li>
                        <li><i class="fas fa-check"></i> Travel Insurance</li>
                        <li><i class="fas fa-check"></i> Car Insurance</li>
                        <li><i class="flaticon-cross-out"></i> Life Insurance</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-pricing-box">
                    <div class="pricing-header bg3">
                        <h3>Premium</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>139<sub>/mo</sub>
                    </div>

                    <div class="book-now-btn">
                        <a routerLink="/" class="default-btn">Book Now <span></span></a>
                    </div>

                    <ul class="pricing-features-list">
                        <li><i class="fas fa-check"></i> Home Insurance</li>
                        <li><i class="fas fa-check"></i> Business Insurance</li>
                        <li><i class="fas fa-check"></i> Health Insurance</li>
                        <li><i class="fas fa-check"></i> Travel Insurance</li>
                        <li><i class="fas fa-check"></i> Car Insurance</li>
                        <li><i class="fas fa-check"></i> Life Insurance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-area bg-black-color">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img5.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="quote-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="quote-content">
                    <h2>Get a free quote</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="image">
                        <img src="assets/img/img1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="tab quote-list-tab">
                    <ul class="tabs">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Business</a></li>
                        <li><a href="#">Health</a></li>
                        <li><a href="#">Car</a></li>
                        <li><a href="#">Life</a></li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>