import { Injectable } from "@angular/core";
// import * as data from '../';

@Injectable({
  providedIn: "root",
})
export class SelectPays {
  data = [
    {
      items: [
        {
          codenat: "ABW",
          name: "Aruba",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "AFG",
          name: "Afghanistan",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "AGO",
          name: "Angola",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "AIA",
          name: "Anguilla",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ALB",
          name: "Albanie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "AND",
          name: "Andorre",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ANT",
          name: "Antilles néerlandaises",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ARE",
          name: "Emirats Arabes Unis",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ARG",
          name: "Argentine",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ARM",
          name: "Arménie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ASM",
          name: "Samoa orientales",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ATA",
          name: "Antarctique",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ATF",
          name: "Territoires Antarctiques français",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ATG",
          name: "Antigua-et-Barbuda",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "AU",
          name: "Australia",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "AUT",
          name: "Autriche",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "AZE",
          name: "Azerbaïdjan",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BDI",
          name: "Burundi",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BEL",
          name: "Belgique",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BEN",
          name: "Bénin",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BFA",
          name: "Burkina Faso",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BGD",
          name: "Bangladesh",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BGR",
          name: "Bulgarie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BHR",
          name: "Bahreïn",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BHS",
          name: "Bahamas",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BIH",
          name: "Bosnie-Herzégovine",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BLR",
          name: "Biélorussie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BLZ",
          name: "Bélize",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BMU",
          name: "Bermudes",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BOL",
          name: "Bolivie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BRA",
          name: "Brésil",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BRB",
          name: "Barbade",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BRN",
          name: "Bruneï",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BTN",
          name: "Bhoutan",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BVT",
          name: "Bouvet (Île)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "BWA",
          name: "Botswana",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CAF",
          name: "République Centrafricaine",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CAN",
          name: "Canada",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CCK",
          name: "Cocos / Keeling (Îles)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CHL",
          name: "Chili",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CHN",
          name: "Chine",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CIV",
          name: "Côte D'Ivoire",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CMR",
          name: "Cameroun",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "COD",
          name: "République Démocratique du Congo",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "COG",
          name: "Congo",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "COK",
          name: "Cook (Îles)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "COL",
          name: "Colombie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "COM",
          name: "Comores",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CPV",
          name: "Cap Vert",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CRI",
          name: "Costa Rica",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CUB",
          name: "Cuba",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CXR",
          name: "Christmas (Île)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CYM",
          name: "Caïmans (Îles)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CYP",
          name: "Chypre",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "CZE",
          name: "République Tchèque",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "DEU",
          name: "Allemagne",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "DJI",
          name: "Djibouti",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "DMA",
          name: "Dominique",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "DNK",
          name: "Danemark",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "DOM",
          name: "République Dominicaine",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "DZA",
          name: "Algérie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ECU",
          name: "Equateur",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "EGY",
          name: "Egypte",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ERI",
          name: "Erythrée",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ESH",
          name: "Sahara Occidental",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "EST",
          name: "Estonie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ETH",
          name: "Ethiopie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "FI",
          name: "Fiji",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "FIN",
          name: "Finlande",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "FLK",
          name: "Falkland / Malouines (Îles)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "FRA",
          name: "France",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "FRO",
          name: "Féroé (Îles)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "FSM",
          name: "Micronésie (Etats fédérés de)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "FXX",
          name: "France métropolitaine",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GAB",
          name: "Gabon",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GEO",
          name: "Géorgie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GHA",
          name: "Ghana",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GIB",
          name: "Gibraltar",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GIN",
          name: "Guinée",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GLP",
          name: "Guadeloupe",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GMB",
          name: "Gambie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GNB",
          name: "Guinée-Bissau",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GNQ",
          name: "Guinée Equatoriale",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GRC",
          name: "Grèce",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GRD",
          name: "Grenade",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GRL",
          name: "Groënland",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GTM",
          name: "Guatemala",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GUF",
          name: "Guyane française",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GUM",
          name: "Guam",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "GUY",
          name: "Guyana",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "HKG",
          name: "Hong Kong",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "HMD",
          name: "Territoire des Îles Heard et McDonald",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "HND",
          name: "Honduras",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "HRV",
          name: "Croatie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "HTI",
          name: "Haïti",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "HUN",
          name: "Hongrie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "IDN",
          name: "Indonésie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "IND",
          name: "Inde",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "IOT",
          name: "Territoire britannique de l'Océan Indien",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "IRL",
          name: "Irlande",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "IRN",
          name: "Iran (République islamique d') ",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "IRQ",
          name: "Irak",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ISL",
          name: "Islande",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ISR",
          name: "Israël",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "IT",
          name: "Italy",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "JAM",
          name: "Jamaïque",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "JOR",
          name: "Jordanie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "JPN",
          name: "Japon",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "KAZ",
          name: "Kazakstan",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "KEN",
          name: "Kenya",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "KGZ",
          name: "Kyrgyzstan",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "KHM",
          name: "Cambodge",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "KIR",
          name: "Kiribati",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "KNA",
          name: "Saint Kitts et Nevis",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "KOR",
          name: "Corée (République démocratique du)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "KWT",
          name: "Koweït",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "LAO",
          name: "Laos (République populaire démocratique du)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "LBN",
          name: "Liban",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "LBR",
          name: "Libéria",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "LBY",
          name: "Libye (Jamahiriya Arabe Libyenne)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "LCA",
          name: "Sainte Lucie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "LIE",
          name: "Liechtenstein",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "LSO",
          name: "Lesotho",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "LTU",
          name: "Lithuanie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "LUX",
          name: "Luxembourg",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "LVA",
          name: "Lettonie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MAC",
          name: "Macau",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MAR",
          name: "Maroc",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MCO",
          name: "Monaco",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MDA",
          name: "Moldavie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MDG",
          name: "Madagascar",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MDV",
          name: "Maldives",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MEX",
          name: "Mexique",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MHL",
          name: "Marshall (Îles)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MKD",
          name: "Macédoine (ancienne République yougoslave de)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MLI",
          name: "Mali",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MLT",
          name: "Malte",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MMR",
          name: "Myanmar",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MNG",
          name: "Mongolie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MNP",
          name: "Mariannes (Îles)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MOZ",
          name: "Mozambique",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MRT",
          name: "Mauritanie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MSR",
          name: "Montserrat",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MTQ",
          name: "Martinique",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MUS",
          name: "Maurice",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MWI",
          name: "Malawi",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MYS",
          name: "Malaysie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MYT",
          name: "Mayotte",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NAM",
          name: "Namibie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NCL",
          name: "Nouvelle-Calédonie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NER",
          name: "Niger",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NFK",
          name: "Norfolk (Île)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NGA",
          name: "Nigéria",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NIC",
          name: "Nicaragua",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NIU",
          name: "Niue",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NL",
          name: "Netherlands",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NLD",
          name: "Pays-Bas",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NOR",
          name: "Norvège",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NPL",
          name: "Népal",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NRU",
          name: "Nauru",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "NZL",
          name: "Nouvelle-Zélande",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "OMN",
          name: "Oman",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PAK",
          name: "Pakistan",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PAN",
          name: "Panama",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PCN",
          name: "Pitcaïrn",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PER",
          name: "Pérou",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PHL",
          name: "Philippines",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PLW",
          name: "Palau",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PNG",
          name: "Papouasie Nouvelle-Guinée",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "POL",
          name: "Pologne",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PRI",
          name: "Porto Rico",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PRK",
          name: "Corée (République populaire démocratique du)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PRT",
          name: "Portugal",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PRY",
          name: "Paraguay",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "PYF",
          name: "Polynésie française",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "QAT",
          name: "Quatar",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "REU",
          name: "Réunion",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "ROM",
          name: "Romania",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "RSA",
          name: "Republic of South Africa",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "RUS",
          name: "Russie (Fédération de)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "RWA",
          name: "Rwanda",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "SAU",
          name: "Arabie Séoudite",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "SLV",
          name: "Salvador",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "SMR",
          name: "San Marin",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "STP",
          name: "Sao Tomé et Principe",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "SYR",
          name: "Syrie (République arabe syrienne)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TCA",
          name: "Turks et Caicos (Îles)",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TCD",
          name: "Tchad",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TGO",
          name: "Togo",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "THA",
          name: "Thaïlande",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TJK",
          name: "Tadjikistan",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TKL",
          name: "Tokelau",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TKM",
          name: "Turkménistan",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TMP",
          name: "Timor-Oriental",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TON",
          name: "Tonga",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TTO",
          name: "Trinidad et Tobago",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TUN",
          name: "Tunisie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TUR",
          name: "Turquie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TUV",
          name: "Tuvalu",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TWN",
          name: "Taïwan",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "TZA",
          name: "Tanzanie",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "UGA",
          name: "Ouganda",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "UK",
          name: "United Kingdom",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "UKR",
          name: "Ukraine",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "UMI",
          name: "Territoires non incorporés des États-Unis ",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "URY",
          name: "Uruguay",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "USA",
          name: "United States of America",
          version: "1",
          language_cd: "ENG",
        },
        {
          codenat: "MTQ ",
          name: "Martinique",
          version: "1",
          language_cd: "FRA",
        },
        {
          codenat: "UK ",
          name: "United Kingdom",
          version: "1",
          language_cd: "FRA",
        },
        {
          codenat: "SEN",
          name: "Sénégal",
          version: "1",
          language_cd: "FRA",
        },
      ],
    },
  ];
  recuperer_pays() {
    return this.data;
  }
}
