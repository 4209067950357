import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/shared/service/account.service';

@Component({
  selector: 'app-apg-success',
  templateUrl: './apg-success.component.html',
  styleUrls: ['./apg-success.component.scss']
})
export class ApgSuccessComponent implements OnInit {
  showMessage:boolean = false;
  user: any;
  datas: any;
  souscription;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private accountService:AccountService ) { 
    this.user = JSON.parse(localStorage.getItem('microFinance'));
    this.activatedRoute.queryParams.subscribe(params => {
      this.datas = params;
    });
    this.getAttestation(this.datas.id)
  }

  ngOnInit(): void {
  }

  getAttestation(idSouscription){
      this.accountService.getSouscription(idSouscription).subscribe((resp) => {
        if (resp['responseCode'] == 200) {
          this.souscription = resp['data'];
          //  if(resp['data']?.attestationAuto?.lien_pdf){
          //   setTimeout(() => {
          //     window.open(resp['data']?.attestationAuto?.lien_pdf, "_blank");
          //   }, 2500);
          // }
        } 
    }, error => {
        
    });
  }


  voirAttestation(){
    window.open(this.souscription?.attestationAuto?.lien_pdf, "_blank");
  }

}
