import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { AppRoutingModule } from './app-routing.module';
import {NgbDateParserFormatter, NgbModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';




import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HeaderOneComponent } from './components/layouts/header-one/header-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HeaderTwoComponent } from './components/layouts/header-two/header-two.component';
import { HeaderThreeComponent } from './components/layouts/header-three/header-three.component';
import { HeaderFourComponent } from './components/layouts/header-four/header-four.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeInsuranceComponent } from './components/pages/home-insurance/home-insurance.component';
import { BusinessInsuranceComponent } from './components/pages/business-insurance/business-insurance.component';
import { HealthInsuranceComponent } from './components/pages/health-insurance/health-insurance.component';
import { TravelInsuranceComponent } from './components/pages/travel-insurance/travel-insurance.component';
import { CarInsuranceComponent } from './components/pages/car-insurance/car-insurance.component';
import { LifeInsuranceComponent } from './components/pages/life-insurance/life-insurance.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { CaseStudyDetailsComponent } from './components/pages/case-study-details/case-study-details.component';
import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';
import { EventsComponent } from './components/pages/events/events.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IconPickerModule } from 'ngx-icon-picker';
import {CommonModule, DatePipe} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { NgxMaskModule, IConfig } from 'ngx-mask';
import {NgxFilesizeModule} from "ngx-filesize";
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
import { NgbDateFRParserFormatter } from './shared/service/date_format';
import {SubscriptionService} from "./shared/service/subscription.service";
import {UtilsService} from "./shared/service/utils.service";
import {SelectPays} from "./shared/service/select_pays.services";
import { ArchwizardModule } from 'angular-archwizard';
import { NgSelectModule } from '@ng-select/ng-select';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

import {HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './shared/service/interceptor';
import { MrhComponent } from './components/pages/home-mrh/home-mrh.component';
import { VoyagesComponent } from './components/pages/home-voyage/home-voyage.component';
import { SantesComponent } from './components/pages/home-sante/home-sante.component';
import { ViesComponent } from './components/pages/home-vie/home-vie.component';
import { SinistresComponent } from './components/pages/declaration-sinistre/declaration-sinistre.component';
import { MrhOfflineComponent } from './components/pages/home-mrh-offline/home-mrh-offline.component';
import { VoyagesOfflineComponent } from './components/pages/home-voyage-offline/home-voyage-offline.component';
import { SantesOfflineComponent } from './components/pages/home-sante-offline/home-sante-offline.component';
import { ResponsabiliteCivileComponent } from './components/pages/home-rce-offline/home-rce-offline.component';
import { TrcOfflineComponent } from './components/pages/home-trc-offline/home-trc-offline.component';
import { ApgSuccessComponent } from './components/pages/apg-success/apg-success.component';
import { CinetPayComponent } from './components/pages/cinetpay-success/cinetpay-success.component';
import { MrpOfflineComponent } from './components/pages/home-mrp-offline/home-mrp-offline.component';
import { SafePipe } from './safe.pipe';
import { IndividuelOfflineComponent } from './components/pages/home-individuel-offline/home-individuel-offline.component';


@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HomeOneComponent,
    FooterComponent,
    HeaderOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    HomeFourComponent,
    HeaderTwoComponent,
    HeaderThreeComponent,
    HeaderFourComponent,
    AboutComponent,
    TeamComponent,
    PricingComponent,
    TestimonialsComponent,
    FaqComponent,
    ErrorComponent,
    ContactComponent,
    HomeInsuranceComponent,
    BusinessInsuranceComponent,
    HealthInsuranceComponent,
    TravelInsuranceComponent,
    CarInsuranceComponent,
    LifeInsuranceComponent,
    CaseStudyComponent,
    CaseStudyDetailsComponent,
    EventsDetailsComponent,
    EventsComponent,
    ServicesOneComponent,
    ServicesTwoComponent,
    BlogGridComponent,
    BlogRightSidebarComponent,
    BlogDetailsComponent,
    MrhComponent,
    VoyagesComponent,
    SantesComponent,
    ViesComponent,
    SinistresComponent,
    MrhOfflineComponent,
    VoyagesOfflineComponent,
    SantesOfflineComponent,
    ResponsabiliteCivileComponent,
    TrcOfflineComponent,
    ApgSuccessComponent,
    CinetPayComponent,
    MrpOfflineComponent,
    SafePipe,
    IndividuelOfflineComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ScrollToModule,
    SharedModule,
    IconPickerModule,
    NgbPopoverModule,
    AngularSvgIconModule.forRoot(),
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      NgxMaskModule.forRoot(options),
      NgxFilesizeModule,
      NgbModule,
      ArchwizardModule,
      NgSelectModule



  ],
  providers: [
      SubscriptionService,DatePipe,UtilsService,SelectPays,
      {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},
      { provide: LOCALE_ID, useValue: 'fr-FR'},
      {
        provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true
      },
  ],
    exports:[NgbPopoverModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
