<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area ptb-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-home-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/home-insurance">Home Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/home-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/business-insurance">Business Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-health-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/health-insurance">Health Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/health-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-travel-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/travel-insurance">Travel Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/travel-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-car-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/car-insurance">Car Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/car-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-life-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/life-insurance">Life Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/life-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-area bg-black-color">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img5.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="quote-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="quote-content">
                    <h2>Get a free quote</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="image">
                        <img src="assets/img/img1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="tab quote-list-tab">
                    <ul class="tabs">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Business</a></li>
                        <li><a href="#">Health</a></li>
                        <li><a href="#">Car</a></li>
                        <li><a href="#">Life</a></li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option value="1">Property Used For</option>
                                        <option value="2">Home Insurance</option>
                                        <option value="0">Business Insurance</option>
                                        <option value="3">Health Insurance</option>
                                        <option value="4">Travel Insurance</option>
                                        <option value="5">Car Insurance</option>
                                        <option value="6">Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option value="1">Property Used For</option>
                                        <option value="2">Home Insurance</option>
                                        <option value="0">Business Insurance</option>
                                        <option value="3">Health Insurance</option>
                                        <option value="4">Travel Insurance</option>
                                        <option value="5">Car Insurance</option>
                                        <option value="6">Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option value="1">Property Used For</option>
                                        <option value="2">Home Insurance</option>
                                        <option value="0">Business Insurance</option>
                                        <option value="3">Health Insurance</option>
                                        <option value="4">Travel Insurance</option>
                                        <option value="5">Car Insurance</option>
                                        <option value="6">Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option value="1">Property Used For</option>
                                        <option value="2">Home Insurance</option>
                                        <option value="0">Business Insurance</option>
                                        <option value="3">Health Insurance</option>
                                        <option value="4">Travel Insurance</option>
                                        <option value="5">Car Insurance</option>
                                        <option value="6">Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option value="1">Property Used For</option>
                                        <option value="2">Home Insurance</option>
                                        <option value="0">Business Insurance</option>
                                        <option value="3">Health Insurance</option>
                                        <option value="4">Travel Insurance</option>
                                        <option value="5">Car Insurance</option>
                                        <option value="6">Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>