import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/shared/service/account.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import { ToWords } from 'to-words';
const toWords = new ToWords({
  localeCode: 'fr-FR',
});



@Component({
  selector: 'app-cinetpay-success',
  templateUrl: './cinetpay-success.component.html',
  styleUrls: ['./cinetpay-success.component.scss']
})
export class CinetPayComponent implements OnInit {
  showMessage:boolean = true;
  user: any;
  datas: any;
  souscription;
  payment;
  cinetPay = environment.cinetPay;
  message='';
  loader : boolean = false;
  @ViewChild('facture', { static: false }) facture: any;
  today = new Date();
  // abijanaiseLogo = '../../../../assets/img/logo.png';
  abijanaiseLogo = localStorage.getItem('LogoImage');

  lettreToNumber: any;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private accountService:AccountService,
    private modalService: NgbModal ) { 
    this.user = JSON.parse(localStorage.getItem('microFinance'));
    this.activatedRoute.queryParams.subscribe(params => {
      this.datas = params;
    });
    //  let data = {
    //   "transaction_id":this.datas.transaction_id,
    // }
    this.checkSouscription(this.datas.transaction_id);
  }

  ngOnInit(): void {
  }


  checkSouscription(data){
    this.showMessage = true;
    this.loader = true;
    this.message = 'Votre opération est en cours de traitement';
    this.accountService.checkSouscription(data).subscribe((resp) => {
      if (resp['responseCode']==200 && resp['data']?.attestationAuto?.lien_pdf) {
        this.souscription = resp['data'];
        let prixT = this.souscription.prix;
        this.lettreToNumber = toWords.convert(prixT);
        this.showMessage = false;
        this.loader = false;
        if(resp['data']?.attestationAuto?.lien_pdf){
            setTimeout(() => {
              window.open(resp['data']?.attestationAuto?.lien_pdf, "_blank");
            }, 1000);
        }
      } 

      else if(resp['responseCode'] == 200 && !resp['data']?.attestationAuto?.lien_pdf && JSON.parse(resp['data']?.attestationAuto?.reponseBrute)?.statut == "-35") {
        this.showMessage = true;
        this.loader = false;
        this.message = 'L\'immatriculation ' + resp['data']['immatriculation'] + ' a déja un contrat actif';
      }
      else if(resp['responseCode'] == 400 ) {
        this.showMessage = true;
        this.loader = false;
        this.message = resp['message'];
      }
      else {
        this.showMessage = true;
        this.loader = false;
        this.message = 'Une erreur est survenue';
      }
  }, error => {
    this.showMessage = false;
    this.loader = false;
  });
}


  voirAttestation(){
    window.open(this.souscription?.attestationAuto?.lien_pdf, "_blank");
  }

  showFacture(){
    this.modalService.open(this.facture, { ariaLabelledBy: 'modal-basic-title', size: <any>'xl' }).result.then((result) => {
      if (result == 'subscribe') {
      }
    }, (reason) => {
    });
  }


public captureScreen(): void {
  this.loader = true;
  var data = document.getElementById('facture');
  html2canvas(data, { useCORS: true,backgroundColor: 'white', removeContainer: true, scale:1, scrollX: 0, scrollY: 0 }).then(canvas => {
    var imgWidth = 210;
    var pageHeight = 470;
    const contentDataURL = canvas.toDataURL('application/pdf')
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    var doc = new jsPDF('p', 'mm', 'a4');
    var position = 0;
    doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    doc.save('facture.pdf');
    this.loader = false;
  });
}

}
