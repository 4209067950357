<app-header-one></app-header-one>

<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area ptb-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img1.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/home-insurance">Home Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/home-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img2.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/business-insurance">Business Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/business-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img3.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/health-insurance">Health Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/health-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img4.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/travel-insurance">Travel Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/travel-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img5.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/car-insurance">Car Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/car-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img6.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/life-insurance">Life Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/life-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area ptb-100 bg-fafafa">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Feedback</span>
            <h2>What Client Says About Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-item-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img1.jpg" alt="image">
                    <h3>David Luis</h3>
                    <span>Founder & CEO</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img2.jpg" alt="image">
                    <h3>Steven Smith</h3>
                    <span>Developer</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img3.jpg" alt="image">
                    <h3>Sarah Lucy</h3>
                    <span>Designer</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img4.jpg" alt="image">
                    <h3>James Anderson</h3>
                    <span>Manager</span>
                </div>
            </div>
        </div>
    </div>
</section>