import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  isLoading = new Subject<boolean>();

  constructor(private toastr: ToastrService) {   }

	showToastError(message: string, title: string){
		this.toastr.error(
			message,
			title,
		);
	}

	showToastSuccess(message: string, title: string){
		this.toastr.success(
			message,
			title,
		);
	}

	showToastInfo(message: string, title: string){
		this.toastr.info(
			message,
			title,
		);
	}

	showToastWarning(message: string, title: string){
		this.toastr.error(
			message,
			title,
		);
	}

  show() {
      this.isLoading.next(true);
  }
  hide() {
      this.isLoading.next(false);
  }

}
