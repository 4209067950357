import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class GenererPdfService {
  constructor(private httpClient: HttpClient) {}

  genererPdf(uuid) {
    return this.httpClient
      .get(environment.baseApiUrl + "souscriptions/" + uuid)
      .pipe((response) => response);
  }
}
