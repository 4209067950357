import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;
import {CONSTANTES} from './shared/model/constantes'
import { SubscriptionService } from './shared/service/subscription.service';
import { UtilsService } from './shared/service/utils.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;


    constantes = CONSTANTES;
    hasLogoConfigured = false;
    paramsGeneralList: any[];
      //start initialColor array;
    //   #ff7f2b
    // paramsGeneralList = [
    // {code: 'default-color', valeur: '#ff7f2b',typeValeur: 'Couleur'},
    // {code: 'primary-color', valeur: '#999999',typeValeur: 'Couleur'},
    // {code: 'secondary-color', valeur: '#000000',typeValeur: 'Couleur'},
    // {code: 'tertiary-color', valeur: '#CCCCCC',typeValeur: 'Couleur'}];
    //end initialColor array;

    constructor(private router: Router,private subscriptionService: SubscriptionService,private utilsService: UtilsService) {
        this.parametrageGeneral();
        // this.getParamsGen();
    }

    ngOnInit(){
        this.recallJsFuntions();
        this.checkTokenStatus();
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            $('.preloader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    parametrageGeneral(){
        this.subscriptionService.listParams().subscribe((resp) => {
            if (resp['responseCode'] == 200) {
                let data = resp['data'];
                this.paramsGeneralList = data?.filter(el => el.typeValeur == this.constantes.TYPE_VALEUR.COULEUR || el.typeValeur == this.constantes.TYPE_VALEUR.IMAGE || el.typeValeur == this.constantes.TYPE_VALEUR.TEXT); 
                this.hasLogoConfigured = !!data.find(el => el.code == this.constantes?.CODE_VALEUR?.LOGO_IMAGE);
                if (this.paramsGeneralList.length != 0) {
                  for (let item of this.paramsGeneralList) {
                    if (item.code == this.constantes?.CODE_VALEUR?.DEFAULT_COLOR) {
                     document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_DEFAULT, item.valeur);
                    } 
                    else if (item.code == this.constantes?.CODE_VALEUR?.PRIMARY_COLOR) {
                        document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_PRIMARY, item.valeur);
                    }
                     else if (item.code == this.constantes?.CODE_VALEUR?.SECONDARY_COLOR) {
                        document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_SECONDARY, item.valeur);
                    } 
                    else if (item.code == this.constantes?.CODE_VALEUR?.TERTIARY_COLOR) {
                        document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_TERTIARY, item.valeur);
                    }
                     else if (item.code == this.constantes?.CODE_VALEUR?.LOGO_IMAGE) {
                        document.documentElement.style.setProperty('--LogoImage', `url('${item.valeur}')`);
                        localStorage.setItem('LogoImage', item.valeur);
                    }
                    else if (item.code == this.constantes?.CODE_VALEUR?.LIEN_LINKEDIN) {
                        localStorage.setItem('lienLinkedin', item.valeur);
                    }
                    else if (item.code == this.constantes?.CODE_VALEUR?.LIEN_FACEBOOK) {
                        localStorage.setItem('lienFacebook', item.valeur);
                    }
                    else if (item.code == this.constantes?.CODE_VALEUR?.LOCALISATION) {
                        localStorage.setItem('localisation', item.valeur);
                    }
                    else if (item.code == this.constantes?.CODE_VALEUR?.ADRESSE) {
                        localStorage.setItem('adresse', item.valeur);
                    }
                    else if (item.code == this.constantes?.CODE_VALEUR?.TELEPHONE) {
                        localStorage.setItem('telephone', item.valeur);
                    }
                    else if (item.code == this.constantes?.CODE_VALEUR?.FAX) {
                        localStorage.setItem('fax', item.valeur);
                    }
                    else if (item.code == this.constantes?.CODE_VALEUR?.EMAIL) {
                        localStorage.setItem('email', item.valeur);
                    }
                  }
                    if (!this.hasLogoConfigured) {
                        document.documentElement.style.setProperty('--LogoImage', `url('assets/images/logo/logos.png')`);
                    }
                } 
                else {
                  document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_DEFAULT, this.constantes?.DEFAUL_CODE_COULEUR?.DEFAULT);
                  document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_PRIMARY, this.constantes?.DEFAUL_CODE_COULEUR?.PRIMARY);
                  document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_SECONDARY, this.constantes?.DEFAUL_CODE_COULEUR?.SECONDARY);
                  document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_TERTIARY, this.constantes?.DEFAUL_CODE_COULEUR?.TERTIARY);
                    if (!this.hasLogoConfigured) {
                        document.documentElement.style.setProperty('--LogoImage', `url('assets/images/logo/logos.png')`);
                    }
                }

            } else {

            }
        }, error => {

        });
  }


   getParamsGen(){
    if(this.paramsGeneralList.length != 0){
    for (let item of this.paramsGeneralList) {
      if (item.code == 'default-color') {
       document.documentElement.style.setProperty('--ColorDefault', item.valeur);
      } else if (item.code == 'primary-color') {
          document.documentElement.style.setProperty('--ColorPrimary', item.valeur);
      } else if (item.code == 'secondary-color') {
          document.documentElement.style.setProperty('--ColorSecondary', item.valeur);
      } else if (item.code == 'tertiary-color') {
          document.documentElement.style.setProperty('--ColorTertiary', item.valeur);
      } else if (item.code == 'logo-image') {
          document.documentElement.style.setProperty('--LogoImage', `url('${item.valeur}')`);
      } 
  }
  } 
  else {
    document.documentElement.style.setProperty('--ColorDefault', '#0090e5');
    document.documentElement.style.setProperty('--ColorPrimary', '#004d72 ');
    document.documentElement.style.setProperty('--ColorSecondary', '#0f172a ');
    document.documentElement.style.setProperty('--ColorTertiary', '#CCCCCC ');
  }
  }

  /**
   * Vérifie l'état du token JWT au lancement de l'application.
   * Si le token n'existe pas (premier lancement), ne pas afficher de message.
   * Si le token a expiré, exécuter l'action de déconnexion.
   */
  checkTokenStatus(): void {
    let val = JSON.parse(localStorage.getItem('microFinance'));
    let token = val ? val.id_token : null;
    if (!token) {
      // Pas de message d'erreur, c'est la première utilisation ou l'utilisateur n'a pas encore de token
    } else if (this.checkTokenExpiration(token)) {
      // Token expiré, déconnecter l'utilisateur
      this.setRefreshToken();
    } else {
       // Le token est valide
    }
  }

  /**
   * Vérifie si un token est expiré
   * @param token JWT
   * @returns boolean - true si expiré, false si valide
   */
  checkTokenExpiration(token: string): boolean {
    if (!token) {
      return true;  // Si aucun token n'est présent, considérer qu'il a expiré
    }
    const payload = this.decodeToken(token);
    if (!payload || !payload.exp) {
      return true;  // Si le token ne peut pas être décodé ou ne contient pas de champ `exp`, le considérer comme expiré
    }
    const expirationDate = payload.exp * 1000;  // `exp` est en secondes, on le convertit en millisecondes
    const currentDate = new Date().getTime();   // Récupérer l'heure actuelle en millisecondes
    return expirationDate < currentDate;  // Retourner `true` si le token a expiré, `false` sinon
  }

  /**
   * Décode un token JWT (en supposant qu'il soit au format valide)
   * @param token JWT
   * @returns Payload décodé du JWT
   */
  private decodeToken(token: string): any {
    try {
      const payload = token.split('.')[1];  // Le payload est la deuxième partie du token JWT
      return JSON.parse(atob(payload));     // Décoder en base64 puis en JSON
    } catch (error) {
      return null;  // Retourne `null` si une erreur survient lors du décodage
    }
  }

  /**
   * Action à effectuer lorsque le token a expiré (déconnexion)
   */
  setRefreshToken() {
    this.utilsService.showToastSuccess("Votre session a expiré", "Veuillez vous reconnecter");
    localStorage.removeItem('microFinance');  // Supprimer les informations de session du localStorage
    this.router.navigate(['accueil']);        // Rediriger vers la page d'accueil ou de connexion
  }

}