import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './components/loader/loader.component';
import { MenuComponent } from './components/navigation/menu/menu.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { HttpLoaderComponent } from './components/http-loader/http-loader.component';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';

@NgModule({
  declarations: [MenuComponent, TapToTopComponent, LoaderComponent, HttpLoaderComponent],
  exports: [
    MenuComponent,
    LoaderComponent,
    TapToTopComponent,
    SweetAlert2Module,
    HttpLoaderComponent,
    NgxBootstrapMultiselectModule,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SweetAlert2Module.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxBootstrapMultiselectModule,
  ]
})
export class SharedModule { }
