<!-- <app-header-four></app-header-four> -->
<app-header-one></app-header-one>


<div class="main-banner-section height_image item-bg16666">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1>Déclaration de sinistre</h1>
                    <p>Pour lancer la procédure d’indemnisation après un accident de voiture (ou tout autre type de sinistre), vous devez effectuer une déclaration à votre assureur.</p>

                    <div class="btn-box" *ngIf="!login">
                        <a (click)="souscrire()" class="default-btn btnO cursor">Faire une déclaration<span></span></a>
                        <!-- <div (click)="souscrire()" class="default-btn default-btn1">Faire une déclaration<span></span></div> -->
                    </div>
                    <div class="btn-box" *ngIf="login">
                        <a (click)="souscrire()" class="default-btn btnO cursor">Faire une déclaration<span></span></a>
                        <!-- <div (click)="souscrire()" class="default-btn default-btn1">Faire une déclaration<span></span></div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/car.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/umbrella-white.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/home.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/heart.png" alt="image"></div>
</div>

<div class="row desc container-row justify-content-center" id="contact" #contact>
    <div class="col-12 droite" *ngIf="login">
        <form [formGroup]="sinistreForm">
            <aw-wizard #wizard class="stepper-container pb-5" [navBarLocation]="'top'" [navBarLayout]="'large-empty'" [disableNavigationBar]="false">

                <!-- Informations pour les déclarants-->

                <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Informations déclarant">
                    <div class="form-container" formGroupName="declarant">
                        <div class="row">

                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Type d'assurance" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-hurricane form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="sinistre" placeholder=" " (change)="onChangeSinistre($event.target.value)" class="form__select" name="sinistre" formControlName="typeAssurance" name="product" #sinistre>
                              <option value="" disabled selected>Type d'assurance</option>
                              <option *ngFor="let v of listAssurance" [value]="v.id">{{v?.libelle}}</option>
                              
                          </select>
                                    <label for="cars" class="form__label">Type d'assurance<span class="text-red">
                                  *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('typeAssurance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Prénom du déclarant" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="prenomDeclarant" class="form__input" autocomplete="off" formControlName="prenomDeclarant" placeholder=" " />
                                    <label for="prenomDeclarant" class="form__label">Prénom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('prenomDeclarant').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Nom du déclarant" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="nomDeclarant" class="form__input" autocomplete="off" formControlName="nomDeclarant" placeholder=" " />
                                    <label for="nomDeclarant" class="form__label">Nom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('nomDeclarant').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Numéro de téléphone du déclarant" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-phone form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" prefix="+225 " [mask]="phoneMask" formControlName="telephoneDeclarant" id="telephoneDeclarant" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="telephoneDeclarant" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('telephoneDeclarant').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE' || this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                                <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-envelope form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="email" id="email" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('email').invalid">
                                    Ce champ est obligatoire ou invalide
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Adresse du déclarant" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="adresseDeclarant" class="form__input" autocomplete="off" formControlName="adresseDeclarant" placeholder=" " />
                                    <label for="adresseDeclarant" class="form__label">Adresse<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('adresseDeclarant').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE' || this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                                <div class="form" placement="top" ngbPopover="Profession du déclarant" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-briefcase form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="profession" class="form__input" autocomplete="off" formControlName="profession" placeholder=" " />
                                    <label for="profession" class="form__label">Profession<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('profession').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Date de naissance" triggers="mouseenter:mouseleave">
                                    <i class="fa-regular fa-calendar-days form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="dateNaissance" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today" (dateSelect)="onDateSelect($event,'declarant','dateNaissance')" ngbDatepicker #dateNaissance="ngbDatepicker"
                                        (click)="dateNaissance.toggle()" />
                                    <label for="dateNaissance" class="form__label">Date de naissance<span
                                class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('dateNaissance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div> -->

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Lieu de naissance" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-location-dot form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="lieuNaissance" class="form__input" autocomplete="off" formControlName="lieuNaissance" placeholder=" " />
                                    <label for="lieuNaissance" class="form__label">lieu de naissance<span class="text-red"></span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('lieuNaissance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-venus form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="sexe" placeholder=" " class="form__select" name="sexe" formControlName="sexe" name="product" #sexe>
                          <option value="" disabled selected>Sexe</option>
                          <option *ngFor="let item of listGenre" [value]="item.value">{{item?.name}}</option>
                          
                      </select>
                                    <label for="cars" class="form__label">Sexe<span class="text-red">
                              *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('sexe').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">
                                <div class="form" placement="top" ngbPopover="Type de piéce" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-puzzle-piece form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="sexe" placeholder=" " class="form__select" name="typeDePiece" formControlName="typeDePiece" name="product" #sexe>
                          <option value="" disabled selected>Type piéce</option>
                          <option *ngFor="let item of listTypePiece" [value]="item.value">{{item?.name}}</option>
                          
                      </select>
                                    <label for="cars" class="form__label">Type piéce<span class="text-red">
                              *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('typeDePiece').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">
                                <div class="form" placement="top" ngbPopover="Numéro de piéce" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-list-ol form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="numeroDePiece" class="form__input" autocomplete="off" formControlName="numeroDePiece" placeholder=" " />
                                    <label for="numeroDePiece" class="form__label">Numéro de piéce<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('numeroDePiece').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">
                                <div class="form" placement="top" ngbPopover="Date d'expiration" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="dateDexpiration" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" (dateSelect)="onDateSelect($event,'declarant','dateDexpiration')" ngbDatepicker #dateDexpiration="ngbDatepicker" (click)="dateDexpiration.toggle()"
                                    />
                                    <label for="dateDexpiration" class="form__label">Date d'expiration<span
                              class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('dateDexpiration').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>


                            <!-- Pour automobile -->

                            <!-- *ngIf="sinistreForm.get('declarant').get('typeAssurance')?.value === 'ASSURANCE AUTOMOBILE' || sinistreForm.get('declarant').get('typeAssurance')?.value === 'ASSURANCE SANTE'" -->
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Numéro Police" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="numeroPolice" class="form__input" autocomplete="off" formControlName="numeroPolice" placeholder=" " />
                                    <label for="numImmat" class="form__label">Numéro Police<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('numeroPolice').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="sinistreForm.get('declarant').get('typeAssurance')?.value === 'ASSURANCE AUTOMOBILE'">
                                <div class="form" placement="top" ngbPopover="Numéro Immatriculation" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="numImmat" class="form__input" autocomplete="off" formControlName="numImmat" placeholder=" " />
                                    <label for="numImmat" class="form__label">Numéro Immatriculation<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('numImmat').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="sinistreForm.get('declarant').get('typeAssurance')?.value === 'ASSURANCE AUTOMOBILE'">
                                <div class="form" placement="top" ngbPopover="Usage du Véhicule" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="utilvehicul" class="form__input" autocomplete="off" formControlName="utilvehicul" placeholder=" " />
                                    <label for="utilvehicul" class="form__label">Usage du Véhicule<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('utilvehicul').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="sinistreForm.get('declarant').get('typeAssurance')?.value === 'ASSURANCE AUTOMOBILE'">
                                <div class="form" placement="top" ngbPopover="Date derniére visite technique" triggers="mouseenter:mouseleave">
                                    <i class="fa-regular fa-calendar-days form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="dateVisitetechnik" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today" (dateSelect)="onDateSelect($event,'declarant','dateVisitetechnik')" ngbDatepicker #dateVisitetechnik="ngbDatepicker"
                                        (click)="dateVisitetechnik.toggle()" />
                                    <label for="dateVisitetechnik" class="form__label">Date derniére visite technique<span
                              class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('declarant').get('dateVisitetechnik').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <!-- <div class="col-5 c-btnWidth">
                    <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="choixinit()"><i
                      class="fa fa-chevron-left"></i></button>
                  </div> -->
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i
                        class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>


                <!-- Informations sinistre-->

                <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Informations sinistre" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">
                    <div class="form-container" formGroupName="sinistre">
                        <div class="row">

                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Type d'assurance" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-person form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="typeSinistre" placeholder=" " class="form__select" name="typeSinistre" formControlName="typeSinistre" name="product" #typeSinistre>
                              <option value="" disabled selected>Type de sinistre</option>
                              <option *ngFor="let v of listTypeSinistre" [value]="v.value">{{v?.name}}</option>
                              
                          </select>
                                    <label for="cars" class="form__label">Type de sinistre<span class="text-red">
                                  *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre').get('typeSinistre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Date d'expiration" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="dateSinistre" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" (dateSelect)="onDateSelect($event,'sinistre','dateSinistre')" ngbDatepicker #dateSinistre="ngbDatepicker" (click)="dateSinistre.toggle()"
                                    />
                                    <label for="dateSinistre" class="form__label">Date sinistre<span
                                  class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre').get('dateSinistre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Prénom du sinistre" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="textarea" min="0" id="details" class="form__input" autocomplete="off" formControlName="details" placeholder=" " />
                                    <label for="details" class="form__label">Détails</label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre').get('details').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div> -->
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" style="margin-top:-15px">
                                <div class="form" ngbPopover="Détail" triggers="mouseenter:mouseleave">
                                    <label for="textarea">Détail</label>
                                    <textarea placeholder="Détail" class="areaStyle" name="details" id="details" maxlength="65525" formControlName="details"></textarea>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre').get('details').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retourPrev()"><i
                      class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i
                        class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>

                <!-- Informations sinistre  Pour Automobile -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Informations sinistre" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                    <div class="form-container" formGroupName="sinistre2">
                        <div class="row">
                            <div class="row  justify-content-center">
                                <label for="details" class="form__label1">Un tiers est-il impliqué à l'accident?</label>
                                <div class="form-check form-check-inline col-2">
                                    <input class="form-check-input" formControlName="tiers" type="radio" name="tiers" id="inlineRadio1" value="oui">
                                    <label class="form-check-label tfes" for="inlineRadio1">OUI</label>
                                </div>
                                <div class="form-check form-check-inline col-2">
                                    <input class="form-check-input" formControlName="tiers" type="radio" name="tiers" id="inlineRadio2" value="non">
                                    <label class="form-check-label tfes" for="inlineRadio2">NON</label>
                                </div>

                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre2').get('tiers').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE' && this.sinistreForm?.get('sinistre2')?.value?.tiers === 'oui'">
                                <div class="form" placement="top" ngbPopover="Nom du propriétaire" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="nomPropr" class="form__input" autocomplete="off" formControlName="nomPropr" placeholder=" " />
                                    <label for="nomPropr" class="form__label">Nom du propriétaire</label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre2').get('nomPropr').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE' && this.sinistreForm?.get('sinistre2')?.value?.tiers === 'oui'">
                                <div class="form" placement="top" ngbPopover="Nom du conducteur" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="nomConduc" class="form__input" autocomplete="off" formControlName="nomConduc" placeholder=" " />
                                    <label for="nomConduc" class="form__label">Nom du conducteur</label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre2').get('nomConduc').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE' && this.sinistreForm?.get('sinistre2')?.value?.tiers === 'oui'">
                                <div class="form" placement="top" ngbPopover="Compagnie d'assurance" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-building form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="compagnie" formControlName="compagnie" placeholder=" " class="form__select" name="radioFruit">
                                  <option value="" disabled selected="selected">Compagnie d'assurance</option>
                                  <option *ngFor="let item of assureurList" value="{{item?.nom}}">{{item?.nom}}</option>
                              </select>
                                    <label for="compagnie" class="form__label">Compagnie d'assurance<span class="text-red">
                                      *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre2').get('compagnie').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>


                            <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE' && this.sinistreForm?.get('sinistre2')?.value?.tiers === 'oui'">
                        <div class="form" placement="top" ngbPopover="Marque et Modéle"
                          triggers="mouseenter:mouseleave">
                          <i class="fa-solid fa-car form__icon"></i>
                          <div class="form__separator">
                          </div>
                          <input type="text" min="0" id="markModel" class="form__input"
                          autocomplete="off" formControlName="markModel" placeholder=" "/>     
                          <label for="markModel" class="form__label">Marque et Modéle</label>
                        </div>
                        <div class="error min-max text-red"
                          *ngIf="invalid && sinistreForm.get('sinistre2').get('markModel').invalid">
                          Ce champ est obligatoire
                        </div>
                      </div> -->



                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE' && this.sinistreForm?.get('sinistre2')?.value?.tiers === 'oui'">

                                <div class="form" placement="top" ngbPopover="Marque du vehicule" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select placeholder=" " class="form__select" id="mySelect" formControlName="marque" (change)="marque()">
                              <option value="" disabled selected="selected"> Marque du véhicule</option>
                              <option *ngFor="let item of model" value="{{item?.make}}">{{item?.make}}
                              </option>
                          </select>
                                    <label for="mySelect" class="form__label">Marque du véhicule<span class="text-red">
                                  *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre2').get('marque').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE' && this.sinistreForm?.get('sinistre2')?.value?.tiers === 'oui') && shoModel && (!autreMarqueSelected || !autreModelSelected)">
                                <div class="form" placement="top" ngbPopover="Modèle de {{ sinistreForm.get('sinistre2').get('marque').value}}!" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select placeholder=" " class="form__select" id="cars" formControlName="modele" (change)="modele()">
                              <option value="" disabled selected="selected"> Modèle du véhicule</option>
                              <option *ngFor="let item of listModeles" [value]="item?.model">{{item?.model}}
                              </option>
                          </select>
                                    <label for="cars" class="form__label">Modèle du véhicule<span class="text-red">
                                  *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre2').get('modele').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(!shoModel && autreMarqueSelected)">
                                <div class="form" ngbPopover="Autre marque du vehicule" triggers="mouseenter:mouseleave">
                                    <i class="fas fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="autreMarque" id="marqueVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="marqueVehicule" class="form__label">Autre marque du véhicule<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre2').get('autreMarque').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="!shoModel && (autreMarqueSelected || autreModelSelected)">
                                <div class="form" ngbPopover="Autre modèle du vehicule" triggers="mouseenter:mouseleave">
                                    <i class="fas fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="autreModele" id="modeleVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="modeleVehicule" class="form__label">Autre modèle du véhicule<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre2').get('autreModele').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE' && this.sinistreForm?.get('sinistre2')?.value?.tiers === 'oui'">
                                <div class="form" placement="top" ngbPopover="Numéro Immatriculation" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="immatProp" class="form__input" autocomplete="off" formControlName="immatProp" placeholder=" " />
                                    <label for="immatProp" class="form__label">Numéro Immatriculation</label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre2').get('immatProp').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>


                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button awPreviousStep type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retourPrev()"><i
                          class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i
                            class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>


                <!--CAUSES ET CIRCONSTANCES DE L'ACCIDENT pour automobile -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Causes ET Circonstances de l'accident" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                    <!-- <div class="form-container" formGroupName="sinistre"> -->
                    <!-- <div formGroupName="sinistre" class="row c-rws" *ngIf="sinistreForm.get('sinistre').get('typeSinistre').value =='DECES'"> -->

                    <!-- </div> -->
                    <div class="form-container" formGroupName="sinistre1">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Date de l'accident" triggers="mouseenter:mouseleave">
                                    <i class="fa-regular fa-calendar-days form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="dateAccident" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" (dateSelect)="onDateSelect($event,'sinistre1','dateAccident')" ngbDatepicker #dateAccident="ngbDatepicker" (click)="dateAccident.toggle()"
                                    />
                                    <label for="dateAccident" class="form__label">Date de l'accident<span
                                  class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre1').get('dateAccident').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Lieu de l'accident" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-location-dot form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="lieuAccident" class="form__input" autocomplete="off" formControlName="lieuAccident" placeholder=" " />
                                    <label for="lieuAccident" class="form__label">Lieu de l'accident<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre1').get('lieuAccident').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <!-- <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Circonstance" triggers="mouseenter:mouseleave">
                                    <i class="fa-regular fa-car-on form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="textarea" min="0" id="circonstance" class="form__input" autocomplete="off" formControlName="circonstance" placeholder=" " />
                                    <label for="circonstance" class="form__label">Circonstance<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre1').get('circonstance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div> -->

                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c_bottom">
                                <div class="form" ngbPopover="Causes et Circonstances" triggers="mouseenter:mouseleave">
                                    <label for="textarea">Causes et Circonstances</label>
                                    <textarea placeholder="Causes et Circonstances" class="areaStyle" name="circonstance" id="circonstance" maxlength="65525" formControlName="circonstance"></textarea>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre1').get('circonstance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <br>

                            <div class="row col-lg-6 col-md-12 col-xs-12 col-sm-12 c-imgRow">
                                <div title="Cliquer pour charger le constat" class="image c-image bg-card" (click)="CONSTATP.click()">
                                    <!-- <div></div> -->
                                    <img src="{{CONSTAT}}" alt="" class="myImg">
                                    <div class="titleImage mt-5">Constat</div>
                                    <input [disabled]="loader" hidden #CONSTATP multiple class="use-avatar" (change)="selectOnFile($event,'CONSTAT','CONSTAT')" type="file" id="file" required accept="image/png, image/PNG, image/jpg, image/JPG, image/jpeg, image/JPEG , .pdf , .PDF , .doc , .DOC , .docx , .DOCX , .docm , .DOCM"
                                    />
                                </div>
                                <div class="chargement" *ngIf="loaderImage">
                                    <i class="text-center fa fa-spinner fa-pulse"></i> Chargement du fichier.....
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!loaderImage">
                            <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12 c-imgRow" *ngFor="let img of listImages;let i=index">
                                <div>
                                    <img src="../../../../assets/images/supprimer-btn.svg" alt="" (click)="DeleteConfirm(i,'constat')" class="sup">
                                </div>
                                <img src="{{img}}" alt="" class="myImg1">
                                <div class="titleImage1 mt-2">Constat</div>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button awPreviousStep type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retourPrev()"><i
                      class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i
                        class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>


                <!-- Pieces jointes  pour assurance vie et sante -->

                <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Piéces jointes" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">
                    <!-- <div class="form-container" formGroupName="sinistre"> -->
                    <div formGroupName="sinistre" class="row c-rws" *ngIf="sinistreForm.get('sinistre').get('typeSinistre').value =='DECES'">
                        <div class="col-lg-5 col-md-5 col-xs-12 col-sm-12 c-imgRow">
                            <div title="Cliquer pour charger le certificat de décés" class="image c-image bg-card" (click)="CERTIFICATDECESP.click()">
                                <img src="{{CERTIFICAT_DECES}}" alt="" class="myImg">
                                <div class="titleImage mt-5">Certificat de décés</div>
                                <input [disabled]="loader" hidden multiple #CERTIFICATDECESP class="use-avatar" (change)="selectOnFile($event,'CERTIFICAT_DECES','CERTIFICAT DE DECES')" type="file" id="file" required accept="image/png, image/PNG, image/jpg, image/JPG, image/jpeg, image/JPEG , .pdf , .PDF , .doc , .DOC , .docx , .DOCX , .docm , .DOCM"
                                />
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-5 col-xs-12 col-sm-12 c-imgRow">
                            <div title="Cliquer pour charger le Certificat de genre mort" class="image c-image bg-card" (click)="CERTIFICATGENREMORP.click()">
                                <img src="{{CERTIFICAT_GENRE_MORT}}" alt="" class="myImg">
                                <div class="titleImage mt-5">Certificat de genre mort</div>
                                <input [disabled]="loader" hidden multiple #CERTIFICATGENREMORP class="use-avatar" (change)="selectOnFile($event,'CERTIFICAT_GENRE_MORT','CERTIFICAT DE GENRE MORT')" type="file" id="file1" required accept="image/png, image/PNG, image/jpg, image/JPG, image/jpeg, image/JPEG , .pdf , .PDF , .doc , .DOC , .docx , .DOCX , .docm , .DOCM"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="rrow c-rws" *ngIf="sinistreForm.get('sinistre').get('typeSinistre').value =='INVALIDITE'">
                        <div class="col-lg-5 col-md-5 col-xs-12 col-sm-12 c-imgRow">
                            <div title="Cliquer pour charger le rapport médical" class="image c-image bg-card" (click)="RAPPORTMEDICALP.click()">
                                <img src="{{RAPPORT_MEDICAL}}" alt="" class="myImg">
                                <div class="titleImage mt-5">Rapport médical</div>
                                <input [disabled]="loader" hidden multiple #RAPPORTMEDICALP class="use-avatar" (change)="selectOnFile($event,'RAPPORT_MEDICAL','RAPPORT MEDICAL')" type="file" id="file2" required accept="image/png, image/PNG, image/jpg, image/JPG, image/jpeg, image/JPEG , .pdf , .PDF , .doc , .DOC , .docx , .DOCX , .docm , .DOCM"
                                />
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-5 col-xs-12 col-sm-12 c-imgRow">
                            <div title="Cliquer pour charger le Certificat de genre mort" class="image c-image bg-card" (click)="RAPPORTPOLICEOUGENDARMERIEP.click()">
                                <img src="{{RAPPORT_POLICE_OU_GENDARMERIE}}" alt="" class="myImg">
                                <div class="titleImage mt-5">Rapport police ou gendarmerie</div>
                                <input [disabled]="loader" hidden multiple #RAPPORTPOLICEOUGENDARMERIEP class="use-avatar" (change)="selectOnFile($event,'RAPPORT_POLICE_OU_GENDARMERIE','RAPPORT POLICE OU GENDARMERIE')" type="file" id="file3" required accept="image/png, image/PNG, image/jpg, image/JPG, image/jpeg, image/JPEG , .pdf , .PDF , .doc , .DOC , .docx , .DOCX , .docm , .DOCM"
                                />
                            </div>
                        </div>

                    </div>
                    <div class="chargement" *ngIf="loaderImage">
                        <i class="text-center fa fa-spinner fa-pulse"></i> Chargement du fichier.....
                    </div>

                    <div class="row" *ngIf="!loaderImage">
                        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-12 c-imgRow" *ngFor="let img of listImagesSante;let i=index">
                            <div>
                                <img src="../../../../assets/images/supprimer-btn.svg" alt="" (click)="DeleteConfirm(i,'sante')" class="sup">
                            </div>
                            <img src="{{img}}" alt="" class="myImg1">
                            <div class="titleImage1 mt-2">Photo</div>
                        </div>
                    </div>

                    <!-- </div> -->
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button awPreviousStep type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retourPrev()"><i
                      class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i
                        class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>


                <aw-wizard-step [navigationSymbol]="{ symbol: '6' }" stepTitle="Dommages matériels subis par l'assuré" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                    <div class="form-container" formGroupName="sinistre">
                        <div class="row">
                            <!-- Pour Automobile -->

                            <div class="row  justify-content-center" *ngIf="this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                                <label for="details" class="form__label1">Existe-il de dommages matériels reliés à l'accident ?</label>
                                <div class="form-check form-check-inline col-2">
                                    <input class="form-check-input" formControlName="domat" type="radio" name="domat" id="domat" value="oui">
                                    <label class="form-check-label tfes" for="inlineRadio1">OUI</label>
                                </div>
                                <div class="form-check form-check-inline col-2">
                                    <input class="form-check-input" formControlName="domat" type="radio" name="domat" id="domat" value="non">
                                    <label class="form-check-label tfes" for="inlineRadio2">NON</label>
                                </div>

                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre').get('tiers').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <!-- <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" *ngIf="this.sinistreForm?.get('sinistre')?.value?.domat === 'oui'">
                                <div class="form" placement="top" ngbPopover="Déclaration" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="declaration" class="form__input" autocomplete="off" formControlName="declaration" placeholder=" " />
                                    <label for="declaration" class="form__label">Déclaration</label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre').get('declaration').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div> -->

                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c_bottom" *ngIf="this.sinistreForm?.get('sinistre')?.value?.domat === 'oui'">
                                <div class="form" ngbPopover="Dommages matériels" triggers="mouseenter:mouseleave">
                                    <label for="textarea">Dommages matériels</label>
                                    <textarea placeholder="Dommages matériels" class="areaStyle" name="declaration" id="declaration" maxlength="65525" formControlName="declaration"></textarea>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre').get('declaration').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 c-imgRow" *ngIf="this.sinistreForm?.get('sinistre')?.value?.domat === 'oui'">
                                <div title="Cliquer pour charger le certificat de décés" class="image c-image bg-card" (click)="PHOTOSP.click()">
                                    <img src="{{PHOTOS}}" alt="" class="myImg">
                                    <div class="titleImage mt-5">Dommage</div>
                                    <input [disabled]="loader" multiple hidden #PHOTOSP class="use-avatar" (change)="selectOnFile($event,'PHOTOS','PHOTOS')" type="file" id="file" required accept="image/png, image/PNG, image/jpg, image/JPG, image/jpeg, image/JPEG , .pdf , .PDF , .doc , .DOC , .docx , .DOCX , .docm , .DOCM"
                                    />
                                </div>
                                <div class="chargement" *ngIf="loaderImage">
                                    <i class="text-center fa fa-spinner fa-pulse"></i> Chargement du fichier.....
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!loaderImage">
                            <div class="col-lg-3 col-md-3 col-xs-12 col-sm-12 c-imgRow" *ngFor="let img of listImagesPhotos;let i=index">
                                <div>
                                    <img src="../../../../assets/images/supprimer-btn.svg" alt="" (click)="DeleteConfirm(i,'photo')" class="sup">
                                </div>
                                <img src="{{img}}" alt="" class="myImg1">
                                <div class="titleImage1 mt-2">Photos</div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button awPreviousStep type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retourPrev()"><i
                      class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i
                        class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>

                <aw-wizard-step [navigationSymbol]="{ symbol: '7' }" stepTitle="Dommages corporels subis par l'assuré" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                    <div class="form-container" formGroupName="sinistre">
                        <div class="row">
                            <!-- Pour Automobile -->

                            <div class="row  justify-content-center" *ngIf="this.sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                                <label for="details" class="form__label1">Existe-il de dommages corporels reliés à l'accident ?</label>
                                <div class="form-check form-check-inline col-2">
                                    <input class="form-check-input" formControlName="domcorporel" type="radio" name="domcorporel" id="domcorporel" value="oui">
                                    <label class="form-check-label tfes" for="inlineRadio1">OUI</label>
                                </div>
                                <div class="form-check form-check-inline col-2">
                                    <input class="form-check-input" formControlName="domcorporel" type="radio" name="domcorporel" id="domcorporel" value="non">
                                    <label class="form-check-label tfes" for="inlineRadio2">NON</label>
                                </div>

                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre').get('domcorporel').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <!-- <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" *ngIf="this.sinistreForm?.get('sinistre')?.value?.domcorporel === 'oui'">
                                <div class="form" placement="top" ngbPopover="Déclaration" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" min="0" id="declarationCorporel" class="form__input" autocomplete="off" formControlName="declarationCorporel" placeholder=" " />
                                    <label for="declarationCorporel" class="form__label">Déclaration</label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre').get('declarationCorporel').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div> -->

                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" *ngIf="this.sinistreForm?.get('sinistre')?.value?.domcorporel === 'oui'">
                                <div class="form" ngbPopover="Dommages corporels" triggers="mouseenter:mouseleave">
                                    <label for="textarea">Dommages corporels</label>
                                    <textarea placeholder="Dommages corporels" class="areaStyle" name="declarationCorporel" id="declarationCorporel" maxlength="65525" formControlName="declarationCorporel"></textarea>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && sinistreForm.get('sinistre').get('declarationCorporel').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button awPreviousStep type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retourPrev()"><i
                      class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="nextstep()">Suivant <i
                        class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>

                <aw-wizard-step [navigationSymbol]="{ symbol: '8' }" stepTitle="Récapitulatif">

                    <div class="recap-form-container">
                        <div class="mesImage">
                            <div class="row imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                <img src="../../../../../assets/images/PROXIM-ASSUR-logo_blanc.png" alt="">
                            </div>
                            <div class="imgLogoR">
                                <img [src]="logoImage" alt="">
                            </div>
                        </div>

                        <div class="info-devis">
                            <div class="proposition">
                                <div class="pro-title"> Déclaration de sinistre</div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        Informations Déclarant
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="openModal(modalAssure)"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('typeAssurance')?.value !==''">Type Assurance</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('typeAssurance')?.value !==''">
                                                {{sinistreForm.get('declarant').get('typeAssurance').value}}
                                            </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('prenomDeclarant')?.value !==''">Prénom</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('prenomDeclarant')?.value !==''">
                                                {{sinistreForm.get('declarant').get('prenomDeclarant').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('nomDeclarant')?.value !==''">Nom</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('nomDeclarant')?.value !==''">
                                                {{sinistreForm.get('declarant').get('nomDeclarant').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('adresseDeclarant')?.value !==''">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('adresseDeclarant')?.value !==''">
                                                {{sinistreForm.get('declarant').get('adresseDeclarant').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('telephoneDeclarant')?.value !==''">Téléphone</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('telephoneDeclarant')?.value !==''">
                                                {{sinistreForm.get('declarant').get('telephoneDeclarant').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('email')?.value !==''">Email</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('email')?.value !==''">
                                                {{sinistreForm.get('declarant').get('email').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('dateNaissance')?.value !==''">Date de Naissance</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('dateNaissance')?.value !==''">
                                                {{sinistreForm.get('declarant').get('dateNaissance').value | date: 'dd-MM-yyyy'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('lieuNaissance')?.value !==''">Lieu de Naissance</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('lieuNaissance')?.value !==''">
                                                {{sinistreForm.get('declarant').get('lieuNaissance').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('sexe')?.value !==''">Sexe</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('sexe')?.value !==''">
                                                {{sinistreForm.get('declarant').get('sexe').value}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">
                                        <span>Informations sinistre</span>
                                    </div>
                                    <div class="title-div" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                                        <span>Informations Véhicule</span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <!-- pour Automobile -->
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('numImmat')?.value !==''">Numéro Immatriculation</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('numImmat')?.value !==''">
                                                {{sinistreForm.get('declarant').get('numImmat').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('utilvehicul')?.value !==''">Usage du Véhicule</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('utilvehicul')?.value !==''">
                                                {{sinistreForm.get('declarant').get('utilvehicul').value}}
                                            </div>
                                            <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('dateVisitetechnik')?.value !==''">Date derniére visite technique</div>
                                            <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('dateVisitetechnik')?.value !==''">
                                                {{sinistreForm.get('declarant').get('dateVisitetechnik').value | date: 'dd-MM-yyyy'}}
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">Type Sinistre</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">
                                                {{sinistreForm.get('sinistre').get('typeSinistre').value}}
                                            </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">Date Sinistre</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">
                                                {{sinistreForm.get('sinistre').get('dateSinistre').value | date: 'dd-MM-yyyy'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">Détails</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">
                                                {{sinistreForm.get('sinistre').get('details').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('typeDePiece')?.value !==''">Type de Piéce</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('typeDePiece')?.value !==''">
                                                {{sinistreForm.get('declarant').get('typeDePiece').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('numeroDePiece')?.value !==''">Numéro de Piéce</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('numeroDePiece')?.value !==''">
                                                {{sinistreForm.get('declarant').get('numeroDePiece').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('dateDexpiration')?.value !==''">Date d'expiration</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('dateDexpiration')?.value !==''">
                                                {{sinistreForm.get('declarant').get('dateDexpiration').value | date: 'dd-MM-yyyy'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('declarant')?.get('profession')?.value !==''">Profession</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('declarant')?.get('profession')?.value !==''">
                                                {{sinistreForm.get('declarant').get('profession').value}}
                                            </div>
                                            <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre2')?.get('tiers')?.value !==''">Un tiers est-il impliqué à l'accident ?</div>
                                            <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre2')?.get('tiers')?.value !==''">
                                                {{sinistreForm.get('sinistre2').get('tiers').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre2')?.get('nomPropr')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">Nom du propriétaire</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre2')?.get('nomPropr')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">
                                                {{sinistreForm.get('sinistre2').get('nomPropr').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre2')?.get('nomConduc')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">Nom du conducteur</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre2')?.get('nomConduc')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">
                                                {{sinistreForm.get('sinistre2').get('nomConduc').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre2')?.get('compagnie')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">Compagnie d'assurance</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre2')?.get('compagnie')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">
                                                {{sinistreForm.get('sinistre2').get('compagnie').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre2')?.get('marque')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">Marque</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre2')?.get('marque')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">
                                                {{sinistreForm.get('sinistre2').get('marque').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre2')?.get('modele')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">Modéle</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre2')?.get('modele')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">
                                                {{sinistreForm.get('sinistre2').get('modele').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre2')?.get('immatProp')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">Numéro Immatriculation</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre2')?.get('immatProp')?.value !=='' && sinistreForm?.get('sinistre2')?.get('tiers')?.value ==='oui'">
                                                {{sinistreForm.get('sinistre2').get('immatProp').value}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="proposition2 mt-3" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">
                                <div class="pro-title"> Pièces jointes</div>
                            </div>
                            <!-- <div class="c-piece" *ngIf="(sinistreForm?.get('sinistre')?.value?.typeSinistre === 'DECES') && (sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE')">
                                <div (click)="showFilePDF(CERTIFICAT_DECES,'CERTIFICAT DE DECES')" class="col-md-5 col-lg-5 col-sm-12 col-xs-12 image c-image bg-card shadow rounded-2xl overflow-hidden">
                                    <img src="{{CERTIFICAT_DECES}}" alt="" class="myImg">
                                    <div class="titleImage mt-5">Certificat de décés</div>
                                </div>

                                <div (click)="showFilePDF(CERTIFICAT_GENRE_MORT,'CERTIFICAT DE GENRE MORT')" class="col-md-5 col-lg-5 col-sm-12 col-xs-12 image c-image bg-card shadow rounded-2xl overflow-hidden">
                                    <img [src]="CERTIFICAT_GENRE_MORT" alt="" class="myImg">
                                    <div class="titleImage mt-5">Certificat de genre mort</div>
                                </div>
                            </div> -->

                            <!-- <div class="c-piece" *ngIf="(sinistreForm?.get('sinistre')?.value?.typeSinistre === 'INVALIDITE') && (sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE')">
                                <div (click)="showFilePDF(RAPPORT_MEDICAL,'RAPPORT MEDICAL')" class="image c-image bg-card shadow rounded-2xl overflow-hidden">
                                    <img src="{{RAPPORT_MEDICAL}}" alt="" class="myImg">
                                    <div class="titleImage mt-5">Rapport médical</div>
                                </div>

                                <div (click)="showFilePDF(RAPPORT_POLICE_OU_GENDARMERIE,'RAPPORT POLICE OU GENDARMERIE')" class="image c-image bg-card shadow rounded-2xl overflow-hidden">
                                    <img [src]="RAPPORT_POLICE_OU_GENDARMERIE" alt="" class="myImg">
                                    <div class="titleImage mt-5">Rapport police ou gendarmerie</div>
                                </div>
                            </div> -->

                            <div class="c-piece" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE SANTE' || sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE VIE'">
                                <div *ngFor="let img of listImagesSante;let i=index" class="col-md-5 col-lg-5 col-sm-12 col-xs-12 image c-image bg-card shadow rounded-2xl overflow-hidden">
                                    <img src="{{img}}" alt="" class="myImg">
                                    <div class="titleImage mt-5">Photo</div>
                                </div>
                            </div>



                            <!--     partie automobile    -->


                            <!-- <div class="proposition">
                          <div class="pro-title"> Déclaration de sinistre</div>
                        </div> -->
                            <div class="row" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        Causes et circonstances de l'accident
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="openModal(modalAssure)"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre1')?.get('dateAccident')?.value !==''">Date de l'accident</div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre1')?.get('dateAccident')?.value !==''">
                                                {{sinistreForm.get('sinistre1').get('dateAccident').value | date: 'dd-MM-yyyy'}}
                                            </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre1')?.get('lieuAccident')?.value !==''">Lieu de l'accident</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre1')?.get('lieuAccident')?.value !==''">
                                                {{sinistreForm.get('sinistre1').get('lieuAccident').value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre1')?.get('circonstance')?.value !==''">Circonstance</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre1')?.get('circonstance')?.value !==''">
                                                {{sinistreForm.get('sinistre1').get('circonstance').value}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Dommages matériels subis par  l'assuré</span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">

                                            <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre')?.get('domat')?.value !==''">Existe-il de dommages matériels reliés à l'accident ?</div>
                                            <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre')?.get('domat')?.value !==''">
                                                {{sinistreForm.get('sinistre').get('domat').value}}
                                            </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="this.sinistreForm?.get('sinistre')?.get('declaration')?.value !=='' && sinistreForm?.get('sinistre')?.get('domat')?.value ==='oui'">Déclaration</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="this.sinistreForm?.get('sinistre')?.get('declaration')?.value !=='' && sinistreForm?.get('sinistre')?.get('domat')?.value ==='oui'">
                                                {{sinistreForm.get('sinistre').get('declaration').value}}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Dommages corporel subis par  l'assuré</span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">

                                            <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre')?.get('domcorporel')?.value !==''">Existe-il de dommages corporels reliés à l'accident ?</div>
                                            <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre')?.get('domcorporel')?.value !==''">
                                                {{sinistreForm.get('sinistre').get('domcorporel').value}}
                                            </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="sinistreForm?.get('sinistre')?.get('declarationCorporel')?.value !=='' && sinistreForm?.get('sinistre')?.get('domcorporel')?.value ==='oui'">Déclaration</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="sinistreForm?.get('sinistre')?.get('declarationCorporel')?.value !=='' && sinistreForm?.get('sinistre')?.get('domcorporel')?.value ==='oui'">
                                                {{sinistreForm.get('sinistre').get('declarationCorporel').value}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="proposition2 mt-3" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE'">
                                <div class="pro-title">Constat et Photos</div>
                            </div>
                            <div class="c-piece" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE' && listImages.length!=0">
                                <!-- (click)="showFilePDF(CONSTAT,'CONSTAT')" -->
                                <div *ngFor="let img of listImages;let i=index" class="col-md-5 col-lg-5 col-sm-12 col-xs-12 image c-image bg-card shadow rounded-2xl overflow-hidden">
                                    <img src="{{img}}" alt="" class="myImg">
                                    <div class="titleImage mt-5">Constat</div>
                                </div>
                            </div>
                            <div class="c-piece" *ngIf="sinistreForm?.get('declarant')?.value?.typeAssurance === 'ASSURANCE AUTOMOBILE' && listImagesPhotos.length!=0">
                                <!-- (click)="showFilePDF(PHOTOS,'PHOTOS')" -->
                                <div *ngFor="let imgP of listImagesPhotos;let i=index" class="col-md-5 col-lg-5 col-sm-12 col-xs-12 image c-image bg-card shadow rounded-2xl overflow-hidden">
                                    <img src="{{imgP}}" alt="" class="myImg">
                                    <div class="titleImage mt-5">PHOTOS</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button awPreviousStep type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retourPrev()"><i
                      class="fa fa-chevron-left"></i>Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth" (click)="declaration()">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [swal]="confirmSubscription2" [disabled]="sendingRequest"><i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer <i
                        class="fa fa-angle-double-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>
            </aw-wizard>
        </form>
    </div>
</div>




<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/images/assets-images/entete86.jpg" alt="image">

                    <div class="text">
                        Plus de <span>10 ans</span> d'expérience
                    </div>
                    <div class="shape">
                        <img src="assets/img/dot.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2 class="c-sinistre">Sinistre</h2>
                    <p>un sinistre est un malheur ou une catastrophe. En assurance, il s'agit d'un événement couvert au contrat qui se réalise et va donner lieu à une indemnisation de la part de l'assureur. Par exemple, un dégât des eaux sur une toiture
                        ou un incendie dans une dépendance.</p>
                    <!-- <a routerLink="/about" class="default-btn">Plus<span></span></a> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="find-agent-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="groupe">L’ABIDJANAISE D’ASSURANCES</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="find-agent-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon icon_height">
                                    <i class="flaticon-home-insurance"></i>
                                </div>

                                <h3 class="titre_card">Assurances</h3>
                                <p>L’ABIDJANAISE D’ASSURANCES affiche un ancrage dans le paysage des assurances en Côte d’Ivoire grâce à sa qualité de service et sa politique de challenger confirmée, lui conférant une position d’acteur de référence du marché
                                    des Assurances.</p>
                            </div>
                        </div>

                        <!-- <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon icon_height">
                                    <i class="flaticon-briefcase"></i>
                                </div>

                                <h3 class="titre_card">Banque & Finance</h3>
                                <p>Les banques de l’ABIDJANAISE D’ASSURANCES sont des banques commerciales de proximité. Elles offrent sur leurs marchés respectifs des solutions adaptées au grand public.</p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="find-agent-contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="flaticon-call"></i>
                            </div>

                            <span class="contact_titre">Parlez à notre agent</span>
                            <a href="tel:27 22 23 68 83" class="contact_value">{{telephone}}</a>
                        </li>

                        <li>
                            <cite>Ou</cite>
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>

                            <span class="contact_titre">Envoyez-nous un e-mail</span>
                            <a href="mailto:infos@abidjanaise-assurances.net" class="contact_value">{{email}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section class="achievements-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="achievements-content">
                    <div class="title">
                        <span class="sub-title">L’ABIDJANAISE D’ASSURANCES</span>
                        <h2>Nos réalisations</h2>
                        <p>L’ABIDJANAISE D’ASSURANCES dispose d’un important réseau Assurances en Afrique de l’Ouest et Centrale, et de cinq (5) Banques en Afrique de l’Ouest. Nous affirmons notre ambition de devenir le leader de la bancassurance dans la
                            zone CIMA par le développement de la banque de détail et par la mise en place d’un suivi de l’impact environnemental et social des projets financés et/ou assurés. Aujourd’hui, l’ABIDJANAISE D’ASSURANCES a pour coeur de métier
                            l’assurance et la banque, mais exerce également dans les secteurs de la finance, l’immobilier et des technologies.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-flag"></i>
                                <h3><span class="odometer" data-count="65">00</span></h3>
                                <p>Pays</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-group"></i>
                                <h3><span class="odometer" data-count="107">00</span> <span class="sign-icon">m</span></h3>
                                <p>Clients</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-medal"></i>
                                <h3><span class="odometer" data-count="150">00</span></h3>
                                <p>Récompenses gagnantes</p>
                            </div>
                        </div>
                    </div>

                    <div class="bg-dot"><img src="assets/img/bg-dot.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="achievements-image-slides owl-carousel owl-theme">
                    <div class="single-achievements-image bg1mrh">
                        <img src="assets/img/achievements-img1.jpg" alt="image">
                    </div>

                    <div class="single-achievements-image bg2mrh">
                        <img src="assets/img/achievements-img2.jpg" alt="image">
                    </div>

                    <div class="single-achievements-image bg3mrh">
                        <img src="assets/img/achievements-img3.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- Modal pour la page login -->
<ng-template #contentResultlogin let-modal>
    <div class="login">
        <form [formGroup]="loginform">
            <div class="container px-4 py-5 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3">
                                        <h3 class="monCompte"> Mon compte!</h3>
                                        <p class="compteText"> Connectez vous à votre compte SVP.</p>
                                    </div>
                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="nomUser" class="form__input" autocomplete="off" placeholder=" " formControlName="username" />
                                        <label for="nomUser" class="form__label">Nom d'utilisateur<span class="text-red"></span></label>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                                        Le nom d'utilisateur est requis
                                    </div>

                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-lock form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password" />
                                        <label for="psw" class="form__label">Mot de passe<span class="text-red"></span></label>
                                        <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>
                                        <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                                        Le mot de passe est requis
                                    </div>
                                    <div class="forgetMdp">
                                        <div class="resterConnecter">
                                            <input type="checkbox">
                                            <span> Restez connecter</span>
                                        </div>
                                        <div class="updateMdp" (click)="lanceforgot()">Mot de passe oublié?</div>
                                    </div>
                                    <div class="text-danger text-center mt-1" *ngIf="userInconnu!=''"> {{userInconnu}}</div>
                                    <div class="text-center"> <button class="seConnecter" (click)="connecter()" [disabled]="loaderVisible">Connectez-vous</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Connexion en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-3 margeTop">
                                <p href="#" class="sm-text mx-auto mb-3">Vous n'avez pas de compte?<button class="btn btn-white ml-2" (click)="inscrire()" [disabled]="loaderVisible">Créez-en un!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
</ng-template>

<ng-template #contentResultsign let-modal>
    <div class="modal-body login">
        <form [formGroup]="inscriptionForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" src="../../../../../assets/img/logo.png"> </div> -->
                                    <h6 class="msg-info text-center">Veuillez remplir ces champs svp!</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Nom</label>
                                        <input type="text" id="nom" placeholder="" class="form-control" formControlName="nom">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('nom')?.errors?.required && (inscriptionForm?.get('nom')?.touched || inscriptionForm.get('nom')?.dirty)">
                                            Le champs nom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Prénom
                                        </label> <input type="text" id="prenom" placeholder="" class="form-control" formControlName="prenom">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('prenom')?.errors?.required && (inscriptionForm.get('prenom')?.touched || inscriptionForm.get('prenom')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse
                                            mail</label> <input type="email" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.required && (inscriptionForm.get('email')?.touched || inscriptionForm.get('email')?.dirty)">
                                            Le champs email est requis</div>
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.pattern">Le champs email est incorrect
                                        </div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Numéro
                                            téléphone</label> <input type="text" id="telephone" placeholder="" class="form-control" formControlName="telephone" prefix="+225 " [mask]="phoneMask">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('telephone')?.errors?.required && (inscriptionForm.get('telephone')?.touched || inscriptionForm.get('telephone')?.dirty)">
                                            Le champs téléphone est requis</div>
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="inscrireutil()" [disabled]="loaderVisible">S'inscrire</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Inscription en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-1">
                                <p href="#" class="sm-text mx-auto mb-3">Vous avez déjà un compte?<button class="btn btn-white ml-2" (click)="souscrire()" [disabled]="loaderVisible">Connectez-vous!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<!-- Modal pour afficher les images et pdf -->
<ng-template #devisAEnvoye let-modal>

    <div class="card card0 c-card">
        <!-- <div class="row beneficiary-section form-container"> -->

        <div class="row no-margin c-headModal">
            <div class="col-10 p-0">
                <h6 class="text-left text-white c-cardTitle">{{titleFile}}</h6>
            </div>
            <div class="col-lg-2 text-right">
                <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
              class="fa fa-times text-white times-ass c-times-ass"></i></a>
            </div>
        </div>

        <div class="flex flex-col flex-auto min-w-10-0 p-2 md:p-4">
            <iframe width="100%" height="500px" [src]="file"></iframe>
        </div>
        <!-- </div> -->
    </div>
</ng-template>


<ng-template #contentResultforgot let-modal>
    <div class="modal-body login">
        <form [formGroup]="forgotForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" [src]="logoImage"> </div> -->
                                    <h6 class="msg-info text-center">Mot de passe oublié ?</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse mail</label> <input type="text" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="forgot()" [disabled]="loaderVisible">Réinitialiser compte</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Réinitialisation en cours
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<ng-template #contentResultResetPassword let-modal>
    <div class="modal-body login">
        <form [formGroup]="resetPasswordForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" src="../../../../../assets/img/logo.png"> </div> -->
                                    <h6 class="msg-info text-center">Veuillez remplir ces champs svp!</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Code OTP</label>
                                        <input type="text" id="token" placeholder="" class="form-control" formControlName="token">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('token')?.errors?.required && (resetPasswordForm?.get('token')?.touched || resetPasswordForm.get('token')?.dirty)">
                                            Le champs nom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Mot de passe
                                        </label> <input type="text" id="newPassword" placeholder="" class="form-control" formControlName="newPassword">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('newPassword')?.errors?.required && (resetPasswordForm.get('newPassword')?.touched || resetPasswordForm.get('newPassword')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>

                                    <div class="form-group"> <label class="form-control-label text-muted">Confirmation de mot de passe
                                    </label> <input type="text" id="confirmPassword" placeholder="" class="form-control" formControlName="confirmPassword">
                                        <div class="text-red ml-3" *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.required && (resetPasswordForm.get('confirmPassword')?.touched || resetPasswordForm.get('confirmPassword')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>

                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="resetPassword()" [disabled]="loaderVisible">Changez votre mot de passe</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Changez de mot de passe en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>








<swal #confirmSubscription2 title="Confirmation" [reverseButtons]="true" text="Voulez-vous vraiment proceder à la souscription ?" icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'" [cancelButtonText]="'NON, ANNULER'"
    (confirm)="saveData()">
</swal>