<app-header-one></app-header-one>

<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Case Study</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Case Study</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="case-study-area ptb-100 pb-70 bg-fafafa">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-case-study-box">
                    <div class="case-study-image bg1"></div>
                    <a routerLink="/case-study-details" class="case-study-link">
                        <div class="case-study-img-hover bg1"></div>
                    </a>
                    <div class="case-study-info">
                        <span class="category">Home</span>
                        <h3 class="title"><a routerLink="/case-study-details">Top Emerging Modes of Home</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-case-study-box">
                    <div class="case-study-image bg2"></div>
                    <a routerLink="/case-study-details" class="case-study-link">
                        <div class="case-study-img-hover bg2"></div>
                    </a>
                    <div class="case-study-info">
                        <span class="category">Business</span>
                        <h3 class="title"><a routerLink="/case-study-details">Emerging Modes of Business</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-case-study-box">
                    <div class="case-study-image bg4"></div>
                    <a routerLink="/case-study-details" class="case-study-link">
                        <div class="case-study-img-hover bg4"></div>
                    </a>
                    <div class="case-study-info">
                        <span class="category">Travel</span>
                        <h3 class="title"><a routerLink="/case-study-details">Best Emerging Modes of Travel</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-case-study-box">
                    <div class="case-study-image bg5"></div>
                    <a routerLink="/case-study-details" class="case-study-link">
                        <div class="case-study-img-hover bg5"></div>
                    </a>
                    <div class="case-study-info">
                        <span class="category">Car</span>
                        <h3 class="title"><a routerLink="/case-study-details">Creative Emerging Modes of Car</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-case-study-box">
                    <div class="case-study-image bg3"></div>
                    <a routerLink="/case-study-details" class="case-study-link">
                        <div class="case-study-img-hover bg3"></div>
                    </a>
                    <div class="case-study-info">
                        <span class="category">Health</span>
                        <h3 class="title"><a routerLink="/case-study-details">Top 10 Emerging Modes of Health</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-case-study-box">
                    <div class="case-study-image bg6"></div>
                    <a routerLink="/case-study-details" class="case-study-link">
                        <div class="case-study-img-hover bg6"></div>
                    </a>
                    <div class="case-study-info">
                        <span class="category">House</span>
                        <h3 class="title"><a routerLink="/case-study-details">10 Best Emerging Modes of House</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ctr-area">
    <div class="container">
        <div class="ctr-content">
            <h2>Insurances for your child's future</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
            <a routerLink="/contact" class="default-btn">Get A Quote <i class="flaticon-right-chevron"></i><span></span></a>
        </div>

        <div class="ctr-image">
            <img src="assets/img/ctr-img.jpg" alt="image">
        </div>

        <div class="shape">
            <img src="assets/img/bg-dot3.png" alt="image">
        </div>
    </div>
</section>