import { Component, ElementRef, isDevMode, OnInit, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from 'src/app/shared/service/account.service';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
@Component({
  selector: 'app-car-insurance',
  templateUrl: './car-insurance.component.html',
  styleUrls: ['./car-insurance.component.scss']
})
export class CarInsuranceComponent implements OnInit {

  @ViewChild('contentResultlogin', { static: false }) modalog: any;
  @ViewChild('contentResultsign', { static: false }) modasign: any;

  loaderVisible: boolean;
  user: any;
  userInconnu: string;
  login: boolean = false;
  listMarques: any = [];
  vehiculeCategories = [];
  garanties: any = [];
  garantiesAll: any = [];
  listAgences = [];
  paymentMethods = [];
  tmpPaymentMethods = [];
  listDurees: any = [];
  listDureesFiltrer: any = [];
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  devisType: string;
  numeroSous="";
  refPro="";
  pwd = 'password';






  loginform = new FormGroup({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });
  simulationVehiculeForm = new FormGroup({
    livraison: new FormControl('domicile', [Validators.required]),
    moyenDePaiement: new FormControl("paiement_agence", [Validators.required]),
    assureur: new FormControl("", [Validators.required]),
    agence: new FormControl(""),
    isSubscriber:new FormControl(true),
    canal: new FormControl(1, [Validators.required]),
    pack: new FormControl("auto"),
    immatriculation: new FormControl("", [Validators.required]),
    marque: new FormControl("", [Validators.required]),
    modele: new FormControl("", [Validators.required]),
    categorieVehicule: new FormControl("", [Validators.required]),
    typeVehicule: new FormControl("", [Validators.required]),
    valeurNeuf: new FormControl("", [Validators.required]),
    valeurVenal: new FormControl("", [Validators.required]),
    duree: new FormControl("", [Validators.required]),
    typeMoteur: new FormControl("", [Validators.required]),
    poids: new FormControl("", [Validators.required]),
    nombrePlace: new FormControl(5, [Validators.required]),
    first_name: new FormControl("", [Validators.required]),
    last_name: new FormControl("", [Validators.required]),
    phone: new FormControl("", [Validators.required]),
    date_mis_en_circulation: new FormControl("", [Validators.required]),
    date_effet: new FormControl(new Date(), [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
    address: new FormControl("", [Validators.required]),
    // carte_grise: new FormControl(null ,[Validators.required]),
    // profession: new FormControl(null ,[Validators.required]),
    puissance: new FormControl("", [Validators.required]),
    assure: new FormGroup({
      prenom: new FormControl("", [Validators.required]),
      nom: new FormControl("", [Validators.required]),
      dateNaissance: new FormControl(""),
      profession: new FormControl(""),
      numeroTelephone: new FormControl("", [Validators.required]),
      numeroCni: new FormControl(""),
      secteuractivite : new FormControl("", [Validators.required]),
      adresse: new FormControl("", [Validators.required]),
      // lateralite: new FormControl("", [Validators.required]),
      genre: new FormControl("", [Validators.required]),
      choix: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.pattern(this.emailPattern)])
    }),
    souscripteur: new FormGroup({
      prenom: new FormControl("", [Validators.required]),
      nom: new FormControl("", [Validators.required]),
      genre: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
      dateNaissance: new FormControl(""),
      telephone: new FormControl("", [Validators.required]),
      numeroCni: new FormControl("")
    }),
    pointRelaisControl: new FormControl("", [Validators.required]),
    adresseLivraison: new FormControl("", [Validators.required]),
  });

  constructor(private modalService: NgbModal,private accountService: AccountService,private subscriptionService: SubscriptionService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.devisType = this.route.snapshot.paramMap.get("type");
    this.getGaranties();
    this.user = JSON.parse(localStorage.getItem('microFinance'));
    let time = new Date().getTime();
    let pro = new Date().getTime() + 123890989;
    this.numeroSous="SN"+time+"SC";
    this.refPro="SN"+pro+"PR";
    this.getModelvoiture();
    this.getVehiculeType();
    this.getPaymentMethods();
    this.getAgences();
    this.getDurees();
  }



  inscrire() {
    this.modalService.dismissAll(this.modalog);
    this.modalService.open(this.modasign, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {

      }
    }, (reason) => {

    });
  }

  connecter() {
    this.userInconnu = "";
    if (this.loginform.valid) {
      this.loaderVisible = true;
      let data = {
        ... this.loginform.value
      }
      this.subscriptionService.login(data)
        .subscribe((res) => {
          if (res['data']) {
            const tokenObj = res['data'];
            let infos = {
              id: tokenObj.id,
              matricule: tokenObj.matricule,
              firstName: tokenObj.firstName,
              lastName: tokenObj.lastName,
              email: tokenObj.email,
              telephone: tokenObj.telephone,
              secteuractivite:tokenObj.secteuractivite,
              profession:tokenObj.profession,
              adresse:tokenObj.adresse,
              genre:tokenObj.genre
            }
            let detail = {
              id_token: tokenObj.access_token,
              refresh_token: tokenObj.refresh_token,
              // roles: tokenObj.roles,
              infos: infos,
              authorities: tokenObj.authorities
            };
            if (res['data']['access_token']) {
              localStorage.setItem('microFinance', JSON.stringify(detail));
              localStorage.setItem('microFinanceBo', JSON.stringify(detail));
              this.accountService.sendAccount(JSON.stringify(infos));
              this.user = detail;
              this.login = true;
              this.getModelvoiture();
              this.getVehiculeType();
              this.getModelvoiture();
              this.getGaranties();
              this.getAgences();
              this.getPaymentMethods();
              this.getDurees();
              // this.loaderVisible = false;
              this.modalService.dismissAll(this.modalog);
            }
            else {
              this.souscrire();
              this.userInconnu = res['message'];
              this.loaderVisible = false;
            }
          } else {
            this.loaderVisible = false;
            this.userInconnu = res['message'];
          }
          this.loaderVisible = false;
        }, (err) => {
          this.userInconnu = err['error']['message'] || err['error']['errors'][0]['message'];
          this.loaderVisible = false;
        });

    } else {
      this.checkValidity(this.loginform);
    }
  }

  souscrire() {
    this.modalService.dismissAll(this.modasign);
    let token = JSON.parse(localStorage.getItem('microFinance'));
    if (token) {
      this.login = true;

    } else {
      this.modalService.open(this.modalog, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
        if (result == 'subscribe') {

        }
      }, (reason) => {

      });
    }
  }

  getModelvoiture() {
    this.subscriptionService.getModel().subscribe((resp) => {
      this.listMarques = resp['data'];
    });
  }

  getVehiculeType() {
    this.subscriptionService.getVehiculeCategories().subscribe((resp) => {
      this.vehiculeCategories = resp['data'] || [];
    });
  }
  getGaranties() {
    this.garanties = [];
    this.garantiesAll = [];
    this.subscriptionService.getTypeGaranties().subscribe((resp) => {
      if (resp['responseCode'] == 200) {
        resp['data'].forEach(element => {
          if (element.parent_id == null) {
            this.garanties.push(element);
            this.garantiesAll.push(element);
          }
        });
      }
    });
    this.getDescriptionGarantie();
  }
  getDescriptionGarantie(){
    if(this.garanties){
      for (let garantie of this.garanties) {
        if (garantie.code == "gtcom") {
          garantie.description = "véhicule."
        }
      }
    }
  }
  getAgences() {
    this.subscriptionService.getListAgences().subscribe((resp) => {
      this.listAgences = resp['data'];
    });
  }
  getPaymentMethods() {
    this.subscriptionService.getPaymentMethods().subscribe((resp) => {
      this.paymentMethods = resp['data'];

      for (let pay of this.paymentMethods) {
        if (pay.code == 'paiement_agence') pay['logo'] = "assets/images/payment.jpg";
        else if (pay.code == 'paiement_carte') pay['logo'] = "assets/images/wallet.jfif";
        else if (pay.code == 'OM') pay['logo'] = "assets/images/om.jpg";
        else if (pay.code == 'FM') pay['logo'] = "assets/images/fm.png";
        else if (pay.code == 'WM') pay['logo'] = "assets/images/wm.jpg";
        else if (pay.code == 'paiement_agence') pay['logo'] = "assets/images/payment.jpg";
        else if (pay.code == 'paiement_carte') pay['logo'] = "assets/images/wallet2.png";
      }
      this.tmpPaymentMethods = this.paymentMethods;
      this.paymentMethods = this.tmpPaymentMethods.filter(el=> el.code !== 'paiement_agence');
      this.simulationVehiculeForm.get('moyenDePaiement').setValue(this.paymentMethods[0].code);
    });
  }
  getDurees() {
    this.subscriptionService.getDurees().subscribe((resp) => {
      this.listDurees = resp['data'] || [];
      this.listDureesFiltrer = this.listDurees.filter( d =>
          d.duree <=12 && d.unite == 'mois'
      );
    });
  }
  
  checkValidity(g: FormGroup) {
    Object.keys(g.controls).forEach(key => {
      g.get(key).markAsDirty();
    });
    Object.keys(g.controls).forEach(key => {
      g.get(key).markAsTouched();
    });
    Object.keys(g.controls).forEach(key => {
      g.get(key).updateValueAndValidity();
    });
  }

}
