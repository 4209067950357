<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2A activedH">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <!-- <h2>Votre paiement a été effectué avec succès.</h2> -->
                </div>
            </div>
        </div>
    </div>
</div>

<section class="faq-contact-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <h2 class="activate">Votre paiement a été effectué avec succès.</h2>
            <div class="col-lg-12 col-md-12 marG">
                <button type="submit" class="default-btn c-uppercase" (click)="voirAttestation()">Voir Attestation<span></span></button>
            </div>
        </div>
    </div>

    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</section>