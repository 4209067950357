<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Suivi sinistre</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Events</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>

<section class="events-area ptb-100 pb-70">
    <div class="container">
        <div class="single-events-box">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg1">
                        <img src="assets/img/events-image/events-img1.jpg" alt="image">
                    </div>
                </div>

                <div class="events-content">
                    <div class="content">
                        <h3><a routerLink="/events-details">2020 Life Insurance Conference</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="location"><i class="fas fa-map-marker-alt"></i> 120 G-35 Spinsovila Sc, USA   </span>
                        <a routerLink="/events-details" class="join-now-btn">Join Now</a>
                    </div>
                </div>

                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>01</span>
                                <h3>23 October</h3>
                                <p>7:30 pm</p>
                                <i class="flaticon-timetable"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-events-box">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg2">
                        <img src="assets/img/events-image/events-img2.jpg" alt="image">
                    </div>
                </div>

                <div class="events-content">
                    <div class="content">
                        <h3><a routerLink="/events-details">2020 Digital Insurance Summit</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="location"><i class="fas fa-map-marker-alt"></i> 120 G-35 Spinsovila Sc, USA   </span>
                        <a routerLink="/events-details" class="join-now-btn">Join Now</a>
                    </div>
                </div>

                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>02</span>
                                <h3>24 October</h3>
                                <p>8:30 pm</p>
                                <i class="flaticon-timetable"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-events-box">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg3">
                        <img src="assets/img/events-image/events-img3.jpg" alt="image">
                    </div>
                </div>

                <div class="events-content">
                    <div class="content">
                        <h3><a routerLink="/events-details">2010 Health Insurance Conference</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="location"><i class="fas fa-map-marker-alt"></i> 120 G-35 Spinsovila Sc, USA   </span>
                        <a routerLink="/events-details" class="join-now-btn">Join Now</a>
                    </div>
                </div>

                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>03</span>
                                <h3>25 October</h3>
                                <p>11:30 pm</p>
                                <i class="flaticon-timetable"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-events-box">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg4">
                        <img src="assets/img/events-image/events-img4.jpg" alt="image">
                    </div>
                </div>

                <div class="events-content">
                    <div class="content">
                        <h3><a routerLink="/events-details">2021 Home Insurance Conference</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="location"><i class="fas fa-map-marker-alt"></i> 120 G-35 Spinsovila Sc, USA   </span>
                        <a routerLink="/events-details" class="join-now-btn">Join Now</a>
                    </div>
                </div>

                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>04</span>
                                <h3>26 October</h3>
                                <p>10:30 pm</p>
                                <i class="flaticon-timetable"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="feedback-area ptb-100 bg-fafafa">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Feedback</span>
            <h2>What Client Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides">
            <div class="client-feedback">
                <div>
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                </div>
                
                <button class="prev-arrow slick-arrow">
                    <i class='flaticon-left-chevron'></i>
                </button>
                
                <button class="next-arrow slick-arrow">
                    <i class='flaticon-right-chevron'></i>
                </button>
            </div>
            
            <div class="client-thumbnails">
                <div>
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img2.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Jonus Nathan</h3>
                            <span>CEO at Envato</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img4.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Sadio Finn</h3>
                            <span>CEO at FlatIcon</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Tom Olivar</h3>
                            <span>CEO at ThemeForest</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>James Finn</h3>
                            <span>CEO at GitLab</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>John Lucy</h3>
                            <span>CEO at Linkedin</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Sarah Taylor</h3>
                            <span>CEO at Twitter</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>James Anderson</h3>
                            <span>CEO at Facebook</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Steven Smith</h3>
                            <span>CEO at EnvyTheme</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="partner-area">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img5.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section> -->