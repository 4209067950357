<header class="header-area">

    <!-- Start Top Header -->
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <ul class="top-header-nav">
                        <li><a [href]="lienLinkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a [href]="lienFacebook" target="_blank"><i class="fab fa-facebook"></i></a></li>
                    </ul>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="top-header-right-side">
                        <ul>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-email"></i>
                                </div>
                                <span>Adresse email:</span>
                                <a>{{email}}</a>
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-call"></i>
                                </div>
                                <span>Téléphone:</span>
                                <a href="tel:{{telephone}}">{{telephone}}</a>
                            </li>

                            <li>
                                <a target="_blank" [href]="redirection" class="default-btn">Espace personnel<span></span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Top Header -->

    <!-- Start Navbar Area -->
    <div class="navbar-area">
        <div class="truz-responsive-nav">
            <div class="container">
                <div class="truz-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <img class="tailleImage" [src]="logoImage" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="truz-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img class="tailleImage" [src]="logoImage" alt="logo">
                    </a>

                    <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Accueil</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Souscription<i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" *ngFor="let childrenItem of products">
                                        <a routerLink="{{childrenItem?.path}}" class="nav-link" *ngIf="childrenItem.type === 'link'">
                                            {{(childrenItem?.code === 'ASSURANCE MRH') ? ('Assurance MRH') : (childrenItem?.code === 'ASSURANCE SANTE') ? ('Assurance Santé') : (childrenItem?.code === 'ASSURANCE RCE') ? ('Responsabilté Civile Entreprise') : (childrenItem?.code === 'ASSURANCE TRC') ? ('Tous Risques Chantiers') : (childrenItem?.code === 'ASSURANCE IA') ? ('Individuel Accident') : (childrenItem?.title  | titlecase)}}
                                        </a>
                                    </li>
                                    <!-- <li class="nav-item"><a routerLink="/home-insurance" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Insurance</a></li> -->

                                    <!-- <li class="nav-item"><a routerLink="/business-insurance" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Assurance Mrh</a></li>

                                    <li class="nav-item"><a routerLink="/health-insurance" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Assurance santé</a></li>

                                    <li class="nav-item"><a routerLink="/travel-insurance" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Assurance voyage</a></li>

                                    <li class="nav-item"><a routerLink="/car-insurance" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Assurance automobile</a></li>

                                    <li class="nav-item"><a routerLink="/life-insurance" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Assurance vie</a></li> -->
                                </ul>
                            </li>
                            <!-- <li class="nav-item"><a routerLink="/" class="nav-link">Accueil <i class="flaticon-down-arrow"></i></a> -->
                            <!-- <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                                    <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                                    <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li>

                                    <li class="nav-item"><a routerLink="/home-four" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Four</a></li>
                                </ul> -->
                            <!-- </li> -->
                            <li class="nav-item"><a routerLink="/" class="nav-link">Sinistre<i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/declaration-sinistre" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Déclaration</a></li>
                                    <!-- <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Suivi Sinistre</a></li> -->
                                </ul>
                            </li>
                            <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Qui sommes Nous<i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Équipe</a></li>

                                    <li class="nav-item"><a routerLink="/services-two" class="nav-link">Services</a>
                                      
                                    </li>

                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Étude de cas</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/case-study" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Study</a></li>

                                            <li class="nav-item"><a routerLink="/case-study-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Study Details</a></li>
                                        </ul>
                                    </li>

                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Événements</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>

                                            <li class="nav-item"><a routerLink="/events-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events Details</a></li>
                                        </ul>
                                    </li>

                                    <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tarification</a></li>

                                    <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Témoignages</a></li>

                                    <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                  
                                </ul>
                            </li> -->

                            <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Blog <i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>

                                    <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>

                                    <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                                </ul>
                            </li> -->
                            <!-- <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">A propos</a></li> -->
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul>

                        <div class="others-option">
                            <div class="option-item">
                                <div *ngIf="utilisateurs">
                                    <span class="log-ini mt-3 ml-5" *ngIf="utilisateurs">
                                        {{utilisateurs?.firstName}}
                                        {{utilisateurs?.lastName}}</span>

                                    <a target="_blank" placement="bottom" triggers="mouseenter:mouseleave" href="http://ma.suntelecoms.com/" class="log-in user" *ngIf="utilisateurs">
                                        <i class="fa fa-user  ml-2 "></i>
                                    </a>
                                </div>

                                <span class="log-ini" *ngIf="utilisateur">
                                    {{utilisateur?.infos?.firstName}}
                                    {{utilisateur?.infos?.lastName}}
                                </span>
                                <span class="log-out user" placement="bottom" [swal]="confirmDeconnexion" *ngIf="utilisateur">

                                    <a target="_blank" placement="bottom"
                                            triggers="mouseenter:mouseleave" href="https://bo.abidjanaise.suntelecoms.com/" class="log-in user">
                                            <i class="fa fa-power-off  ml-2 colorIcon"></i>
                                    </a>
                                </span>

                                <!-- <i class="search-btn flaticon-search"></i> -->
                                <i class="close-btn flaticon-cross-out"></i>

                                <div class="search-overlay search-popup">
                                    <div class='search-box'>
                                        <form class="search-form">
                                            <input class="search-input" name="search" placeholder="Search" type="text">

                                            <button class="search-button" type="submit"><i class="flaticon-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="burger-menu">
                                <i class="flaticon-menu"></i>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->

</header>

<swal #confirmDeconnexion title="Confirmation" text="Voulez-vous vraiment proceder à la déconnexion ?" icon="question" [showCancelButton]="true" [reverseButtons]="true" [focusCancel]="false" [confirmButtonText]="'Confirmer'" [cancelButtonText]="'ANNULER'"
    (confirm)="deconnexion()">
</swal>