import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/shared/service/account.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  showMessage:boolean = false
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private accountService:AccountService ) { }

  ngOnInit(): void {
    this.activateAccount()
  }
  activateAccount(){
    this.activatedRoute.queryParams.subscribe(param => {
      this.accountService.activate(param.id).subscribe((resp) => {
        if (resp['responseCode'] == 200) {
        this.showMessage = true
  
        } else if (resp['code'] == 400) {
            console.error(resp['message']);
            this.router.navigate(['sign-in']);
        }
    }, error => {
        console.error('error');
    });
    });
    

  }

}
