import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { UtilsService } from 'src/app/shared/service/utils.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  logoImage = localStorage.getItem('LogoImage');
  loaderVisible = false;
  emailSouscripteur = ''
  lienLinkedin = localStorage.getItem('lienLinkedin');
  lienFacebook = localStorage.getItem('lienFacebook');
  adresse = localStorage.getItem('adresse');
  telephone = localStorage.getItem('telephone');
  fax = localStorage.getItem('fax');
  email = localStorage.getItem('email');
  constructor(private subscriptionService: SubscriptionService,  private utilsService: UtilsService, ) { }

  ngOnInit(): void {
  }



  newsletter() {
    if (this.emailSouscripteur) {
        let data = {
            "emailSouscripteur": this.emailSouscripteur
        }
        this.loaderVisible = true;
        this.subscriptionService.newsletter(data).subscribe((resp) => {
            if (resp["responseCode"] == 200) {
                this.utilsService.showToastSuccess("Abonnement effectué avec succès.", "Abonnement réussi");
                this.emailSouscripteur = ''
            }
            this.loaderVisible = false;
        }, error => {
            this.utilsService.showToastError(error.error.errors[0].message, "Echec");
            this.loaderVisible = false;
        });

    } else {
      this.utilsService.showToastError('Veuillez renseigner votre adresse mail', "Echec");
    }
}

}
