<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog Right Sidebar</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog Right Sidebar</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog-image/img1.jpg" alt="image"></a>
                                <div class="date"><i class="flaticon-calendar"></i> Oct 14, 2020</div>
                            </div>

                            <div class="post-content">
                                <h3><a routerLink="/blog-details">2020 Insurance Trends And Possible Challenges</a></h3>
                                <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog-image/img2.jpg" alt="image"></a>
                                <div class="date"><i class="flaticon-calendar"></i> Oct 10, 2020</div>
                            </div>

                            <div class="post-content">
                                <h3><a routerLink="/blog-details">Global Trends in the Life Insurance Industry</a></h3>
                                <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog-image/img3.jpg" alt="image"></a>
                                <div class="date"><i class="flaticon-calendar"></i> Sep 13, 2020</div>
                            </div>

                            <div class="post-content">
                                <h3><a routerLink="/blog-details">The Best Car Insurance Companies in 2020</a></h3>
                                <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog-image/img4.jpg" alt="image"></a>
                                <div class="date"><i class="flaticon-calendar"></i> Oct 14, 2020</div>
                            </div>

                            <div class="post-content">
                                <h3><a routerLink="/blog-details">Top Business Meeting Tech Toys for 2020</a></h3>
                                <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog-image/img5.jpg" alt="image"></a>
                                <div class="date"><i class="flaticon-calendar"></i> Oct 10, 2020</div>
                            </div>

                            <div class="post-content">
                                <h3><a routerLink="/blog-details">Build a Better Brand with Your Office Space</a></h3>
                                <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog-image/img6.jpg" alt="image"></a>
                                <div class="date"><i class="flaticon-calendar"></i> Sep 13, 2020</div>
                            </div>

                            <div class="post-content">
                                <h3><a routerLink="/blog-details">Best Office Spaces for a Tight Budget</a></h3>
                                <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog-image/img7.jpg" alt="image"></a>
                                <div class="date"><i class="flaticon-calendar"></i> Oct 14, 2020</div>
                            </div>

                            <div class="post-content">
                                <h3><a routerLink="/blog-details">5 Reasons Every Business Needs an Office Space</a></h3>
                                <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog-image/img8.jpg" alt="image"></a>
                                <div class="date"><i class="flaticon-calendar"></i> Oct 10, 2020</div>
                            </div>

                            <div class="post-content">
                                <h3><a routerLink="/blog-details">How to Choose the Best Coworking Space</a></h3>
                                <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                            <a routerLink="/" class="page-numbers">1</a>
                            <span class="page-numbers current" aria-current="page">2</span>
                            <a routerLink="/" class="page-numbers">3</a>
                            <a routerLink="/" class="page-numbers">4</a>
                            <a routerLink="/" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_truz_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">June 10, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">June 21, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">I Used The Web For A Day On A 50 MB Budget</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">June 30, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">How To Create A Responsive Popup Gallery?</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/">Business</a></li>
                            <li><a routerLink="/">Privacy</a></li>
                            <li><a routerLink="/">Technology</a></li>
                            <li><a routerLink="/">Tips</a></li>
                            <li><a routerLink="/">Uncategorized</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_recent_comments">
                        <h3 class="widget-title">Recent Comments</h3>

                        <ul>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/">A WordPress Commenter</a>
                                </span>
                                on
                                <a routerLink="/">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/">Pearo</a>
                                </span>
                                on
                                <a routerLink="/">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/">Wordpress</a>
                                </span>
                                on
                                <a routerLink="/">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/">A WordPress Commenter</a>
                                </span>
                                on
                                <a routerLink="/">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/">Pearo</a>
                                </span>
                                on
                                <a routerLink="/">Hello world!</a>
                            </li>
                        </ul>
                    </section>

                    <section class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>

                        <ul>
                            <li><a routerLink="/">How to Become a Successful Entry Level UX Designer</a></li>
                            <li><a routerLink="/">How to start your business as an entrepreneur</a></li>
                            <li><a routerLink="/">How to be a successful entrepreneur</a></li>
                            <li><a routerLink="/">10 Building Mobile Apps With Ionic And React</a></li>
                            <li><a routerLink="/">Protect your workplace from cyber attacks</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>

                        <ul>
                            <li><a routerLink="/">May 2020</a></li>
                            <li><a routerLink="/">April 2020</a></li>
                            <li><a routerLink="/">June 2020</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>

                        <ul>
                            <li><a routerLink="/">Log in</a></li>
                            <li><a routerLink="/">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">WordPress.org</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Pearo <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>